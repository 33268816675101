import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./Toastify.scss";
import styles from "./AlertToast.module.scss";

import AlertCheck from "../../assets/svgs/alert-check.svg";
import AlertCross from "../../assets/svgs/alert-cross.svg";
import AlertWarning from "../../assets/svgs/alert-warning.svg";

const CustomToast = ({ type, header, subheader }) => (
  <div className={styles.custom_toast}>
    <div className={styles.custom_toast__header}>
      <div className={styles.custom_toast__header__text}>{header}</div>
      <img
        className={styles.custom_toast__header__icon}
        src={
          type == "success"
            ? AlertCheck
            : type == "error"
            ? AlertCross
            : type == "warning"
            ? AlertWarning
            : AlertWarning
        }
        alt="success-action"
      />
    </div>
    {subheader && <p className={styles.custom_toast__subheader}>{subheader}</p>}
  </div>
);

const alertToast = (type, header, subheader, callback) => {
  toast(<CustomToast type={type} header={header} subheader={subheader} />, {
    progressClassName:
      type == "success"
        ? "progress-bar-success"
        : type == "error"
        ? "progress-bar-error"
        : type == "warning"
        ? "progress-bar-warning"
        : "progress-bar-warning",
    autoClose: type == "success",
    onClose: callback && callback(),
  });
};

// const AlertToast = ({ type = "success", header, subheader, callback }) => {
//   toast(<CustomToast type={type} header={header} subheader={subheader} />, {
//     progressClassName:
//       type == "success"
//         ? "progress-bar-success"
//         : type == "error"
//         ? "progress-bar-error"
//         : type == "warning"
//         ? "progress-bar-warning"
//         : "",
//     autoClose: type == "success",
//     onClose: callback && callback(),
//   });
// };

export default alertToast;
