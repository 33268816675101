import styles from "./genderDistribution.module.scss";
import MaleFemaleIcon from "../../../../../../assets/svgs/male-female.svg";
import CircleProgressBar from "../../../../../Common/Charts/CircleProgressBar";

const GenderDistribution = ({ gender_distribution }) => {
  const roundFemale = Math.round(
    gender_distribution?.graph_data?.data?.cat_area[1] * 100
  );

  return (
    <div className={styles.gender_distribution}>
      <CircleProgressBar
        percentage={roundFemale || 60}
        colour={"#567a1f"}
        radius={40}
        stroke={"1.4rem"}
        width={"100%"}
        centreSvg={70}
        disableColour={"#bde383"}
        icon={MaleFemaleIcon}
        label={["Uomo", "Donna"]}
        labelColor={"#567a1f"}
        labelColorText={"#0f172a"}
      />
    </div>
  );
};

export default GenderDistribution;
