import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Dashboard.module.scss";
import { motion } from "framer-motion";
import clx from "classnames";

import Nav from "./Nav";
import CompleteTable from "./CompleteTable";
import TransactionsTable from "./TransactionsTable";
import Account from "./Account";
import CompleteSlider from "./CompleteSlider";
import DeleteReportModal from "./DeleteReportModal";

import { getUser } from "../Auth/store/selectors";
import { actions as actionUser } from "../Auth/store";
import { actions } from "./store";

import { getUserReports } from "./store/selectors";
import { getTransactions } from "./store/selectors";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { name, email, isGoogle, isFacebook } = useSelector(getUser());
  // console.log(name, "name dashboard");
  const transactions = useSelector(getTransactions());
  // console.log(transactions, "transactions");
  const { data } = useSelector(getUserReports());
  // console.log(data, "data dashboard");

  const [deleteModal, setDeleteModal] = useState({
    isVisible: false,
    reportId: "",
    reportUrl: "",
  });

  useEffect(() => {
    dispatch(actions.GET_TRANSACTIONS.REQUEST());
    dispatch(actions.GET_REPORTS.REQUEST());
    dispatch(actionUser.FETCH_USER.REQUEST());
  }, [dispatch]);

  return (
    data && (
      <motion.section
        className={clx(styles.dashboard, "dashboard")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: "easeOut",
          },
        }}
        transition={{
          duration: 0.5,
          ease: "easeIn",
        }}
      >
        {/* NAV */}
        <Nav />

        <div className={styles.dashboard__wrapper}>
          {/* HEADER */}
          <h1 className={styles.dashboard__header}>
            {name ? `Benvenuto ${name}` : ""}
          </h1>

          {/* CONTENT */}
          <div className={styles.dashboard__content}>
            {/* COMPLETE REPORTS */}
            {data?.length > 0 && (
              <div className={styles.dashboard__content__complete_reports}>
                <div className={styles.dashboard__content__section_header}>
                  I tuoi reports
                </div>
                <CompleteTable
                  userReports={data}
                  deleteModal={deleteModal}
                  setDeleteModal={setDeleteModal}
                />
                <div className={styles.dashboard__content__complete__slider}>
                  <CompleteSlider
                    userReports={data}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                  />
                </div>
              </div>
            )}

            {/* TRANSACTIONS */}
            {transactions?.length > 0 && (
              <div className={styles.dashboard__content__transactions}>
                <div className={styles.dashboard__content__section_header}>
                  Transazioni
                </div>
                <TransactionsTable transactions={transactions} />
              </div>
            )}

            <div className={styles.dashboard__content__accountandcredits}>
              {/* ACCOUNT */}
              <div className={styles.dashboard__content__subsection}>
                <div className={styles.dashboard__content__section_header}>
                  Account
                </div>
                <Account
                  name={name}
                  email={email}
                  isSocialAccount={isGoogle == 1 || isFacebook == 1}
                />
              </div>
            </div>
            {/* DELETE REPORT MODAL */}
            <DeleteReportModal
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          </div>
        </div>
      </motion.section>
    )
  );
};

export default Dashboard;
