import * as constants from "./constants";

const initialState = {
  loading: 0,
  isModalLoader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.START_LOADER:
      return {
        ...state,
        loading: state.loading + 1,
        isModalLoader: action?.payload?.isModalLoader,
      };
    case constants.STOP_LOADER:
      return {
        ...state,
        loading: state.loading - 1,
        isModalLoader: action?.payload?.isModalLoader,
      };
    default:
      return { ...state };
  }
};

export { reducer as LoaderReducer };
