import clx from "classnames";
import styles from "./SixthPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";

import formatToEuro from "../../../../utils/formatToEuro";
import translateDisplayValue from "../../../../utils/translateDisplayValue";
import GenderDistributionArea from "./GenderDistributionArea";
import PopulationChange from "./PopulationChange";
import PopulationDensityArea from "./PopulationDensityArea";
import AverageEarnings from "./AverageEarnings";
import CommonCard from "./CommonCard";

const SixthPg = ({
  population_change,
  family,
  avg_price,
  ads_amount,
  population_density,
  avg_earnings,
  gender_distribution,
}) => {
  return (
    <div className={styles.sixth_page}>
      {/* PAGE TITLE */}
      <div className={stylesMain.page__section_title}>Mercato immobiliare</div>

      {/* CHARTS */}
      <div className={styles.sixth_page__charts_grid}>
        {/* POPULATION CHANGE - AREA CHART */}
        {population_change?.graph_status == "OK" && (
          <div className={clx(styles.pop__change, styles.general__card)}>
            <PopulationChange
              data={population_change?.graph_data}
              minHeight={250}
              wrapperHeight={"250px"}
              locale={false}
              paddingTop={"20px"}
              chartMarginTop={"20px"}
              footerTop={"0px"}
            />
          </div>
        )}

        {/* FAMILY - TEXT CARD */}
        {family?.graph_status == "OK" && (
          <div className={clx(styles.family, styles.general__card)}>
            <CommonCard
              header={family?.graph_data?.title}
              description={family?.graph_data?.description}
              content={translateDisplayValue(
                family?.graph_data?.data?.displayed_value
              )}
              marginTop={"0px"}
              fontSize={"18px"}
              titleSize={"15px"}
            />
          </div>
        )}

        {/* AVERAGE PRICE - TEXT CARD */}
        {avg_price && (
          <div className={clx(styles.avg_price, styles.general__card)}>
            <CommonCard
              header={
                <span style={{ fontSize: 15 }}>
                  Prezzo medio al m<sup style={{ fontSize: 8 }}>2</sup>
                </span>
              }
              content={formatToEuro(avg_price)}
              marginTop={"0px"}
              fontSize={"18px"}
              titleSize={"15px"}
            />
          </div>
        )}

        {/* ADS AMOUNT - TEXT CARD */}
        {ads_amount && (
          <div className={clx(styles.ads_amount, styles.general__card)}>
            <CommonCard
              header={"Numero annunci"}
              content={formatToEuro(ads_amount)}
              marginTop={"0px"}
              fontSize={"18px"}
              titleSize={"15px"}
            />
          </div>
        )}

        {/* POPULATION DENSITY - GAUGE CHART */}
        {population_density?.graph_status == "OK" && (
          <div
            className={clx(
              styles.population__density__area,
              styles.general__card
            )}
          >
            <PopulationDensityArea
              data={population_density?.graph_data}
              titleTop={"15px"}
              titleLeft={"24px"}
              minHeight={"200px"}
              fontSize={"24px"}
              marginTopContainer={"47px"}
            />
          </div>
        )}

        {/* AVERAGE EARNINGS - GAUGE CHART */}
        {avg_earnings?.graph_status == "OK" && (
          <div className={clx(styles.avg_earnings, styles.general__card)}>
            <AverageEarnings
              data={avg_earnings?.graph_data}
              titleTop={"15px"}
              titleLeft={"24px"}
              minHeight={"200px"}
              fontSize={"24px"}
            />
          </div>
        )}

        {/* GENDER DISTRIBUTION - CIRCLE CHART */}
        {gender_distribution?.graph_status == "OK" && (
          <div
            className={clx(
              styles.gender__distribution__area,
              styles.general__card
            )}
          >
            <GenderDistributionArea
              data={gender_distribution?.graph_data}
              marginTop="35px"
              minHeight={"400px"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SixthPg;
