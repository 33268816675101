import { combineReducers } from "redux";

import { accountReducer } from "../containers/Dashboard/store";
import { authReducer } from "../containers/Auth/store";
import { errorReducer } from "../shared/Error/store/reducer";
import { LoaderReducer } from "../shared/Loader/store/reducer";
import { landingReducer } from "../containers/Landing/store";

export default () =>
  combineReducers({
    loader: LoaderReducer,
    errorReducer,
    accountReducer,
    authReducer,
    landingReducer,
  });
