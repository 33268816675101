import { useDispatch } from "react-redux";
import styles from "./ContactUsModal.module.scss";

import { actions as actionsUser } from "../../../Dashboard/store";

import Modal from "../../../Common/Modal";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import TextField from "../../../Common/TextField";
import CheckboxField from "../../../Common/CheckboxField";
import alertToast from "../../../AlertToast";
import useNoScroll from "../../../../utils/useNoScroll";

import { ReactComponent as Close } from "../../../../assets/svgs/close.svg";
import AuthSvg from "../../../../assets/svgs/auth-svg.svg";

import { Field, Form, Formik } from "formik";
import * as yup from "yup";

const ContactUs = ({ modalState, setModalState }) => {
  const dispatch = useDispatch();

  // ADJUST BODY SCROLL WHEN MODALS ARE OPEN
  useNoScroll(modalState);

  const INITIAL_VALUES = {
    nameContact: "",
    emailContact: "",
    text: "",
    acceptPolicyContact: false,
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    nameContact: yup.string().required("Nome richiesto"),
    emailContact: yup
      .string("Inserisci la tua email")
      // .email("Attenzione! L`email inserita è nel formato errato")
      .required("Email richiesta")
      .matches(
        // Regular expression for email validation
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        "Attenzione! L`email inserita è nel formato errato"
      ),
    text: yup.string().required("Messaggio richiesto"),
    acceptPolicyContact: yup
      .boolean()
      .required(
        "Accetta l'informativa sulla privacy per inviare il tuo messaggio"
      )
      .oneOf(
        [true],
        "Accetta l'informativa sulla privacy per inviare il tuo messaggio"
      ),
  });

  return (
    <Modal modalState={modalState} width={"80%"}>
      <div className={styles.contact_us_modal}>
        {/* MODAL TEXT */}
        <div className={styles.image}>
          <img src={AuthSvg} alt="contact us" />
        </div>

        {/* FORM */}
        <div className={styles.form_wrapper}>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={async (values, actions) => {
              if (
                !values.nameContact ||
                !values.text ||
                !values.emailContact ||
                !values.acceptPolicyContact
              ) {
                return alertToast(
                  "error",
                  "Compila tutti i campi",
                  "Si prega di compilare tutti i campi richiesti"
                );
              }

              const formValues = {
                nameContact: values.nameContact,
                emailContact: values.emailContact,
                text: values.text,
              };
              dispatch(
                actionsUser.CONTACT_USER.REQUEST(formValues, () => {
                  setModalState(false);
                  actions.resetForm();
                  alertToast(
                    "success",
                    "Email inviata con successo!",
                    "Il team Houseplus ti contatterà entro 24 ore lavorative."
                  );
                })
              );
            }}
          >
            {({ isSubmitting, errors, values }) => (
              // console.log(errors, "ERRORS"),
              <Form className={styles.form}>
                <div className={styles.form_content}>
                  {/* FORM HEADER */}
                  <h4>
                    Inserisci nome, email e la ragione per cui vuoi contattarci
                  </h4>

                  {/* FORM FIELDS */}
                  <div className={styles.form_content_fields}>
                    {/* Name */}
                    <Field
                      name="nameContact"
                      id="nameContact"
                      label="Nome"
                      component={TextField}
                      height="35px"
                      labelStyle={{ color: "#f9f9fa" }}
                      border="1px solid #59987e"
                    />
                    {/* Email */}
                    <Field
                      name="emailContact"
                      id="emailContact"
                      label="Email"
                      component={TextField}
                      height="35px"
                      labelStyle={{ color: "#f9f9fa" }}
                      border="1px solid #59987e"
                    />
                    {/* Message */}
                    <Field
                      name="text"
                      id="text"
                      label="Il tuo messaggio"
                      component={TextField}
                      height="35px"
                      labelStyle={{ color: "#f9f9fa" }}
                      border="1px solid #59987e"
                      gridColumn={"1 / 3"}
                    />
                  </div>

                  {/* FORM PRIVACY */}
                  <div className={styles.form_content_privacy}>
                    <Field
                      name="acceptPolicyContact"
                      id="acceptPolicyContact"
                      label={
                        <>
                          Accetto l'informativa sulla{" "}
                          <a
                            href="https://www.iubenda.com/privacy-policy/24329777"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacy di Houseplus
                          </a>
                          .
                        </>
                      }
                      labelStyle={{ color: "#f9f9fa", fontSize: "12px" }}
                      component={CheckboxField}
                      border="1px solid #59987e"
                      gap="6px"
                      padding="0px 0px 5px 2px"
                      errorMargin="5px"
                    />
                  </div>
                </div>
                {/* Submit */}
                <PrimaryButton
                  text="Invia il tuo messaggio"
                  type="submit"
                  width={250}
                  background={"#078ea6"}
                  className={styles.submit_btn}
                  disabled={
                    // isSubmitting ||
                    !values.nameContact ||
                    !values.emailContact ||
                    !values.text ||
                    !values.acceptPolicyContact ||
                    Object.keys(errors).length > 0
                  }
                />
              </Form>
            )}
          </Formik>
        </div>

        {/* CLOSE BUTTON */}
        <div className={styles.close_btn}>
          <Close onClick={() => setModalState(false)} />
        </div>
      </div>
    </Modal>
  );
};

export default ContactUs;
