import { setTypeModal } from "../../../store/actions";
import { actions as reduxActions } from "../../../../Auth/store";
import alertToast from "../../../../AlertToast";
import { ROUTES } from "../../../../../routes/constants";

// login FUNCTION EITHER BY FORM OR BY GOOGLE OR FACEBOOK
const login = (
  dispatch,
  response,
  platform,
  formValues,
  setSuccessStep,
  navigate,
  typeModal
) => {
  // console.log(response, "RESPONSE login");
  // console.log(formValues, "RESPONSE login");

  dispatch(
    reduxActions[`SIGN_IN${platform}`].REQUEST(response || formValues, () => {
      dispatch(
        reduxActions.FETCH_USER.REQUEST("", () => {
          setSuccessStep(true);

          if (typeModal.isBuy) {
            setTimeout(() => {
              dispatch(setTypeModal({ type: "buy", isBuy: true }));
              alertToast(
                "success",
                "Autenticazione effettuata",
                "Puoi adesso procedere con il pagamento per generare il tuo report PDF"
              );
            }, 500);
          } else {
            alertToast("success", "Autenticazione effettuata");
            navigate(ROUTES.DASHBOARD);
            dispatch(setTypeModal({ type: null }));
          }
        })
      );
    })
  );
};

export default login;
