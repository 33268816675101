import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../containers/Auth/store";
import { getUser } from "../containers/Auth/store/selectors";

const useAuth = () => {
  const dispatch = useDispatch();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const { isAdmin } = useSelector(getUser());

  useEffect(() => {
    const token = localStorage.getItem("jwtTokenBakeca");
    if (token) {
      const decode = jwtDecode(token);
      const date = new Date().getTime();
      // change to decode.exp < date
      if (decode.exp > date) {
        localStorage.removeItem("jwtTokenBakeca");
        dispatch(actions.LOG_OUT.SUCCESS());
        setIsUserLoading(false);
      } else {
        localStorage.setItem("jwtTokenBakeca", token);
        dispatch(actions.SIGN_IN.SUCCESS(token));
        dispatch(actions.FETCH_USER.REQUEST());
        // setIsUserLoading(false);
      }
    } else {
      setIsUserLoading(false);
      dispatch(actions.LOG_OUT.SUCCESS());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin !== null) {
      setIsUserLoading(false);
    }
  }, [isAdmin]);

  return { isUserLoading };
};

export default useAuth;
