import ImpreseEdili from "./impresedili.svg";
import Ore from "./sole.svg";
import IndustriaItaliana from "./industriaitaliana.svg";
import PropTech from "./proptech.svg";
import StartupItalia from "./startup.svg";

export default {
  ImpreseEdili,
  Ore,
  IndustriaItaliana,
  PropTech,
  StartupItalia,
};
