import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoader } from "../../../../shared/Loader/store/selectors";
import styles from "./ForgotPassword.module.scss";
import { Field, Form, Formik } from "formik";

import { INITIAL_VALUES_FORGOT, VALIDATION_SCHEMA_FORGOT } from "../constants";
import { actions as reduxActions } from "../../../Auth/store";
import { setTypeModal } from "../../store/actions";

import TextField from "../../../Common/TextField";
import FormButton from "../../../Common/Buttons/FormButton";
import AuthHeader from "../AuthHeader";
import CloseBtn from "../CloseBtn";
import alertToast from "../../../AlertToast";

const ForgotPassword = () => {
  const loading = useSelector(getLoader());
  const dispatch = useDispatch();
  const [successStep, setSuccessStep] = useState(false);

  return (
    <div
      className={styles.forgot_pwd}
      style={{
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      {/* HEADER */}
      <AuthHeader
        headerText={"Recupera la tua password"}
        subheaderText={"Inserisci l’email con cui ti sei registrato."}
        paddingLeft={"20px"}
        paddingRight={"20px"}
      />
      <CloseBtn />

      {/* FORM */}
      <Formik
        initialValues={INITIAL_VALUES_FORGOT}
        validationSchema={VALIDATION_SCHEMA_FORGOT}
        onSubmit={(values, actions) => {
          if (!values.emailForgot) {
            return alertToast("error", "Inserisci la tua email");
          }

          dispatch(
            reduxActions.FORGOT_PASSWORD.REQUEST(values, () => {
              setSuccessStep(true);

              setTimeout(() => {
                dispatch(setTypeModal({ type: null, isBuy: false }));
                alertToast(
                  "success",
                  "Email inviata",
                  "Clicca nel link inviato nella tua casella di posta elettronica per resettare la password"
                );
                actions.resetForm();

                setTimeout(() => {
                  setSuccessStep(false);
                }, 500);
              }, 500);
            })
          );
        }}
      >
        {({ values, errors }) => (
          // console.log(errors, "errors forgot"),
          <Form className={styles.form}>
            <Field
              name="emailForgot"
              id="emailForgot"
              label="Email"
              component={TextField}
              type="email"
              height="40px"
            />
            {/* SUBMIT BTN */}
            <div className={styles.form__submit}>
              <FormButton
                successState={successStep}
                title="Invia email"
                disabled={
                  !values.emailForgot ||
                  Object.keys(errors).length !== 0 ||
                  loading
                }
                type="submit"
                width="100%"
                height="45px"
                loading={loading}
              />
              <div className={styles.form__submit__text}>
                Ti ricordi la password?{" "}
                <span
                  // href="#"
                  onClick={() => {
                    dispatch(setTypeModal({ type: "login" }));
                  }}
                  tabIndex="0"
                  onKeyPress={(e) =>
                    e.key === "Enter" &&
                    dispatch(setTypeModal({ type: "login" }))
                  }
                >
                  Accedi
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
