import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import styles from "./Navigation.module.scss";

import Nav from "./Nav";
import Sidebar from "./Sidebar";

import { getAuth } from "../../Auth/store/selectors";

const Navigation = ({ townInfoRef, whyRef, footerRef, hasMinimumInfo }) => {
  const isUserLoggedin = useSelector(getAuth());
  // console.log("isUserLoggedin", isUserLoggedin);

  return (
    <motion.nav
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
    >
      {/* Desktop nav */}
      <Nav
        townInfoRef={townInfoRef}
        whyRef={whyRef}
        footerRef={footerRef}
        isUserLoggedin={isUserLoggedin}
        hasMinimumInfo={hasMinimumInfo}
      />
      {/* Sidebar */}
      <Sidebar
        townInfoRef={townInfoRef}
        whyRef={whyRef}
        footerRef={footerRef}
        isUserLoggedin={isUserLoggedin}
        hasMinimumInfo={hasMinimumInfo}
      />
    </motion.nav>
  );
};

export default Navigation;
