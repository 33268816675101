import { ReactComponent as CloseBtnBtn } from "../../../../assets/svgs/close.svg";
import { setTypeModal } from "../../store/actions";
import { useDispatch } from "react-redux";
import styles from "./CloseBtn.module.scss";
import clx from "classnames";

const CloseBtn = ({ right, top, className }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={clx(styles.close_btn, className)}
      onClick={() => {
        dispatch(setTypeModal({ type: null, isBuy: false }));
      }}
      style={{ right, top }}
    >
      <CloseBtnBtn />
    </div>
  );
};

export default CloseBtn;
