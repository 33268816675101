import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import alertToast from "../../containers/AlertToast";

import { actions } from "./store";
import { getError } from "./store/selectors";
import { useEffect } from "react";

export default () => {
  const error = useSelector(getError());
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(actions.clearError());
  };
  const [algoErrorModal, setAlgoErrorModal] = useState(false);

  const selectError = (error) => {
    switch (error) {
      case "emptySignupFormFields":
        return {
          title: "Mancano campi richiesti nella registrazione",
          subtitle: "",
          type: "error",
        };
      case "mailAlreadyRegistered":
        return { title: "Email già registrata", subtitle: "", type: "warning" };
      case "dbErrorSignup":
        return {
          title: "Errore nella registrazione",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emptyLoginFormFields":
        return {
          title: "Mancano campi richiesti nell'autenticazione",
          subtitle: "",
          type: "error",
        };
      case "emailNotFoundOnLogin":
        return {
          title: "L'email inserita non è registrata",
          subtitle: "",
          type: "error",
        };
      case "googleUser":
        return {
          title: "Utente registrato con Google",
          subtitle:
            "Questo account è stato registrato con Google, si prega accedere con Google",
          type: "error",
        };
      case "facebookUser":
        return {
          title: "Utente registrato con Facebook",
          subtitle:
            "Questo account è stato registrato con Facebook, si prega accedere con Facebook",
          type: "error",
        };
      case "wrongPassword":
        return {
          title: "La password inserita non è corretta",
          subtitle: "",
          type: "error",
        };
      case "dbErrorLogin":
        return {
          title: "Errore interno del server durante l'autenticazione",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "Google token is missing.":
        return {
          title: "Problema con la registrazione tramite Google",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "dbErrorSignupGoogle":
        return {
          title: "Errore nella registrazione tramite Google",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emailNotFoundOnLoginGoogle":
        return {
          title: "L'email associata a Google non è registrata",
          subtitle: "",
          type: "error",
        };
      case "dbErrorLoginGoogle":
        return {
          title: "Errore nell'autenticazione tramite Google",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "Facebook token or user ID is missing.":
        return {
          title: "Problema con la registrazione tramite Facebook",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "dbErrorSignupFacebook":
        return {
          title: "Errore nella registrazione tramite Facebook",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emailNotFoundOnLoginFacebook":
        return {
          title: "L'email associata a Facebook non è registrata",
          subtitle: "",
          type: "error",
        };
      case "dbErrorLoginFacebook":
        return {
          title: "Errore nell'autenticazione tramite Facebook",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emptyForgotPasswordFields":
        return {
          title: "Manca l'email richiesta per cambiare la password",
          subtitle: "",
          type: "error",
        };
      case "emailNotFoundOnForgotPassword":
        return {
          title: "L'email inserita non è registrata",
          subtitle: "",
          type: "error",
        };
      case "dbErrorForgotPassword":
        return {
          title: "Errore interno del server durante il cambio password",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emptyUpdatePasswordFields":
        return {
          title: "Manca la nuova password richiesta per cambiare la password",
          subtitle: "",
          type: "error",
        };
      case "emailNotFoundOnUpdatePassword":
        return {
          title: "L'email inserita non è registrata",
          subtitle: "",
          type: "error",
        };
      case "dbErrorUpdatePassword":
        return {
          title: "Errore interno del server durante il reset della password",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "dbErrorUserRetrieval":
        return {
          title:
            "Si è verificato un problema durante il recupero dell'utente registrato",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "dbErrorUpdatingUser":
        return {
          title:
            "Errore interno del server durante l'aggiornamento dell'utente",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "userToDeleteNotFound":
        return {
          title: "L'utente da cancellare non è stato trovato",
          subtitle: "",
          type: "error",
        };
      case "userDeletionError":
        return {
          title:
            "Si è verificato un problema durante il cancellamento dell'utente registrato",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "emptyChangePasswordFormFields":
        return {
          title: "Mancano i campi richiesti per cambiare la password",
          subtitle: "",
          type: "error",
        };
      case "emailNotFoundOnChangePassword":
        return {
          title: "L'email inserita non è registrata",
          subtitle: "",
          type: "error",
        };
      case "old password inserted doesnt match with registered password":
        return {
          title:
            "La vecchia password inserita non corrisponde a quella registrata",
          subtitle: "",
          type: "error",
        };
      case "dbErrorChangePassword":
        return {
          title: "Errore interno del server durante il cambiamento password",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "transactionsRetrievedUnsuccesfully":
        return {
          title:
            "Si è verificato un problema durante il recupero delle transazioni dell'utente",
          subtitle:
            "Si è verificato un problema con il server. Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "tokenMismatch":
        return {
          title: "Utente non autorizzato",
          subtitle: "Token non corrispondente al token associato all'utente",
          type: "error",
        };
      case "tokenNotPresentOnResetPassword":
        return {
          title: "Token non presente",
          subtitle:
            "Il token necessario per il reset della password non è presente, prova a richiedere un nuovo reset della password",
          type: "error",
        };
      case "tokenMissing":
        return {
          title: "Utente non autorizzato",
          subtitle:
            "è necessario essere autenticati per eseguire questa operazione",
          type: "error",
        };
      case "teaserDataRetrievalError":
        return {
          title:
            "L'immobile selezionato non è più disponibile negli archivi di Bakeca",
          subtitle: "",
          type: "warning",
        };
      case "dbErrorTeaserRetrieval":
        return {
          title: "Errore nel recupero dei dati dell'immobile",
          subtitle: "",
          type: "error",
        };
      case "dbErrorReportRetrieval":
        return {
          title: "Errore nel recupero dei dati dell'immobile",
          subtitle: "",
          type: "error",
        };
      case "reportDataRetrievalError":
        return {
          title: "Errore nel recupero dei dati del report immobile",
          subtitle: "",
          type: "error",
        };
      case "missingRequiredDataToGenerateReport":
        return {
          title: "Campi necessari per generare il report mancanti",
          subtitle: "",
          type: "error",
        };
      case "missingRequiredDataToSendContactEmail":
        return {
          title: "Campi necessari per inviare email mancanti",
          subtitle: "",
          type: "error",
        };
      case "contactEmailNotSent":
        return {
          title: "Si e' verificato un problema nell'invio dell'email",
          subtitle: "",
          type: "error",
        };
      case "Stripe payment unsuccessfull":
        return {
          title: "Si e' verificato un problema con il pagamento via Stripe",
          subtitle: "",
          type: "error",
        };
      case "dbErrorReportPdfRetrieval":
        return {
          title: "Si e' verificato un problema con il recupero del tuo PDF",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
      case "dbErrorGoogleReviewsRetrieval":
        return {
          title:
            "Si e' verificato un problema con il recupero delle recensioni Google",
          subtitle: "",
          type: "error",
        };
      case "FAILED TO GET REPORT BY ID FROM DASHBOARD DOWNLOAD":
        return {
          title: "Si e' verificato un'errore nello scaricamento del pdf",
          subtitle: "",
          type: "error",
        };
      case "ERROR GENERATING REPORT FROM DASHBOARD DOWNLOAD":
        return {
          title: "Si e' verificato un'errore nello scaricamento del pdf",
          subtitle: "",
          type: "error",
        };
      case "ERROR GENERATING PDF FROM DASHBOARD DOWNLOAD":
        return {
          title: "Si e' verificato un'errore nello scaricamento del pdf",
          subtitle: "",
          type: "error",
        };
      case "dbErrorReportsRetrieval":
        return {
          title: "Si e' verificato un'errore nel recupero dei tuoi reports",
          subtitle: "",
          type: "error",
        };
      case "dbErrorReportsRemoval":
        return {
          title: "Si e' verificato un'errore nella rimozione del report",
          subtitle: "",
          type: "error",
        };
      case "insufficient funds stripe":
        return {
          title: "Fondi insufficienti per effettuare il pagamento",
          subtitle: "",
          type: "error",
        };
      default:
        return {
          title:
            "Si è verificato un problema nell' esecuzione dell' operazione richiesta",
          subtitle:
            "Contatta il team Houseplus che risolvera' il problema al più presto",
          type: "error",
        };
    }
  };

  useEffect(() => {
    if (error) {
      alertToast(
        selectError(error).type,
        selectError(error).title,
        selectError(error).subtitle,
        clear
      );
    }
  }, [error]);
};
