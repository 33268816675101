import styles from "./Total.module.scss";
import { formatNumber } from "../../../../../utils/formatNumber";

const Total = () => {
  return (
    <div className={styles.total_info}>
      <div className={styles.subtitle}>Importo</div>
      <div className={styles.total_info__price_info}>
        <div className={styles.text}>Imponibile</div>
        <div className={styles.number}>€ {formatNumber(4)}</div>
      </div>
      <div className={styles.total_info__price_info}>
        <div className={styles.text}>Iva</div>
        <div className={styles.number}>€ {formatNumber((4 * 22) / 100)}</div>
      </div>
      <div className={styles.total_info__price_info}>
        <div className={styles.text}>Totale</div>
        <div className={styles.number}>€ {formatNumber(4 * 1.22)}</div>
      </div>
    </div>
  );
};

export default Total;
