import { useDispatch } from "react-redux";
import styles from "./CompleteCard.module.scss";
import clx from "classnames";
import Download from "../../../../assets/svgs/download-pdf-mobile.svg";
import NotImg from "../../../../assets/images/img-not-available.png";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import Delete from "../../../../assets/svgs/delete.svg";

import { actions } from "../../store";

const CompleteCard = ({
  id,
  url_foto,
  url_annuncio,
  address,
  valuation,
  setDeleteModal,
  semaforo,
}) => {
  const dispatch = useDispatch();

  const handleDeleteReport = (reportId, reportUrl) => {
    setDeleteModal({
      isVisible: true,
      reportId,
      reportUrl,
    });
  };

  const downloadUserReport = (reportId) => {
    dispatch(actions.DOWNLOAD_FROM_DASHBOARD.REQUEST(reportId));
  };

  return (
    <div className={styles.complete__card}>
      <img
        className={styles.complete__card__image}
        src={url_foto || NotImg}
        alt="card-image"
      />
      <div className={styles.complete__card__id}>
        <span>URL annuncio:</span>{" "}
        <a href={url_annuncio} target="_blank" rel="noreferrer">
          {url_annuncio?.length > 30
            ? `${url_annuncio?.slice(0, 30)}...`
            : url_annuncio}
        </a>
      </div>
      <div className={styles.complete__card__address}>
        {address.length > 50 ? `${address.slice(0, 50)}...` : address}
      </div>
      <div className={styles.complete__card__valuation}>
        <span>Valutazione:</span> {valuation}
      </div>
      <div className={styles.complete__card__semaforo}>
        <div
          className={clx(styles.complete__card__semaforo__red, {
            [styles.inactive]: semaforo !== "red",
          })}
        />
        <div
          className={clx(styles.complete__card__semaforo__yellow, {
            [styles.inactive]: semaforo !== "orange",
          })}
        />
        <div
          className={clx(styles.complete__card__semaforo__green, {
            [styles.inactive]: semaforo !== "green",
          })}
        />
      </div>
      <div className={styles.complete__card__ctas}>
        <PrimaryButton
          rightIconSrc={Download}
          width={"100%"}
          background={"#64748b"}
          className={styles.copy}
          iconWidth={17}
          onClick={() => downloadUserReport(id)}
        />
        <SecondaryButton
          rightIconSrc={Delete}
          width={"100%"}
          color={"#b10000"}
          borderColor={"#b10000"}
          className={styles.delete}
          onClick={() => handleDeleteReport(id, url_annuncio)}
        />
      </div>
    </div>
  );
};

export default CompleteCard;
