import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./Nav.module.scss";

import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import BakecaHpLogo from "../../../assets/svgs/bakeca-hp-logo.svg";
import LogOut from "../../../assets/svgs/logout.svg";
import Homepage from "../../../assets/svgs/homepage.svg";

import { actions } from "../../Auth/store";

const Nav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={styles.dashboard__nav}>
      {/* Logo large screens */}
      <div className={styles.dashboard__nav__logo}>
        <img src={BakecaHpLogo} alt="logo" />
      </div>
      {/* Logo small screens */}
      {/* <div className={styles.dashboard__nav__logo_small}>
        <img src={HpLogoSmall} alt="logo" />
      </div> */}

      {/* Links */}
      <div className={styles.dashboard__nav__links}>
        {/* Links large */}
        <div
          className={styles.dashboard__nav__links__link}
          onClick={() => navigate("/")}
        >
          <PrimaryButton text="Homepage" leftIconSrc={Homepage} width={150} />
        </div>
        <div
          className={styles.dashboard__nav__links__link}
          onClick={() => {
            dispatch(actions.LOG_OUT.REQUEST());
          }}
        >
          <SecondaryButton text="Esci" leftIconSrc={LogOut} />
        </div>
        {/* Links small */}
        <div
          className={styles.dashboard__nav__links__link_mobile}
          onClick={() => navigate("/")}
        >
          <PrimaryButton leftIconSrc={Homepage} width={50} height={50} />
          <p>Home</p>
        </div>
        <div
          className={styles.dashboard__nav__links__link_mobile}
          onClick={() => {
            dispatch(actions.LOG_OUT.REQUEST());
          }}
        >
          <SecondaryButton leftIconSrc={LogOut} width={50} height={50} />
          <p>Esci</p>
        </div>
      </div>
    </div>
  );
};

export default Nav;
