import HorizontalBar from "../../../../Common/Charts/HorizontalBar";

const Education = ({ data, minHeight, wrapperHeight, marginTop }) => {
  // console.log(data);
  const chartData = data?.data?.y?.map((item, index) => ({
    // 'y': item[0].toUpperCase() + item.substring(1).split('_').join(' '),
    y: item[0].toUpperCase() + item.substring(1)?.split("_")[0],
    Uomini: data?.data?.x[0][index],
    Donne: data?.data?.x[1][index],
    percentUomini: `${data?.data?.displayed_values[0][index].toFixed(1)}%`,
    percentDonne: `${data?.data?.displayed_values[1][index].toFixed(1)}%`,
  }));
  // console.log(chartData, 'EDUCATION');

  return (
    <div style={{ position: "relative" }}>
      <HorizontalBar
        title={data?.title}
        description={data?.description}
        label={data?.data?.xtitle}
        legend={data?.data?.legend}
        dx={-15}
        limit={data?.data?.xlimit}
        marginLeft={15}
        minHeight={minHeight}
        wrapperHeight={wrapperHeight}
        marginTop={marginTop}
        chartData={chartData}
      />
    </div>
  );
};

export default Education;
