import { useEffect } from "react";

const FacebookSDKInit = ({ appId }) => {
  useEffect(() => {
    // Load the SDK Asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    // Initialize the SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: appId,
        cookie: true, // Enable cookies to allow the server to access the session
        xfbml: true, // Parse social plugins on this webpage
        version: "v12.0", // Use the correct version
      });

      // Additional initialization code such as adding Event Listeners
    };
  }, [appId]);

  return null;
};

export default FacebookSDKInit;
