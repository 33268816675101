import styles from "./ResetPassword.module.scss";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import {
  INITIAL_VALUES_RESET,
  VALIDATION_SCHEMA_RESET,
} from "../../Landing/Auth/constants";
import { setError } from "../../../shared/Error/store/actions";
import { actions as reduxActions } from "../store";

import AuthImg from "../../../assets/images/auth-img-4.png";

import TextField from "../../Common/TextField";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import HOC from "../HOC";
import AuthHeader from "../../Landing/Auth/AuthHeader";
import alertToast from "../../AlertToast";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  return (
    <HOC
      maxImageWidth={"450px"}
      MainImg={AuthImg}
      contentGap={"20px"}
      minHeight={"auto"}
      ImgMarginTop={"-20px"}
      leftMinHeight={"100vh"}
    >
      {/* HEADER */}
      <AuthHeader
        headerText={"Imposta la tua nuova password"}
        subheaderText={"La nuova password deve contenere almeno 3 caratteri"}
        marginBottom={"60px"}
      />

      {/* FORM */}
      <Formik
        initialValues={INITIAL_VALUES_RESET}
        validationSchema={VALIDATION_SCHEMA_RESET}
        onSubmit={async (values) => {
          if (!values.newPwd || !values.newPwdConfirmation) {
            return alertToast(
              "error",
              "Compila tutti i campi",
              "Si prega di compilare i campi password e conferma password"
            );
          }

          if (token) {
            localStorage.setItem("jwtTokenBakeca", token);
            dispatch(
              reduxActions.RESET_PASSWORD.REQUEST(
                {
                  newPwd: values.newPwd,
                },
                () => {
                  localStorage.removeItem("jwtTokenBakeca");
                  alertToast(
                    "success",
                    "Password aggiornata",
                    "Puoi adesso accedere con la tua nuova password"
                  );

                  setTimeout(() => {
                    navigate("/");
                  }, 1000);
                }
              )
            );
          } else {
            dispatch(setError("tokenNotPresentOnResetPassword"));
          }
        }}
      >
        {({ isSubmitting, values, errors }) => (
          <Form className={styles.form}>
            <Field
              name="newPwd"
              id="newPwd"
              label="Nuova Password"
              type="password"
              placeholder="Password"
              component={TextField}
              showPassword={true}
            />
            <Field
              name="newPwdConfirmation"
              id="newPwdConfirmation"
              label="Riscrivi la Password scelta"
              type="password"
              placeholder="Password"
              component={TextField}
              showPassword={true}
            />
            <PrimaryButton
              width="100%"
              height="55px"
              text="Imposta nuova Password"
              background={"#078ea6"}
              className={styles.submit_btn}
              disabled={
                // isSubmitting ||
                !values.newPwd ||
                !values.newPwdConfirmation ||
                Object.keys(errors).length > 0
              }
            />
          </Form>
        )}
      </Formik>
    </HOC>
  );
};

export default ResetPassword;
