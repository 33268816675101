const propertyMapping = (property) => {
  switch (property) {
    case "monolocale":
      return "Monolocale";
      break;
    case "bilocale":
      return "Bilocale";
      break;
    case "trilocale":
      return "Trilocale";
      break;
    case "quadrilocale":
      return "Quadrilocale";
      break;
    case "cinque-o-piu-locali":
      return "Cinque o più locali";
      break;
    case "attico":
      return "Attico";
      break;
    case "mansarda":
      return "Mansarda";
      break;
    case "villa":
      return "Villa";
      break;
    case "villetta":
      return "Villetta";
      break;
    default:
      return "Bilocale";
  }
};

export default propertyMapping;
