import clx from "classnames";
import styles from "./SecondPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import formatToEuro from "../../../../utils/formatToEuro";

const SecondPg = ({
  semaforo,
  semaforo_GPT,
  adv_price = 0,
  adv_price_mq = 0,
  min_price = 0,
  max_price = 0,
  min_price_mq = 0,
  max_price_mq = 0,
  prediction_mq = 0,
  prediction = 0,
}) => {
  return (
    <div
      className={styles.second_page}
      style={{
        gap: semaforo_GPT?.length < 700 && "60px",
        paddingTop: semaforo_GPT?.length < 700 && "60px",
      }}
    >
      {/* EVALUATION */}
      <div className={styles.second_page__evaluation}>
        {/* TITLE */}
        <div className={clx(stylesMain.page__section_title, styles.title)}>
          Valutazione immobile
        </div>

        {/* STIMAS */}
        <div className={styles.stimas}>
          {/* STIMA */}
          <div className={styles.stimas__stima}>
            <div className={styles.stimas__stima__container}>
              <div className={styles.text}>Range minimo</div>
              <div className={styles.min}>
                &euro;{formatToEuro(min_price?.toFixed(0) || 0)}
              </div>
            </div>
            <div className={styles.stimas__stima__container}>
              <div className={styles.text_prediction}>La stima Houseplus</div>
              <div className={styles.prediction}>
                &euro;{formatToEuro(prediction?.toFixed(0) || 0)}
              </div>
            </div>
            <div className={styles.stimas__stima__container}>
              <div className={styles.text}>Range massimo</div>
              <div className={styles.max}>
                &euro;{formatToEuro(max_price?.toFixed(0) || 0)}
              </div>
            </div>
          </div>
          {/* STIMA MQ */}
          <div className={styles.stimas__stima}>
            <div className={styles.stimas__stima__container}>
              <div className={styles.min}>
                &euro;{formatToEuro(min_price_mq?.toFixed(0) || 0)}/
                <span>m</span>
                <sup style={{ fontSize: 8 }}>2</sup>
              </div>
            </div>
            <div className={styles.stimas__stima__container}>
              <div
                className={styles.prediction_mask}
                style={{ backgroundColor: "white" }}
              >
                <div
                  className={styles.prediction}
                  style={{ backgroundColor: "rgba(7, 142, 166, 0.5)" }}
                >
                  &euro;{formatToEuro(prediction_mq?.toFixed(0) || 0)}/
                  <span>m</span>
                  <sup style={{ fontSize: 12 }}>2</sup>
                </div>
              </div>
            </div>
            <div className={styles.stimas__stima__container}>
              <div className={styles.max}>
                &euro;{formatToEuro(max_price_mq?.toFixed(0) || 0)}/
                <span>m</span>
                <sup style={{ fontSize: 8 }}>2</sup>
              </div>
            </div>
          </div>
        </div>

        {/* SEMAFORO */}
        <div
          className={styles.semaforo}
          style={{
            backgroundColor:
              semaforo == "red"
                ? "rgba(205, 98, 98, 0.5)"
                : semaforo == "green"
                ? "rgba(100, 194, 98, 0.5)"
                : semaforo == "orange"
                ? "rgba(245, 175, 69, 0.5)"
                : "",
          }}
        >
          <div className={styles.semaforo__lights}>
            <div
              className={clx(styles.semaforo__lights__red, {
                [styles.inactive]: semaforo !== "red",
              })}
            />
            <div
              className={clx(styles.semaforo__lights__yellow, {
                [styles.inactive]: semaforo !== "orange",
              })}
            />
            <div
              className={clx(styles.semaforo__lights__green, {
                [styles.inactive]: semaforo !== "green",
              })}
            />
          </div>

          {/* WHY SEMAFORO */}
          <div className={styles.semaforo__whysemaforo}>
            <div
              className={clx(
                stylesMain.page__text,
                styles.semaforo__whysemaforo__text
              )}
            >
              L'immobile in annuncio al prezzo di &euro;
              {formatToEuro(adv_price?.toFixed(0) || 0)} rappresenta{" "}
              {semaforo == "green"
                ? "un'ottima opportunità di"
                : semaforo == "red"
                ? "un pessimo"
                : semaforo == "orange"
                ? "un mediocre"
                : ""}{" "}
              investimento
            </div>
            <div
              className={clx(
                stylesMain.page__text,
                styles.semaforo__whysemaforo__info
              )}
            >
              Consulta l'intero report per una valutazione completa
            </div>
          </div>
        </div>

        {/* TEXT SUMMARY */}
        <div className={styles.text_summary}>
          <div className={stylesMain.page__text}>{semaforo_GPT}</div>
        </div>
      </div>

      {/* AD PRICE */}
      <div className={styles.second_page__ad_price}>
        {/* TITLE */}
        <div className={stylesMain.page__section_title}>Prezzo in annuncio</div>
        {/* PRICES */}
        <div className={styles.prices}>
          <div className={styles.prediction}>
            &euro;{formatToEuro(adv_price?.toFixed(0) || 0)}
          </div>
          <div
            className={styles.prediction}
            style={{ backgroundColor: "rgba(7, 142, 166, 0.5)" }}
          >
            &euro;{formatToEuro(adv_price_mq?.toFixed(0) || 0)}/<span>m</span>
            <sup style={{ fontSize: 12 }}>2</sup>
          </div>
        </div>
      </div>

      {/* COMPARATION */}
      <div className={styles.second_page__comparation}>
        {/* TITLE */}
        <div className={stylesMain.page__section_title}>
          Comparazione tra stima Houseplus e prezzo in annuncio
        </div>
        {/* FORMULAS */}
        <div className={styles.formulas}>
          <div
            className={styles.formulas__formula}
            style={{
              backgroundColor:
                semaforo == "red"
                  ? "rgba(205, 98, 98, 0.5)"
                  : semaforo == "green"
                  ? "rgba(100, 194, 98, 0.5)"
                  : semaforo == "orange"
                  ? "rgba(245, 175, 69, 0.5)"
                  : "",
            }}
          >
            {prediction - adv_price > 0
              ? "+"
              : prediction - adv_price < 0
              ? "-"
              : ""}{" "}
            &euro;{formatToEuro(prediction - adv_price || 0)}
          </div>
          <div
            className={styles.formulas__formula}
            style={{
              backgroundColor:
                semaforo == "red"
                  ? "rgba(205, 98, 98, 0.5)"
                  : semaforo == "green"
                  ? "rgba(100, 194, 98, 0.5)"
                  : semaforo == "orange"
                  ? "rgba(245, 175, 69, 0.5)"
                  : "",
            }}
          >
            {prediction_mq - adv_price_mq > 0
              ? "+"
              : prediction_mq - adv_price_mq < 0
              ? "-"
              : ""}{" "}
            &euro;{formatToEuro(prediction_mq - adv_price_mq || 0)}/
            <span>m</span>
            <sup style={{ fontSize: 12 }}>2</sup>
          </div>
          <div
            className={styles.formulas__formula}
            style={{
              backgroundColor:
                semaforo == "red"
                  ? "rgba(205, 98, 98, 0.5)"
                  : semaforo == "green"
                  ? "rgba(100, 194, 98, 0.5)"
                  : semaforo == "orange"
                  ? "rgba(245, 175, 69, 0.5)"
                  : "",
            }}
          >
            {(prediction * 100) / adv_price - 100 > 0 ? "+" : ""}{" "}
            {((prediction * 100) / adv_price - 100).toFixed(0)}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondPg;
