import { call, put } from "redux-saga/effects";

import { setError } from "../shared/Error/store/actions";
import { startLoader, stopLoader } from "../shared/Loader/store/actions";

export default function* (successAction, errorAction, isModalLoader = true) {
  try {
    yield put(startLoader(isModalLoader));
    yield call(successAction());
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          error?.message ||
          null
      )
    );
    yield put(
      errorAction(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          null
      )
    );
  } finally {
    yield put(stopLoader(isModalLoader));
  }
}
