import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  rightHOC,
  rightHOCItem,
  leftHOC,
  leftHOCItem,
} from "./Motion/MotionVariants";
import styles from "./HOC.module.scss";
import AuthSvg from "../../../assets/svgs/auth-svg.svg";

import ImageList from "./ImageList";

const HOC = ({
  children,
  MainImg,
  contentGap,
  minHeight,
  ImgMarginTop,
  maxImageWidth,
  leftHeight,
  leftMinHeight,
  contentPadding,
}) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.5,
          ease: "easeOut",
        },
      }}
      transition={{
        duration: 0.5,
        ease: "easeIn",
      }}
      className={styles.HOC}
    >
      {/* LEFT SIDE */}
      <motion.div
        className={styles.HOC__left}
        style={{ height: leftHeight, minHeight: leftMinHeight }}
        initial="hiddenLeft"
        animate="visibleLeft"
        variants={leftHOC}
      >
        {/* LOGO */}
        <motion.div
          className={styles.HOC__left__logo}
          alt="logo"
          variants={leftHOCItem}
        />

        {/* CONTENT */}
        <motion.div
          className={styles.HOC__left__content}
          variants={leftHOCItem}
          style={{ padding: contentPadding }}
        >
          {children}
        </motion.div>

        {/* IMAGES LIST */}
        <ImageList className={"HOC__left__list"} leftHOCItem={leftHOCItem} />
      </motion.div>

      {/* RIGHT SIDE */}
      <motion.div
        className={styles.HOC__right}
        style={{ minHeight }}
        initial="hidden"
        animate="visible"
        variants={rightHOC}
      >
        <div className={styles.HOC__right__content} style={{ gap: contentGap }}>
          {/* HEADER */}
          <motion.div
            className={styles.HOC__right__header}
            variants={rightHOCItem}
          >
            <h2 className={styles.HOC__right__header__large}>
              Lorem ipsum dolor sit amet consectetur dolor.
            </h2>
            <p className={styles.HOC__right__header__small}>
              Lorem ipsum dolor sit amet consectetur nunc mattis.
            </p>
          </motion.div>

          {/* IMAGE */}
          <motion.img
            src={MainImg || AuthSvg}
            alt="Auth"
            className={styles.HOC__right__img}
            style={{ maxWidth: maxImageWidth, marginTop: ImgMarginTop }}
            variants={rightHOCItem}
          />

          {/* IMAGES LIST */}
          <ImageList
            className={"HOC__right__list"}
            rightHOCItem={rightHOCItem}
          />
        </div>
      </motion.div>
    </motion.section>
  );
};

export default HOC;
