import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./SignUp.module.scss";
import clx from "classnames";
import { Field, Form, Formik } from "formik";

import { INITIAL_VALUES_SIGNUP, VALIDATION_SCHEMA_SIGNUP } from "../constants";
import { setTypeModal } from "../../store/actions";
import { getTypeModal } from "../../store/selectors";
import { getLoader } from "../../../../shared/Loader/store/selectors";
import signUp from "./utils/signUp";

import AuthHeader from "../AuthHeader";
import CloseBtn from "../CloseBtn";
import GoogleBtn from "../GoogleBtn";
import FacebookBtn from "../FacebookBtn";
import TextField from "../../../Common/TextField";
import CheckboxField from "../../../Common/CheckboxField";
import FormButton from "../../../Common/Buttons/FormButton";
import alertToast from "../../../AlertToast";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoader());
  const typeModal = useSelector(getTypeModal());
  const [successStep, setSuccessStep] = useState(false);

  return (
    <div
      className={styles.signup}
      style={{
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      <AuthHeader
        headerText={"Registrati"}
        subheaderText={
          "Ti registri con queste poche informazioni, scegli come pagare e hai subito il tuo report Houseplus!"
        }
        paddingLeft={"20px"}
        paddingRight={"20px"}
      />
      <CloseBtn />

      {/* FORMS */}
      <div className={styles.signup__form}>
        {/* SOCIAL AUTH BTNS */}
        <div className={styles.signup__form__social}>
          <div className={styles.signup__form__social__btns}>
            {/* GOOGLE BUTTON */}
            <GoogleBtn
              className={styles.signup__form__social__btns__btn}
              callback={signUp}
              setSuccessStep={setSuccessStep}
            />
            {/* FACEBOOK BUTTON */}
            <FacebookBtn
              className={styles.signup__form__social__btns__btn}
              classNameTwo={styles.facebook}
              callback={signUp}
              setSuccessStep={setSuccessStep}
            />
          </div>

          <div className={styles.signup__form__social__btns__login}>
            <div className={styles.signup__form__social__btns__login__cta}>
              Hai gia un account?{" "}
              <span
                // href="#"
                onClick={() => {
                  dispatch(setTypeModal({ type: "login" }));
                  // RESET SIGNUP FORM HERE
                }}
                tabIndex="0"
                onKeyPress={(e) =>
                  e.key === "Enter" && dispatch(setTypeModal({ type: "login" }))
                }
              >
                Accedi
              </span>
            </div>
          </div>
        </div>

        {/* DIVIDER */}
        <div className={styles.signup__form__hr}>
          <span>Oppure</span>
        </div>

        {/* FORMIK FORM */}
        <div className={styles.signup__form__formik}>
          <Formik
            initialValues={INITIAL_VALUES_SIGNUP}
            validationSchema={VALIDATION_SCHEMA_SIGNUP}
            onSubmit={async (values, actions) => {
              if (
                !values.nameSignup ||
                !values.emailSignup ||
                !values.pwdSignup ||
                !values.acceptPolicy
              ) {
                return alertToast(
                  "error",
                  "Compila tutti i campi",
                  "Si prega di compilare tutti i campi richiesti"
                );
              }

              signUp(
                dispatch,
                "",
                "",
                {
                  auth_name: values.nameSignup,
                  email: values.emailSignup,
                  pwd: values.pwdSignup,
                },
                setSuccessStep,
                navigate,
                typeModal
              );
            }}
          >
            {({ isSubmitting, errors, values }) => (
              <Form className={styles.form}>
                <div className={styles.form__field}>
                  <Field
                    name="nameSignup"
                    id="nameSignup"
                    label="Nome e Cognome"
                    component={TextField}
                    height="40px"
                  />
                </div>
                <div className={styles.form__field}>
                  <Field
                    name="emailSignup"
                    id="emailSignup"
                    label="Email"
                    labelInfo="(nella quale riceverai il tuo report PDF)"
                    component={TextField}
                    type="email"
                    height="40px"
                  />
                </div>
                <div className={styles.form__field}>
                  <Field
                    name="pwdSignup"
                    id="pwdSignup"
                    label="Scegli una password"
                    component={TextField}
                    type="password"
                    showPassword={true}
                    height="40px"
                  />
                </div>
                <div className={clx(styles.form__field, styles.privacy)}>
                  <Field
                    name="acceptPolicy"
                    id="acceptPolicy"
                    label={
                      <a
                        href="https://uploads-ssl.webflow.com/6377c6cc4ffcbe580af46c7f/654d304fe946c0ade97232ab_termini-e-condizioni.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Accetto termini e condizioni
                      </a>
                    }
                    component={CheckboxField}
                    gap="6px"
                    labelStyle={{ color: "#0f172a", fontSize: "12px" }}
                    border="1px solid #0f172a"
                    padding="0px 0px 0px 2px"
                  />
                </div>

                <div className={styles.form__submit}>
                  <FormButton
                    successState={successStep}
                    title="Procedi con la registrazione"
                    disabled={
                      loading ||
                      isSubmitting ||
                      !values.nameSignup ||
                      !values.emailSignup ||
                      !values.pwdSignup ||
                      !values.acceptPolicy ||
                      Object.keys(errors).length > 0
                    }
                    type="submit"
                    width="100%"
                    height="45px"
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <div className={styles.signup__form__login}>
            <div className={styles.signup__form__login__cta}>
              Hai gia un account?{" "}
              <span
                // href="#"
                onClick={() => {
                  dispatch(setTypeModal({ type: "login" }));
                  // RESET SIGNUP FORM HERE
                }}
                tabIndex="0"
                onKeyPress={(e) =>
                  e.key === "Enter" && dispatch(setTypeModal({ type: "login" }))
                }
              >
                Accedi
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
