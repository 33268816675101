import * as yup from "yup";

export const INITIAL_VALUES_ACCOUNT_NAME = {
  acct_name: "",
};

export const VALIDATION_SCHEMA_ACCOUNT_NAME = yup.object().shape({
  acct_name: yup
    .string("Inserisci il tuo nuovo nome account")
    .when("$showName", (anyName, schema) =>
      anyName ? schema.required("Nuovo nome richiesto") : schema
    ),
});

export const INITIAL_VALUES_ACCOUNT_PASSWORD = {
  oldPassword: "",
  newPassword: "",
};

export const VALIDATION_SCHEMA_ACCOUNT_PASSWORD = yup.object().shape({
  oldPassword: yup
    .string()
    .min(3, "Password è troppo corta")
    .max(100, "Password troppo lunga")
    .when("$showPassword", (anyName, schema) =>
      anyName ? schema.required("Password corrente richiesta") : schema
    ),
  newPassword: yup
    .string()
    // .oneOf([yup.ref("pwd"), null], "Attenzione! Le password non coincidono")
    .min(3, "La password è troppe breve")
    .max(100, "La password è troppo lunga")
    .when("$showPassword", (anyName, schema) =>
      anyName ? schema.required("Nuova password richiesta") : schema
    ),
});
