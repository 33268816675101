import styles from "./DeleteAccountModal.module.scss";
import { useDispatch } from "react-redux";

import { actions } from "../../../Auth/store";

import Modal from "../../../Common/Modal";
import { ReactComponent as Close } from "../../../../assets/svgs/close.svg";
import Delete from "../../../../assets/svgs/delete.svg";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import alertToast from "../../../AlertToast";

const DeleteAccountModal = ({ deleteModal, setDeleteModal }) => {
  const dispatch = useDispatch();

  return (
    <Modal modalState={deleteModal} width={"80%"} maxWidth={"500px"}>
      <div className={styles.modal_content}>
        {/* CLOSE BUTTON */}
        <div className={styles.close_btn}>
          <Close onClick={() => setDeleteModal(false)} />
        </div>
        <div className={styles.modal_content}>
          <p className={styles.modal_content__header}>
            Sei sicuro di voler eliminare il tuo account?
          </p>
          <p
            className={styles.modal_content__header}
            style={{ textDecoration: "underline", fontWeight: 600 }}
          >
            Questa azione non e' reversibile
          </p>
          <div className={styles.modal_content__ctas}>
            <SecondaryButton
              text="Elimina"
              rightIconSrc={Delete}
              color={"#b10000"}
              borderColor={"#b10000"}
              className={styles.delete_acct}
              onClick={() => {
                dispatch(
                  actions.DELETE_ACCOUNT.REQUEST("", () => {
                    dispatch(actions.LOG_OUT.REQUEST());
                    alertToast("success", "Account eliminato con successo");
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
