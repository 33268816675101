import { useState } from "react";
import styles from "./Account.module.scss";

import Delete from "../../../assets/svgs/delete.svg";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import useNoScroll from "../../../utils/useNoScroll";

import NameForm from "./NameForm";
import PasswordForm from "./PasswordForm";
import DeleteAccountModal from "./DeleteAccountModal";

const Account = ({ name, email, isSocialAccount }) => {
  const [deleteModal, setDeleteModal] = useState(false);

  // ADJUST BODY SCROLL WHEN MODALS ARE OPEN
  useNoScroll(deleteModal);

  return (
    <div className={styles.account}>
      {/* EMAIL */}
      <div className={styles.account__block}>
        <div className={styles.account__block__title}>Email</div>
        <div className={styles.text}>{email}</div>
      </div>

      {!isSocialAccount && (
        <>
          {/* NAME */}
          <NameForm name={name} />

          {/* PASSWORD */}
          <PasswordForm />
        </>
      )}

      {/* DELETE BTN */}
      <SecondaryButton
        text="Elimina account"
        rightIconSrc={Delete}
        width={"100%"}
        color={"#b10000"}
        borderColor={"#b10000"}
        className={styles.delete_acct}
        onClick={() => setDeleteModal(true)}
      />

      {/* DELETE ACCOUNT MODAL */}
      <DeleteAccountModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

export default Account;
