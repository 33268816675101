import clx from "classnames";
import styles from "./NinthPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import formatToEuro from "../../../../utils/formatToEuro";

const NinthPg = ({ semaforo, finalEvaluation, adv_price }) => {
  // console.log(finalEvaluation, "FINAL EVALUATION");

  return (
    <div className={styles.ninth_page}>
      {/* PAGE TITLE */}
      <div className={stylesMain.page__section_title}>Valutazione annuncio</div>

      {/* SEMAFORO */}
      <div
        className={styles.ninth_page__semaforo}
        style={{
          backgroundColor:
            semaforo == "red"
              ? "rgba(205, 98, 98, 0.5)"
              : semaforo == "green"
              ? "rgba(100, 194, 98, 0.5)"
              : semaforo == "orange"
              ? "rgba(245, 175, 69, 0.5)"
              : "",
        }}
      >
        <div className={styles.ninth_page__semaforo__lights}>
          <div
            className={clx(styles.ninth_page__semaforo__lights__red, {
              [styles.inactive]: semaforo !== "red",
            })}
          />
          <div
            className={clx(styles.ninth_page__semaforo__lights__yellow, {
              [styles.inactive]: semaforo !== "orange",
            })}
          />
          <div
            className={clx(styles.ninth_page__semaforo__lights__green, {
              [styles.inactive]: semaforo !== "green",
            })}
          />
        </div>

        {/* WHY SEMAFORO */}
        <div className={styles.ninth_page__semaforo__whysemaforo}>
          <div
            className={clx(
              stylesMain.page__text,
              styles.ninth_page__semaforo__whysemaforo__text
            )}
          >
            L'immobile in annuncio al prezzo di &euro;
            {formatToEuro(adv_price?.toFixed(0) || 0)} rappresenta{" "}
            {semaforo == "green"
              ? "un'ottima opportunità di"
              : semaforo == "red"
              ? "un pessimo"
              : semaforo == "orange"
              ? "un mediocre"
              : ""}{" "}
            investimento
          </div>
          <div
            className={clx(
              stylesMain.page__text,
              styles.ninth_page__semaforo__whysemaforo__info
            )}
          >
            Consulta l'intero report per una valutazione completa
          </div>
        </div>
      </div>

      {/* TEXT */}
      <div className={styles.ninth_page__text}>
        <div
          className={stylesMain.page__text}
          style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}
        >
          {finalEvaluation}
        </div>
      </div>
    </div>
  );
};

export default NinthPg;
