import styles from "./EightPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";

const EightPg = () => (
  <div className={styles.page__container}>
    {/* PAGE TITLE */}
    <div className={stylesMain.page__section_title}>Informazioni tecniche</div>

    {/* SECTION */}
    <div className={styles.subsection}>
      <p className={stylesMain.page__section_title}>
        Plus - La nostra brillante intelligenza artificiale
      </p>
      <p className={stylesMain.page__text}>
        La precisa stima della valutazione immobiliare riportata nel presente
        documento è stata ottenuta tramite Plus, il potente algoritmo
        proprietario di Houseplus Innovation S.r.l. basato su una sofisticata
        intelligenza artificiale (IA) con capacità predittive. Plus combina
        metodologie all’avanguardia che rappresentano lo stato dell’arte in
        ambito IA, quali tecniche di regressione, correlazione, interpolazione,
        riduzione dimensionale manifold, solo per citarne alcune.
      </p>
    </div>

    {/* SECTION */}
    <div className={styles.subsection}>
      <p className={stylesMain.page__section_title}>Dati</p>
      <p className={stylesMain.page__text}>
        Plus elabora i dati disponibili relativi all’immobile – quali le
        caratteristiche intrinseche dello stesso, la sua posizione geografica,
        la presenza di servizi nei paraggi, l’andamento del mercato immobiliare
        in loco, le informazioni relative alla popolazione residente in zona,
        ecc. – per fornire all’interessato un’accurata stima del valore
        dell’immobile al momento della redazione del report.
        <br />
        <br />
        Per effettuare i suoi ragionamenti, Plus dispone di dati aggiornati
        provenienti da diverse fonti che integra con logiche e metodologie
        innovative proprie del dominio della data science. In primis, Plus ha
        accesso a un voluminoso database contenente dati storici del mercato
        immobiliare italiano, con una finissima granularità geografica. Questo
        database si compone sia di dati open, provenienti dall’Agenzia delle
        Entrate, che proprietari. Un’ulteriore fonte di dato è costituita da
        Istat, che fornisce libero accesso ad utili informazioni sulla zona di
        tipo demografico ed economico. In aggiunta, l’algoritmo fa affidamento
        su dati di terze parti (a cui vi accede tramite API), fondamentali per
        individuare i servizi e le attività nei dintorni dell’immobile. Tutte
        queste informazioni vengono combinate tra di loro, migliorate per
        eliminare gli errori che le affliggono, ricostruite dove non disponibili
        usando approcci statistici avanzati, integrate con la nostra conoscenza
        approfondita del settore immobiliare frutto di una lunga tradizione
        familiare. Infine, i dati pre-elaborati vengono dati in pasto a Plus, il
        quale sfrutta le tecniche di machine learning più all’avanguardia per
        fornire una stima robusta e reale del valore di mercato dell’immbobile
        selezionato dall’utente.
      </p>
    </div>

    {/* SECTION */}
    <div className={styles.subsection}>
      <p className={stylesMain.page__section_title}>Avvertenze</p>
      <p className={stylesMain.page__text}>
        Il risultato ottenuto deve considerarsi come una stima basata sulle
        informazioni riportate in annuncio e la loro correttezza di queste
        impatterà sull’esito della stima. Il risultato ottenuto inoltre non deve
        considerarsi come una perizia e dunque il Report non ha alcun valore
        legale.
      </p>
    </div>
  </div>
);

export default EightPg;
