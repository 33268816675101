import actionCreator from "../../../utils/actionCreator";

const SignInActionTypes = [
  "SIGN_UP",
  "SIGN_IN",
  "FETCH_USER",
  "LOG_OUT",
  "FORGOT_PASSWORD",
  "RESET_PASSWORD",
  "CHANGE_PASSWORD",
  "DELETE_ACCOUNT",
  "EDIT_ACCOUNT",
  "SIGN_IN_GOOGLE",
  "SIGN_IN_FACEBOOK",
  "SIGN_UP_GOOGLE",
  "SIGN_UP_FACEBOOK",
];

export const { types, actions } = actionCreator(SignInActionTypes);
