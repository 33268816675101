import clx from "classnames";
import "./HorizontalBar.scss";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";

const Index = ({
  title,
  description,
  label,
  marginLeft,
  barMarginTop,
  barMarginBottom,
  barMarginRight,
  chartPadding,
  chartData,
  wrapperHeight,
  minHeight,
  barSize,
  isLegend = true,
  isDouble = true,
  marginTop,
}) => (
  <div
    className={chartStyles.chart__wrapper}
    style={{
      minHeight: wrapperHeight,
      height: wrapperHeight,
      padding: chartPadding || "15px 30px 20px",
    }}
  >
    <b style={{ fontSize: "15px" }}>{title}</b>
    <div
      className={clx(chartStyles.chart__container, "horizontal")}
      style={{ marginTop: marginTop || "-10px" }}
    >
      <ResponsiveContainer
        minHeight={minHeight || 450}
        minWidth={380}
        height="80%"
      >
        <BarChart
          data={chartData}
          margin={{
            top: barMarginTop,
            bottom: barMarginBottom,
            left: marginLeft,
            right: barMarginRight || 30,
          }}
          layout={"vertical"}
          height={minHeight}
        >
          <XAxis
            type="number"
            label={<ChartsLabel label={label} dy={440} dx={95} />}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
          />
          <YAxis
            type="category"
            dataKey={chartData && Object.keys(chartData[0])[0]}
            tick={<ChartsAxisLabel dy={3.5} textAnchor={"end"} />}
          />
          <Tooltip
            cursor={{ fill: "#BFEEF3" }}
            wrapperStyle={{ outline: "none" }}
            content={<ChartsTooltip />}
          />
          {isLegend && (
            <Legend
              verticalAlign="top"
              align="right"
              height={20}
              width={"fit-content"}
            />
          )}
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[1]}
            fill={"#00B1C5"}
            radius={[0, 5, 5, 0]}
            barSize={barSize}
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[3]}
              position="right"
              fill="#9D9D9D"
            />
          </Bar>
          {isDouble && (
            <Bar
              isAnimationActive={false}
              dataKey={chartData && Object.keys(chartData[0])[2]}
              fill={"#11606D"}
              radius={[0, 5, 5, 0]}
            >
              <LabelList
                dataKey={chartData && Object.keys(chartData[0])[4]}
                position="right"
                fill="#9D9D9D"
              />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
    <p
      className={chartStyles.chart__source}
      style={{ position: "absolute", top: "6px", right: "39px" }}
    >
      {description}
    </p>
  </div>
);
export default Index;
