const initialState = {
  direction: "",
  typeModal: {
    type: null,
    isBuy: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_DIRECTION":
      return {
        ...state,
        direction: action.payload,
      };
    case "SET_TYPE_MODAL":
      return {
        ...state,
        typeModal: {
          ...state.typeModal,
          ...action.payload,
        },
      };
    default:
      return { ...state };
  }
};
