import React from "react";
import styles from "./TownInfo.module.scss";

import InfoCards from "./InfoCards";
// import CompaniesBanner from "./CompaniesBanner";

const TownInfo = ({ townInfoRef, hasMinimumInfo }) => {
  return (
    <section className={styles.town__info} ref={townInfoRef}>
      <InfoCards hasMinimumInfo={hasMinimumInfo} />
      {/* <CompaniesBanner /> */}
      {/* <div className={styles.town__info_gradient} /> */}
    </section>
  );
};

export default TownInfo;
