import { Navigate, useLocation } from "react-router-dom";
import Helmet from "../../shared/Helmet/Helmet";

export const PublicRoute = ({
  component: RouteComponent,
  isAuthenticated,
  restricted = false,
  title,
  route,
}) => {
  let location = useLocation();

  if (isAuthenticated && restricted) {
    return <Navigate to={"/"} state={{ from: location }} />;
  }

  return (
    <>
      {title && <Helmet title={title} route={route} />}
      <RouteComponent />
    </>
  );
};
