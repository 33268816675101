// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../DashboardSwiper.scss";

import CompleteCard from "./CompleteCard";
import formatToEuro from "../../../utils/formatToEuro";

const CompleteSlider = ({ userReports, setDeleteModal }) => {
  // console.log(userReports, "userReports");
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      breakpoints={{
        // when window width is >= 740px
        430: {
          slidesPerView: "auto",
        },
      }}
    >
      {userReports?.map((report, index) => {
        let parsedPhoto = [];
        try {
          parsedPhoto = JSON.parse(report?.url_foto);
        } catch (error) {
          // console.log(error);
        }
        // console.log(parsedPhoto, "parsedPhoto");

        return (
          <SwiperSlide key={`report-card-${index}`}>
            <CompleteCard
              id={report?.report_id}
              url_foto={parsedPhoto?.[0]}
              url_annuncio={report?.url_annuncio}
              address={
                report?.address || "Indirizzo non presente nell'annuncio"
              }
              valuation={`€ ${formatToEuro(String(report?.valuation) || 0)}`}
              semaforo={report?.semaforo}
              setDeleteModal={setDeleteModal}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CompleteSlider;
