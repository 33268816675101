import actionCreator from "../../../utils/actionCreator";

const AccountActionTypes = [
  "GET_TRANSACTIONS",
  "CHECK_USER_CREDITS",
  "BUY_CREDITS",
  "GET_REPORTS",
  "GET_REPORT",
  "GET_REPORT_PDF",
  "CREATE_REPORT",
  "DELETE_REPORT",
  "DOWNLOAD_FROM_DASHBOARD",
  "GET_TEASER",
  "CONTACT_AGENCY",
  "CONTACT_USER",
  "GET_REVIEWS",
];

export const { types, actions } = actionCreator(AccountActionTypes);
