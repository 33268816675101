import React from "react";
import styles from "./Hero.module.scss";
import HeroText from "./HeroText";
import HeroImage from "./HeroImage";
import HeroStats from "./HeroStats";
import { ReactComponent as BackIcon } from "../../../assets/svgs/back-simple.svg";
import clx from "classnames";

const Hero = ({ hasMinimumInfo, isFromBakeca }) => {
  const adv_url = JSON.parse(
    localStorage.getItem("propertyData") || "{}"
  )?.url_annuncio;
  // console.log(adv_url, "adv_url");

  return (
    <>
      <header
        className={clx(styles.hero, {
          [styles.isNotFromBakeca]: !isFromBakeca || !hasMinimumInfo,
        })}
      >
        {/* Back to adv button */}
        {adv_url && (
          <>
            <a href={adv_url} target="_blank" className={styles.hero__url_btn}>
              <BackIcon /> Torna sul tuo annuncio
            </a>
          </>
        )}
        {/* Hero content */}
        <div className={styles.hero_content}>
          <HeroText
            hasMinimumInfo={hasMinimumInfo}
            isFromBakeca={isFromBakeca}
          />
          <HeroImage />
        </div>

        {/* Hero banner */}
        {/* <HeroBanner /> */}
      </header>

      <section>
        {/* Hero Stats */}
        <HeroStats />
      </section>
    </>
  );
};

export default Hero;
