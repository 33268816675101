import styles from "./CircleProgressBar.module.scss";
import { Label } from "./containers/Label";

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage, radius, stroke }) => {
  const circ = 2 * Math.PI * radius;
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <circle
      r={radius}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth={stroke || "2rem"}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  );
};

const CircleProgressBar = ({
  percentage,
  colour,
  disableColour,
  icon,
  label = false,
  radius,
  stroke,
  width,
  centreSvg,
  labelColor,
  labelColorText,
}) => {
  const pct = cleanPercentage(percentage);
  const r = radius || 70;

  return (
    <div className={styles.circle__bar__wrapper}>
      <svg width={width || 250} height={width || 250}>
        <g transform={`rotate(-90 ${`${centreSvg || "125"} 100`})`}>
          <Circle radius={r} colour={disableColour} stroke={stroke} />
          <Circle radius={r} colour={colour} percentage={pct} stroke={stroke} />
        </g>
      </svg>
      {label && (
        <div className={styles.circle__bar__labels}>
          <Label
            radius={r}
            label={label[0]}
            value={100 - pct}
            disabled
            labelColor={labelColor}
            labelColorText={labelColorText}
          />
          <Label
            radius={r}
            label={label[1]}
            value={pct}
            labelColor={labelColor}
            labelColorText={labelColorText}
          />
        </div>
      )}
      {!label && (
        <div className={styles.icon__wrapper}>
          <img src={icon} alt="icon" />
        </div>
      )}
    </div>
  );
};

export default CircleProgressBar;
