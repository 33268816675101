import clx from "classnames";

import styles from "./SecondaryButton.module.scss";

const SecondaryButton = ({
  background,
  color,
  height,
  width,
  minWidth,
  fontSize,
  padding,
  margin,
  marginTop,
  marginBottom,
  alignItems,
  borderRadius,
  border,
  text,
  gap,
  leftIconSrc: LeftIconSrc,
  rightIconSrc: RightIconSrc,
  isReactIcon,
  viewBox,
  onClick,
  className,
  iconWidth,
  iconHeight,
  borderColor,
  disabled,
}) => (
  <button
    style={{
      height,
      width,
      minWidth,
      fontSize,
      borderRadius,
      border,
      padding,
      margin,
      marginTop,
      marginBottom,
      borderColor,
      color,
      background,
      alignItems,
      gap,
    }}
    className={clx(styles.secondary__btn, className)}
    onClick={onClick}
    disabled={disabled}
  >
    {LeftIconSrc &&
      (!isReactIcon ? (
        <img
          className={styles["left-icon"]}
          style={{ width: iconWidth, height: iconHeight }}
          src={LeftIconSrc}
          alt="icon"
        />
      ) : (
        <LeftIconSrc viewBox={viewBox} />
      ))}
    {text && <span style={{ color, fontSize }}>{text}</span>}
    {RightIconSrc &&
      (!isReactIcon ? (
        <img
          className={styles["right-icon"]}
          src={RightIconSrc}
          alt="icon"
          style={{ width: iconWidth, height: iconHeight }}
        />
      ) : (
        <RightIconSrc viewBox={viewBox} />
      ))}
  </button>
);

export default SecondaryButton;
