import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import styles from "./HeroStats.module.scss";
import { getDirection } from "../../store/selectors";

const HeroStats = () => {
  const isScrollingDown = useSelector(getDirection());

  return (
    <motion.div
      className={styles.hero_stats}
      initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, ease: "easeOut" },
      }}
      viewport={{ margin: "-50px" }}
    >
      <div className={styles.hero_stats_stat}>
        <h3>+480.000</h3>
        <p>Immobili analizzati</p>
      </div>
      <div className={styles.hero_stats_stat}>
        <h3>+160</h3>
        <p>Clienti B2B</p>
      </div>
      <div className={styles.hero_stats_stat}>
        <h3>+87%</h3>
        <p>Accuratezza garantita</p>
      </div>
    </motion.div>
  );
};

export default HeroStats;
