import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Facebook from "../../../../assets/svgs/facebook.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./FacebookBtn.module.scss";
import { getTypeModal } from "../../store/selectors";

const FacebookBtn = ({ className, classNameTwo, callback, setSuccessStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeModal = useSelector(getTypeModal());

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      callback={(response) =>
        callback(
          dispatch,
          response,
          "_FACEBOOK",
          "",
          setSuccessStep,
          navigate,
          typeModal
        )
      }
      render={(renderProps) => (
        <div className={className} onClick={renderProps.onClick}>
          <img src={Facebook} alt="facebook" className={classNameTwo} />{" "}
          <span className={styles.span}>Facebook</span>
        </div>
      )}
    />
  );
};

export default FacebookBtn;
