import { useEffect, useRef } from "react";
import styles from "./DownloadPdf.module.scss";
import PageHOC from "./containers/PageHOC";
import FirstPg from "./containers/FirstPg";
import SecondPg from "./containers/SecondPg";
import SecondPgAdditional from "./containers/SecondPgAdditional";
import ThirdPg from "./containers/ThirdPg";
// import FourthPg from "./containers/FourthPg";
import FifthPg from "./containers/FifthPg";
import SixthPg from "./containers/SixthPg";
import SeventhPg from "./containers/SeventhPg";
import EightPg from "./containers/EightPg";
import NinthPg from "./containers/NinthPg";
import buildAddressString from "../../utils/buildAddress";
import propertyMapping from "../../utils/propertyMapping";
import formatToEuro from "../../utils/formatToEuro";

const Download = () => {
  const report = JSON.parse(localStorage.getItem("puppeteerData") || "{}");

  // FIRST PAGE HOUSE ADDRESS
  const houseAddress = buildAddressString(report?.address_info);

  // SUMMARY INFO
  const {
    lat,
    lng,
    comune,
    descrizione,
    id,
    ipe,
    provincia,
    quartiere,
    regione,
    titolo,
    trattativariservata,
    unita_misura_ipe,
    url_annuncio,
    url_foto,
    ...summary
  } = report?.request_params ?? {};

  const renamedSummary = Object.keys(summary)
    // .filter((key) => summary[key] !== null)
    .reduce((acc, curr) => {
      switch (curr) {
        case "arredato":
          acc["È arredato"] = summary[curr] || "Non disponibile";
          break;
        case "clenergetica":
          acc["Classe energetica"] = summary[curr] || "Non disponibile";
          break;
        case "data_annuncio":
          acc["Data annuncio"] =
            new Date(summary[curr]).toISOString().slice(0, 10) ||
            "Non disponibile";
          break;
        case "mquadri":
          acc["Superficie"] = summary[curr] ? (
            <span>
              {summary[curr]} m<sup>2</sup>
            </span>
          ) : (
            "Non disponibile"
          );
          break;
        case "prezzo":
          acc["Prezzo"] = summary[curr]
            ? `€ ${formatToEuro(summary[curr])}`
            : "Non disponibile";
          break;
        case "tipoimmobile":
          acc["Tipo di immobile"] = summary[curr]
            ? propertyMapping(summary[curr])
            : "Non disponibile";
          break;
        case "tipoinserzionista":
          acc["Inserzionista"] = summary[curr] || "Non disponibile";
          break;
        default:
          acc[curr] = summary[curr];
      }
      return acc;
    }, {});

  // LAT LNG
  const latLng = [report?.request_params?.lat, report?.request_params?.lng];

  // TOTAL SURFACE
  const totalSurface = report?.request_params?.mquadri;

  // ADD PAGE NUMBER DYNAMICALLY TO ALL COMPONENTS THAT ARENT HIDDEN
  const componentRefs = useRef([]);
  useEffect(() => {
    const visibleRefs = componentRefs.current.filter(
      (ref) => ref && !ref.classList.contains(styles.hide)
    );
    visibleRefs?.forEach((ref, index) => {
      // console.log(ref, 'REF');
      if (ref) ref.querySelector(".footer__number").innerText = index + 2;
    });
  });

  return (
    report && (
      <div className={styles.book}>
        {/* FIRST PAGE */}
        <div className={styles.page} style={{ height: "296.80mm" }}>
          <FirstPg
            houseAddress={houseAddress}
            creationDate={report?.creation_date}
          />
        </div>

        {/* SECOND PAGE - SUMMARY */}
        <PageHOC ref={(ref) => (componentRefs.current[0] = ref)}>
          <SecondPg
            semaforo={report?.propensity_score}
            semaforo_GPT={report?.gpt_evaluation}
            adv_price={report?.request_params?.prezzo}
            adv_price_mq={report?.model_omi_info?.price_annuncio_mq}
            min_price={report?.model_omi_info?.range_min}
            max_price={report?.model_omi_info?.range_max}
            prediction={report?.model_omi_info?.prediction}
            min_price_mq={report?.model_omi_info?.min_price}
            max_price_mq={report?.model_omi_info?.max_price}
            prediction_mq={report?.model_omi_info?.price_mq}
          />
        </PageHOC>

        {/* SECOND PAGE ADDITIONAL */}
        <PageHOC ref={(ref) => (componentRefs.current[1] = ref)}>
          <SecondPgAdditional
            summary_info={renamedSummary}
            photos={report?.request_params?.url_foto}
            coords={latLng}
            description={report?.request_params?.descrizione}
          />
        </PageHOC>

        {/* THIRD PAGE - AREA SERVICES */}
        <PageHOC
          ref={(ref) => (componentRefs.current[2] = ref)}
          height={"296.80mm"}
        >
          <ThirdPg
            services={report?.local_services}
            services_GPT={report?.gpt_description}
            coords={latLng}
          />
        </PageHOC>

        {/* FIFTH PAGE - MARKET */}
        <PageHOC
          ref={(ref) => (componentRefs.current[3] = ref)}
          height={"296.80mm"}
        >
          <FifthPg
            omi={report?.model_omi_info}
            city={report?.address_info?.city}
            type={Object.keys(report?.area_stats?.tipology || {})?.[0]}
            total_surface={totalSurface}
            adv_price={report?.request_params?.prezzo}
            similar_properties={report?.similar_properties}
            similar_properties_GPT={report?.similar_properties_evaluation}
            energy_chart={report?.area_stats?.energy_rating}
            energy_chart_GPT={report?.area_stats?.energy_class_description}
          />
        </PageHOC>

        {/* SIXTH PAGE - SOCIO CHARTS PART 1 */}
        <PageHOC
          ref={(ref) => (componentRefs.current[4] = ref)}
          height={"296.80mm"}
        >
          <SixthPg
            population_change={report?.charts?.info?.graphs?.[2]}
            family={report?.charts?.info?.graphs?.[9]}
            avg_price={report?.area_stats?.median_price_mq}
            ads_amount={report?.area_stats?.num_properties}
            population_density={report?.charts?.info?.graphs?.[7]}
            avg_earnings={report?.charts?.info?.graphs?.[10]}
            gender_distribution={report?.charts?.info?.graphs?.[3]}
          />
        </PageHOC>

        {/* SEVENTH PAGE - SOCIO CHARTS PART 2 */}
        <PageHOC
          ref={(ref) => (componentRefs.current[5] = ref)}
          height={"296.80mm"}
        >
          <SeventhPg
            education={report?.charts?.info?.graphs?.[8]}
            gender_status={report?.charts?.info?.graphs?.[4]}
            population_pyramid={report?.charts?.info?.graphs?.[1]}
          />
        </PageHOC>

        {/* NINTH PAGE - FINAL VALUATION*/}
        <PageHOC
          ref={(ref) => (componentRefs.current[6] = ref)}
          height={"296.80mm"}
        >
          <NinthPg
            semaforo={report?.propensity_score}
            finalEvaluation={report?.final_evaluation}
            adv_price={report?.request_params?.prezzo}
          />
        </PageHOC>

        {/* SEVENTEENTH PAGE - TECH */}
        <PageHOC
          ref={(ref) => (componentRefs.current[7] = ref)}
          height={"296.80mm"}
        >
          <EightPg />
        </PageHOC>
      </div>
    )
  );
};

export default Download;
