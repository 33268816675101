import { types } from "./actions";

const initialState = {
  error: null,
  user: {
    isAdmin: null, // explicitly set isAdmin to null initially
  },
  token: null,
  isLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SIGN_IN.REQUEST:
    case types.SIGN_IN_GOOGLE.REQUEST:
    case types.SIGN_IN_FACEBOOK.REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.SIGN_IN.REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.SIGN_IN.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SIGN_IN.SUCCESS:
      return {
        ...state,
        token: action.payload,
        error: null,
        loading: false,
        isLogin: true,
      };
    case types.SIGN_UP_GOOGLE.SUCCESS:
      return {
        ...state,
        token: action.payload,
        error: null,
        loading: false,
        isLogin: true,
      };
    case types.FETCH_USER.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload, // This will override isAdmin with the fetched value
        },
        loading: false,
        error: null,
      };
    case types.LOG_OUT.SUCCESS:
      return {
        ...state,
        error: null,
        isLogin: false,
        user: {},
      };
    default:
      return { ...state };
  }
};
