import React from "react";
import clx from "classnames";
import styles from "./MobileSlider.module.scss";
import { motion } from "framer-motion";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./MobileSwiper.scss";

const MobileSlider = ({ reasonsWhy }) => {
  return (
    <motion.div
      className={clx(styles.mobile__slider, "mobile__slider")}
      initial={{ opacity: 0, x: 50 }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: { duration: 0.5, ease: "easeOut" },
      }}
      viewport={{ margin: "-50px 0px -50px 0px" }}
    >
      {/* Swiper Slider */}
      <Swiper
        spaceBetween={80}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {reasonsWhy.map((reason, index, array) => (
          <SwiperSlide key={`mobile-slider-${index}`}>
            <div className={styles.mobile__slider_card}>
              <div className={styles.mobile__slider_card_text}>
                <h2 className={styles.mobile__slider_card_text_header}>
                  {reason.title}
                </h2>
                <p className={styles.mobile__slider_card_text_p}>
                  {reason.text}
                </p>
              </div>
              <img
                src={reason.img}
                alt="card-img"
                // style={{ height: index === array.length - 1 && "auto" }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  );
};

export default MobileSlider;
