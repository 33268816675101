import styles from "./DataPreview.module.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTypeModal } from "../../store/actions";

import CloseBtn from "../CloseBtn";
// import GraphicImg from "../../../../assets/svgs/qualita-dati.svg";
import GraphicImg from "../../../../assets/svgs/apartment-rent-bro.svg";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import alertToast from "../../../AlertToast";
import Check from "../../../../assets/svgs/check-green.svg";

import { getAuth } from "../../../Auth/store/selectors";

const DataPreview = ({ hasMinimumInfo }) => {
  const dispatch = useDispatch();
  const isUserLoggedin = useSelector(getAuth());

  return (
    <div className={styles.data_preview}>
      <CloseBtn className={styles.close} />
      <div className={styles.data_preview__left}>
        <h4 className={styles.data_preview__left__header}>
          Ecco tutti i dati che troverai nella tua analisi:
        </h4>
        <ul className={styles.data_preview__left__list}>
          <li>
            <img src={Check} alt="check" />
            Stima reale dell'immobile
          </li>
          <li>
            <img src={Check} alt="check" /> Valutazione del prezzo in annuncio
          </li>
          <li>
            <img src={Check} alt="check" /> Analisi dei servizi di zona
          </li>
          <li>
            <img src={Check} alt="check" /> Analisi del mercato immobiliare
            (Dati OMI, immobili comparabili, numero di immobili in vendita)
          </li>
          <li>
            <img src={Check} alt="check" /> Analisi socio-demografica della zona
          </li>
          <li>
            <img src={Check} alt="check" /> Valutazione omnicomprensiva
            dell'immobile
          </li>
        </ul>

        <div className={styles.data_preview__left__btn}>
          <PrimaryButton
            text={"Acquista la tua analisi"}
            width={"100%"}
            height={60}
            onClick={() => {
              if (hasMinimumInfo) {
                if (isUserLoggedin) {
                  dispatch(setTypeModal({ type: "buy", isBuy: true }));
                } else {
                  dispatch(setTypeModal({ type: "signup", isBuy: true }));
                }
              } else {
                alertToast(
                  "warning",
                  "Dati insufficienti",
                  "L'immobile selezionato non ha abbastanza informazioni per generare un report."
                );
              }
            }}
            disabled={!hasMinimumInfo}
          />
        </div>

        <div className={styles.data_preview__left__img}>
          <img src={GraphicImg} alt="modal-home-img" />
        </div>
      </div>
      <div className={styles.data_preview__right}>
        <img
          className={styles.data_preview__right__img}
          src={GraphicImg}
          alt="modal-home-img"
        />
        <div className={styles.data_preview__right__btn}>
          <PrimaryButton
            text={"Acquista la tua analisi"}
            width={280}
            height={60}
            onClick={() => {
              if (hasMinimumInfo) {
                if (isUserLoggedin) {
                  dispatch(setTypeModal({ type: "buy", isBuy: true }));
                } else {
                  dispatch(setTypeModal({ type: "signup", isBuy: true }));
                }
              } else {
                alertToast(
                  "warning",
                  "Dati insufficienti",
                  "L'immobile selezionato non ha abbastanza informazioni per generare un report."
                );
              }
            }}
            disabled={!hasMinimumInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default DataPreview;
