import clx from "classnames";
import { useEffect, useState } from "react";

import { ReactComponent as EyeIcon } from "../../../assets/svgs/eye.svg";
import { ReactComponent as EyeCrossIcon } from "../../../assets/svgs/eye-cross.svg";
import Exclamation from "../../../assets/svgs/Exclamation.svg";
import styles from "./TextField.module.scss";

const TextField = ({
  field,
  form: { touched, errors, values },
  className,
  label,
  labelInfo,
  type = "text",
  readonly,
  textarea = false,
  height,
  showPassword = false,
  forgotPassword = false,
  iconComponent,
  errorFlexDirection,
  labelStyle = {},
  labelFieldStyle = {},
  inputGroupStyle = {},
  inputStyle = {},
  width,
  value,
  callback,
  disabled,
  gridColumn,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const isError = touched[field.name] && errors[field.name];
  // console.log("isError TEXT FIELD", isError);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={clx(styles.field__wrapper, className)}
      style={{ width, gridColumn }}
    >
      {label && (
        <div className={styles.label} style={{ ...labelStyle }}>
          <label
            className={styles["field__label"]}
            htmlFor={field.name}
            style={{ ...labelFieldStyle }}
          >
            {label}
          </label>
          {labelInfo != "" && <p className={styles.label_info}>{labelInfo}</p>}
          {forgotPassword && (
            <span
              className={styles.forgot_password}
              onClick={callback}
              tabIndex="0"
              onKeyPress={(e) => e.key === "Enter" && callback}
            >
              Hai dimenticato la password?
            </span>
          )}
        </div>
      )}

      <div
        className={clx(styles["input-group"], {
          [styles["error-field"]]: isError,
        })}
        style={{
          ...inputGroupStyle,
          height,
        }}
      >
        <input
          className={styles["field__input"]}
          type={inputType}
          disabled={disabled}
          {...field}
          {...props}
          style={{ ...inputStyle }}
        ></input>
        {showPassword &&
          (inputType === "password" ? (
            <EyeIcon
              className={styles.pwd_icon}
              onClick={() => {
                if (inputType === "password") {
                  setInputType("text");
                } else {
                  setInputType("password");
                }
              }}
            />
          ) : (
            <EyeCrossIcon
              className={styles.pwd_icon}
              onClick={() => {
                if (inputType === "password") {
                  setInputType("text");
                } else {
                  setInputType("password");
                }
              }}
            />
          ))}
        {iconComponent}
      </div>

      {isError && (
        <div
          className={styles.error}
          style={{ flexDirection: errorFlexDirection }}
        >
          <img src={Exclamation} alt="error" /> {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default TextField;
