import styles from "./FormButton.module.scss";
import clx from "classnames";
import Check from "../../../../assets/svgs/check.svg";

import ButtonSpinner from "./ButtonSpinner";

const FormButton = ({
  callback,
  successState,
  title,
  type,
  disabled,
  loading,
  width,
  height,
}) => {
  return (
    <button
      className={clx(styles.form_button, {
        [styles.success]: successState,
      })}
      onClick={callback}
      type={type}
      disabled={disabled}
      style={{ width, height }}
    >
      <div className={styles.form_button__spans}>
        <span>
          {title} {loading == true ? <ButtonSpinner /> : ""}
        </span>
        <span>
          <img src={Check} alt="success-check" />
        </span>
      </div>
    </button>
  );
};

export default FormButton;
