import { useScroll, useMotionValueEvent } from "framer-motion";
import { useDispatch } from "react-redux";
import { setIsScrollingDown } from "../containers/Landing/store/actions";

const useMotionScrollDirection = () => {
  const { scrollY } = useScroll();
  const dispatch = useDispatch();

  useMotionValueEvent(scrollY, "change", (latest) => {
    // console.log(scrollY.getPrevious() - latest < 0, "SCROLLING DOWN");
    dispatch(setIsScrollingDown(scrollY.getPrevious() - latest < 0));
  });
};

export default useMotionScrollDirection;
