import clx from "classnames";
import styles from "./SeventhPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import GenderStatus from "./GenderStatus";
import PopulationPyramid from "./PopulationPyramid";
import Education from "./Education";

const SeventhPg = ({ education, gender_status, population_pyramid }) => {
  return (
    <div className={styles.seventh_page}>
      {/* PAGE TITLE */}
      {/* <h3 className={stylesMain.page__title}>Mercato immobiliare</h3> */}

      {/* CHARTS */}
      <div className={styles.seventh_page__charts_grid}>
        {/* GENDER STATUS - DOUBLE BAR CHART */}
        {gender_status?.graph_status == "OK" && (
          <div
            className={clx(styles.gender__status, styles.general__card)}
            style={{ position: "relative" }}
          >
            <GenderStatus
              data={gender_status?.graph_data}
              minHeight={300}
              wrapperHeight={200}
              chartHeight={320}
            />
          </div>
        )}

        {/* POPULATION PYRAMID - DOUBLE BAR CHART */}
        {population_pyramid?.graph_status == "OK" && (
          <div
            className={clx(styles.pop__pyramid, styles.general__card)}
            style={{ position: "relative" }}
          >
            <PopulationPyramid
              data={population_pyramid?.graph_data}
              minHeight={280}
              wrapperHeight={300}
              chartWidth={"56%"}
              tickX={-5.5}
            />
          </div>
        )}

        {/* EDUCATION - DOUBLE BAR CHART */}
        {education?.graph_status == "OK" && (
          <div className={clx(styles.education, styles.general__card)}>
            <Education
              data={education?.graph_data}
              minHeight={310}
              wrapperHeight={350}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SeventhPg;
