import clx from "classnames";
import LeftThumb from "../../../../../assets/images/left-thumb.png";
import OmiHome from "../../../../../assets/images/immobile.png";
import RightThumb from "../../../../../assets/images/right-thumb.png";
import SliderRail from "../../../../../assets/images/slider-rail.png";
import SliderTrack from "../../../../../assets/images/slider-track.png";
import formatToEuro from "../../../../../utils/formatToEuro";
import styles from "./OmiGraphic.module.scss";
import stylesMain from "../../../DownloadPdf.module.scss";

const OmiGraphic = ({ omi, city, type, pricePerMq2 }) => {
  // Buy prices
  const max = Math.round(omi?.omi_max);
  const min = Math.round(omi?.omi_min);
  const priceAvg = Math.round((+min + +max) / 2);
  // console.log(pricePerMq2, "pricePerMq2");

  // Calculate the x position of the home icon based on the value per square meter compared to min and max omi values
  const xPosition = ((pricePerMq2 - min) / (max - min)) * 100;
  // console.log(xPosition, "xPosition");

  // Home icon track styles, remove 18px to center the icon also hide it if its above 113% and out of the slider
  const homeIconTrackLeft = `calc(${xPosition}% - 18px)`;
  const homeIconTrackDisplay = (xPosition > 113 || xPosition < -13) && "none";

  // Home icon rail styles, visible if xPosition above 113% and positioned to the extremes of the rail
  const homeIconRailLeft = xPosition < -13 && 0;
  const homeIconRailRight = xPosition > 113 && 0;
  const homeIconRailDisplay = xPosition <= 113 && xPosition >= -13 && "none";

  return (
    /* OMI GRAPHIC */
    <div className={styles.omi__data}>
      <div className={stylesMain.page__section_title}>Mercato immobiliare</div>
      <div className={styles.omi__data__main}>
        <div className={styles.left__side}>
          {city && (
            <div className={styles.item}>
              <p className={styles.title}>Comune</p>
              <p className={styles.text}>{city}</p>
            </div>
          )}
          <div className={styles.item}>
            <p className={styles.title}>Zona OMI</p>
            {omi?.zona_omi != null ? (
              <p className={styles.text}>{omi?.zona_omi}</p>
            ) : (
              <p className={styles.text}>Dati omi non disponibili</p>
            )}
          </div>
          {type && (
            <div className={styles.item}>
              <p className={styles.title}>Tipologia Immobile</p>
              <p className={styles.text}>{type}</p>
            </div>
          )}
        </div>

        <div className={styles.right__side}>
          {/* HEADER */}
          <p className={styles.header_text}>
            Prezzi per m<sup className={styles.pdf_sup}>2</sup>
          </p>
          {/* PRICES CONTAINER */}
          <div className={styles.prices__container}>
            {/* MINIMUM */}
            <div className={styles.price__container}>
              <p className={styles.subheader_text}>Minimo</p>
              <div>
                {/* <p className={styles.text}>Vendita</p> */}
                {!isNaN(formatToEuro(min)) ? (
                  <p className={styles.price}>
                    <span className={styles.euro}>&euro;</span>{" "}
                    {formatToEuro(min || 0)}
                  </p>
                ) : (
                  <p className={styles.price} style={{ fontSize: 14 }}>
                    Dato non <br /> disponibile
                  </p>
                )}
              </div>
            </div>

            {/* AVERAGE */}
            <div
              className={clx(styles.price__container, styles.avg__container)}
            >
              <p className={styles.subheader_text}>Medio</p>
              <div>
                {/* <p className={styles.text}>Vendita</p> */}
                {!isNaN(formatToEuro(priceAvg)) ? (
                  <p className={styles.price}>
                    <span className={styles.euro}>&euro;</span>{" "}
                    {formatToEuro(priceAvg || 0)}
                  </p>
                ) : (
                  <p className={styles.price} style={{ fontSize: 14 }}>
                    Dato non <br /> disponibile
                  </p>
                )}
              </div>
            </div>

            {/* MAXIMUM */}
            <div className={styles.price__container}>
              <p className={styles.subheader_text}>Massimo</p>
              <div>
                {/* <p className={styles.text}>Vendita</p> */}
                {!isNaN(formatToEuro(max)) ? (
                  <p className={styles.price}>
                    <span className={styles.euro}>&euro;</span>{" "}
                    {formatToEuro(max || 0)}
                  </p>
                ) : (
                  <p className={styles.price} style={{ fontSize: 14 }}>
                    Dato non <br /> disponibile
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* SLIDER */}
          <div className={`${styles.photo__wrapper} slider`}>
            <img src={SliderRail} alt="slider" className={styles.slider_rail} />
            <div className={styles.slider_track}>
              <img
                src={SliderTrack}
                alt="slider"
                className={styles.slider_track_track}
              />
              <img
                src={LeftThumb}
                alt="slider"
                className={styles.slider_left_thumb}
              />
              <img
                src={RightThumb}
                alt="slider"
                className={styles.slider_right_thumb}
              />
              <img
                src={OmiHome}
                alt="slider"
                className={styles.slider_home}
                style={{
                  left: homeIconTrackLeft,
                  display: homeIconTrackDisplay,
                }}
              />
            </div>
            <img
              src={OmiHome}
              alt="slider"
              className={styles.slider_home_rail}
              style={{
                right: homeIconRailRight,
                left: homeIconRailLeft,
                display: homeIconRailDisplay,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmiGraphic;
