import "./BarChart.scss";
import clx from "classnames";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";

const Index = ({
  title,
  description,
  label,
  legend,
  dx,
  marginLeft,
  chartData,
  wrapperHeight,
  minHeight,
  minWidth,
  chartMarginTop,
  chartHeight,
  chartPadding,
  barMarginTop,
  barSize,
  labelDx,
  labelDy,
  isLegend = true,
  isDouble = true,
}) => (
  <div
    className={clx(chartStyles.chart__wrapper, "bar")}
    style={{
      minHeight: wrapperHeight,
      height: chartHeight,
      padding: chartPadding || "15px 30px 20px",
    }}
  >
    {title && <b style={{ fontSize: "15px" }}>{title}</b>}
    <div
      className={chartStyles.chart__container}
      style={{ marginTop: chartMarginTop || "-34px" }}
    >
      <ResponsiveContainer
        minHeight={minHeight}
        minWidth={minWidth || 420}
        height={"100%"}
      >
        <BarChart
          data={chartData}
          margin={{ top: barMarginTop || 40, left: marginLeft }}
          // height={"100%"}
          // width={"100%"}
        >
          <XAxis
            dataKey={chartData && Object.keys(chartData[0])[0]}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
          />
          <YAxis
            label={
              <ChartsLabel label={label} dy={labelDy || 60} dx={labelDx || 0} />
            }
            tick={<ChartsAxisLabel dy={3.5} textAnchor={"end"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
          />
          <Tooltip
            cursor={{ fill: "#BFEEF3" }}
            wrapperStyle={{ outline: "none" }}
            content={<ChartsTooltip />}
          />
          {isLegend && (
            <Legend
              verticalAlign="top"
              align="right"
              height={50}
              width={"fit-content"}
            />
          )}
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[1]}
            fill={"#00B1C5"}
            radius={[5, 5, 0, 0]}
            barSize={barSize}
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[3]}
              position="top"
              fill="#9D9D9D"
            />
          </Bar>
          {isDouble && (
            <Bar
              isAnimationActive={false}
              dataKey={chartData && Object.keys(chartData[0])[2]}
              fill={"#11606D"}
              radius={[5, 5, 0, 0]}
            >
              <LabelList
                dataKey={chartData && Object.keys(chartData[0])[4]}
                position="top"
                fill="#9D9D9D"
              />
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
    {description && (
      <p
        className={chartStyles.chart__source}
        style={{ position: "absolute", top: "6px", right: "39px" }}
      >
        {description}
      </p>
    )}
  </div>
);

export default Index;
