import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { Api } from "../../../entries/ApiTransport";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";
import { setError } from "../../../shared/Error/store/actions";

const api = Api.getInstance();

const createReport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { response } = yield call(() => api.post("/report/add", payload));

        yield put(actions.CREATE_REPORT.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CREATE_REPORT.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

function* getTeaser({ payload, callback, failureCallback }) {
  try {
    const { data } = yield call(() =>
      api.post("/report/teaser", { idpubblico_annuncio: payload })
    );
    yield put(actions.GET_TEASER.SUCCESS(data.data));
    callback && typeof callback === "function" && callback(data.data);
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.display ||
          error?.message ||
          null
      )
    );
    yield put(
      actions.GET_TEASER.FAILED(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          null
      )
    );
    failureCallback &&
      typeof failureCallback === "function" &&
      failureCallback();
  }
}

const getReport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        try {
          // console.log(payload, "payload");
          const { data } = yield call(() =>
            api.post("/report/report", payload)
          );
          // console.log(data, "landing data from saga");
          yield put(actions.GET_REPORT.SUCCESS(data.data));
          callback && typeof callback === "function" && callback();
        } catch (error) {
          // Catch any errors and throw a new error with the specific text
          throw new Error("reportDataRetrievalError");
        }
      },
    (err) => actions.GET_REPORT.FAILED(err)
  );

const getTransactions = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get("/transactions/getTransactions")
        );
        yield put(actions.GET_TRANSACTIONS.SUCCESS(data.transactions));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_TRANSACTIONS.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const getUserReports = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/reports"));
        yield put(actions.GET_REPORTS.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_REPORTS.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const downloadFromDashboard = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.get(`/report/download_from_dashboard/${payload}`, {
            responseType: "blob",
          })
        );
        // console.log(data, "data");
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Report_houseplus_" + payload + ".pdf");
        document.body.appendChild(link);
        link.click();

        // Clean up the object URL
        URL.revokeObjectURL(href);

        yield put(actions.DOWNLOAD_FROM_DASHBOARD.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DOWNLOAD_FROM_DASHBOARD.FAILED(err)
  );

const deleteReport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.delete(`/report/${payload}`));
        // yield put(actions.REFRESH_INFORMATION.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DELETE_REPORT.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const contactAgency = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post("/contact_agency", payload));
        yield put(actions.CONTACT_AGENCY.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CONTACT_AGENCY.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const contactUser = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post("/contact_user", payload));
        yield put(actions.CONTACT_USER.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CONTACT_USER.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const getReviews = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => axios.get(`/reviews`));
        yield put(actions.GET_REVIEWS.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.GET_REVIEWS.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

// Watcher saga - It will watch for the below actions to dispatch in order to call the handler functions associated with it
export default function* () {
  yield takeLatest(types.GET_TRANSACTIONS.REQUEST, getTransactions);
  yield takeLatest(types.GET_REPORTS.REQUEST, getUserReports);
  yield takeLatest(
    types.DOWNLOAD_FROM_DASHBOARD.REQUEST,
    downloadFromDashboard
  );
  yield takeLatest(types.GET_REPORT.REQUEST, getReport);
  yield takeLatest(types.CREATE_REPORT.REQUEST, createReport);
  yield takeLatest(types.DELETE_REPORT.REQUEST, deleteReport);
  yield takeLatest(types.GET_TEASER.REQUEST, getTeaser);
  yield takeLatest(types.CONTACT_AGENCY.REQUEST, contactAgency);
  yield takeLatest(types.CONTACT_USER.REQUEST, contactUser);
  yield takeLatest(types.GET_REVIEWS.REQUEST, getReviews);
}
