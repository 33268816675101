import styles from "./BuyModal.module.scss";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getLoader } from "../../../../shared/Loader/store/selectors";
import { actions } from "../../../Dashboard/store/actions";
import { ROUTES } from "../../../../routes/constants";
import { getUser } from "../../../Auth/store/selectors";
import { setTypeModal } from "../../store/actions";

import AuthHeader from "../AuthHeader";
import CloseBtn from "../CloseBtn";
import Total from "./Total";
import Paypal from "./Paypal";
import Stripe from "./Stripe";
import alertToast from "../../../AlertToast";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);

const BuyModal = ({ hasMinimumInfo }) => {
  const loading = useSelector(getLoader());
  const dispatch = useDispatch(); // Hook to dispatch actions to the Redux store
  const navigate = useNavigate(); // Hook to navigate between routes
  const { name, email } = useSelector(getUser());
  const propertyData = localStorage.getItem("propertyData");
  const propertyDataParsed = JSON.parse(propertyData);
  const [successStep, setSuccessStep] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  nameRef.current = name;
  emailRef.current = email;

  // Function to create a report after successful payment
  const createReport = async (platform, platformData) => {
    // console.log("platformData", platformData);
    // console.log("platform", platform);

    // // Check if all necessary data is present
    if (
      propertyDataParsed &&
      platform &&
      platformData &&
      nameRef.current &&
      emailRef.current &&
      hasMinimumInfo
    ) {
      dispatch(
        actions.CREATE_REPORT.REQUEST(
          {
            data: propertyDataParsed,
            userEmail: emailRef.current, // Placeholder email
            userName: nameRef.current, // Placeholder user name
            platform: platform,
            platformData: platformData,
          },
          () => {
            setSuccessStep(true);
            navigate(ROUTES.TYP); // Navigate to Thank You Page after successful report creation
            dispatch(setTypeModal({ type: null, isBuy: false })); // Close the authentication modal
            // Show success toast message
            alertToast(
              "success",
              "Congratulazioni! Stiamo creando il tuo report",
              "Potrai trovare il tuo report salvato nella tua dashboard e inviato nella tua email. Controlla la tua mail nei prossimi cinque minuti e in caso di mancato ricevimento del report, contatta il team Houseplus che risolvera' il problema al piu` presto"
            );
          }
        )
      );
    } else {
      // console.log(propertyDataParsed, "propertyDataParsed on payment step");
      // console.log(platformData, "platformData on payment step");
      // console.log(platform, "platform on payment step");
      // console.log(name, "name on payment step");
      // console.log(email, "email on payment step");

      // Show error toast message if data is missing
      alertToast(
        "error",
        "Mancano i campi necessare per la creazione del report",
        "Riprova cliccando sul bottone 'Acquista la tua analisi', se il problema persiste contatta il team Houseplus che risolvera' il problema al piu` presto"
      );
      setSuccessStep(false);
    }
  };

  return (
    <div
      className={styles.buy_modal}
      style={{
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      <AuthHeader
        headerText={"Acquista la tua analisi"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
      />
      <CloseBtn />

      <div className={styles.buy_modal__form}>
        {/* Total payment information */}
        <Total />

        {/* Divider */}
        <div className={styles.divider} />

        {/* Payment method selection */}
        <div className={styles.buy_modal__form__subtitle}>
          Metodo di pagamento
        </div>

        {/* PayPal integration */}
        <Paypal createReport={createReport} />

        {/* Divider */}
        <div className={styles.buy_modal__form__hr}>
          <span>Oppure</span>
        </div>

        {/* Stripe payment method */}
        <Elements stripe={stripePromise}>
          <Stripe
            createReport={createReport}
            successStep={successStep}
            setSuccessStep={setSuccessStep}
          />
        </Elements>
      </div>
    </div>
  );
};

export default BuyModal;
