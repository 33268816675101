import styles from "./DeleteReportModal.module.scss";
import { useDispatch } from "react-redux";

import { actions } from "../store";

import Modal from "../../Common/Modal";
import { ReactComponent as Close } from "../../../assets/svgs/close.svg";
import Delete from "../../../assets/svgs/delete.svg";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import alertToast from "../../AlertToast";

const DeleteReportModal = ({ deleteModal, setDeleteModal }) => {
  const dispatch = useDispatch();

  return (
    <Modal modalState={deleteModal.isVisible} width={"80%"} maxWidth={"500px"}>
      <div className={styles.modal_content}>
        {/* CLOSE BUTTON */}
        <div className={styles.close_btn}>
          <Close
            onClick={() =>
              setDeleteModal((prevState) => ({
                ...prevState,
                isVisible: false,
              }))
            }
          />
        </div>
        <div className={styles.modal_content}>
          <div className={styles.modal_content__header}>
            Sei sicuro di voler eliminare il seguente report?
          </div>
          <p className={styles.modal_content__link}>
            "{deleteModal.reportUrl}"
          </p>
          <p
            className={styles.modal_content__header}
            style={{ textDecoration: "underline", fontWeight: 600 }}
          >
            Questa azione non e' reversibile
          </p>
          <div className={styles.modal_content__ctas}>
            <SecondaryButton
              text="Elimina"
              rightIconSrc={Delete}
              color={"#b10000"}
              borderColor={"#b10000"}
              className={styles.delete_acct}
              onClick={() => {
                dispatch(
                  actions.DELETE_REPORT.REQUEST(deleteModal.reportId, () => {
                    dispatch(actions.GET_REPORTS.REQUEST());
                    setDeleteModal((prevState) => ({
                      ...prevState,
                      isVisible: false,
                    }));
                    alertToast("success", "Report eliminato con successo");
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteReportModal;
