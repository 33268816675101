import styles from "./TYP.module.scss";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { ROUTES } from "../../routes/constants";

import ImageList from "../../containers/Auth/HOC/ImageList";
import AuthSvg from "../../assets/svgs/auth-svg.svg";
import PrimaryButton from "../../containers/Common/Buttons/PrimaryButton";
import Dashboard from "../../assets/svgs/dashboard-white.svg";
import {
  rightHOC,
  rightHOCItem,
  leftHOC,
  leftHOCItem,
} from "../../containers/Auth/HOC/Motion/MotionVariants";

const TYP = () => {
  const navigate = useNavigate();

  return (
    <motion.section
      className={styles.TYP}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.5,
          ease: "easeOut",
        },
      }}
      transition={{
        duration: 0.5,
        ease: "easeIn",
      }}
    >
      {/* LEFT SIDE */}
      <motion.div
        className={styles.TYP__left}
        initial="hiddenLeft"
        animate="visibleLeft"
        variants={leftHOC}
      >
        {/* LOGO */}
        <motion.div
          className={styles.TYP__left__logo}
          alt="logo"
          variants={leftHOCItem}
        />

        {/* CONTENT */}
        <motion.div
          className={styles.TYP__left__content}
          variants={leftHOCItem}
        >
          <h1>Grazie per aver scelto Houseplus!</h1>
          <p>Stiamo generando il tuo report ed entro 2 minuti lo troverai:</p>
          <ul>
            <li>Salvato nella tua Dashboard</li>
            <li>Inviato come allegato alla tua email</li>
          </ul>
          <div>
            Se riscontri dei problemi nel scaricare il tuo report, contatta il
            team di <a href="mailto:hello@houseplus.it">Houseplus</a> per
            ricevere assistenza.
          </div>
          <PrimaryButton
            text={"Vai alla tua Dashboard"}
            className={styles.submit_btn}
            leftIconSrc={Dashboard}
            background={"#078ea6"}
            onClick={() => navigate(ROUTES.DASHBOARD)}
          />
        </motion.div>

        {/* IMAGES LIST */}
        <ImageList className={"HOC__left__list"} leftHOCItem={leftHOCItem} />
      </motion.div>

      {/* RIGHT SIDE */}
      <motion.div
        className={styles.TYP__right}
        initial="hidden"
        animate="visible"
        variants={rightHOC}
      >
        {/* CONTENT */}
        <div className={styles.TYP__right__content}>
          {/* IMAGE */}
          <motion.img
            src={AuthSvg}
            alt="Auth"
            className={styles.TYP__right__img}
            variants={rightHOCItem}
          />

          {/* IMAGES LIST */}
          <ImageList
            className={"HOC__right__list"}
            rightHOCItem={rightHOCItem}
          />
        </div>
      </motion.div>
    </motion.section>
  );
};

export default TYP;
