export const rightHOC = {
  visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
  hidden: { opacity: 0 },
};

export const rightHOCItem = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.35, ease: "easeOut" },
  },
  hidden: { opacity: 0, x: 50 },
};

export const leftHOC = {
  visibleLeft: { opacity: 1, transition: { staggerChildren: 0.1 } },
  hiddenLeft: { opacity: 0 },
};

export const leftHOCItem = {
  visibleLeft: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.35, ease: "easeOut" },
  },
  hiddenLeft: { opacity: 0, x: -50 },
};
