import { useState, useEffect } from "react";

// Set display and opacity based on typeModal
// We want the modal to change the display but also to keep the opacity transition
const useAnimateModal = (typeModal, ref) => {
  const [display, setDisplay] = useState(false);
  const [show, setShow] = useState(false);

  // Set display and opacity based on typeModal
  // We want the modal to change the display but also to keep the opacity transition
  useEffect(() => {
    if (typeModal) {
      setDisplay(Boolean(typeModal));

      // Use requestAnimationFrame to ensure that the modal is displayed before setting the opacity
      // The first requestAnimationFrame is to wait for any ongoing layout calculations and DOM updates to finish.
      // The second requestAnimationFrame ensures that you're waiting for the next frame, essentially ensuring that
      // your updates in the prior frame (like changing display) have fully taken effect.
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // Trigger a repaint by accessing a style property
            void document.body.offsetHeight;
            setShow(Boolean(typeModal));
          });
        });
      });
    } else {
      // Check for the right transition property
      // For example, if transitioning 'opacity', check for it
      const handleTransitionEnd = (e) => {
        if (e.propertyName === "opacity") {
          setDisplay(Boolean(typeModal));
          ref?.current?.removeEventListener(
            "transitionend",
            handleTransitionEnd
          );
        }
      };

      // Set display to none immediately and wait for transition to end before setting opacity to 0
      setShow(Boolean(typeModal));
      ref?.current?.addEventListener("transitionend", handleTransitionEnd);

      // Cleanup in case the component gets unmounted before the transition finishes
      return () => {
        ref?.current?.removeEventListener("transitionend", handleTransitionEnd);
      };
    }
  }, [typeModal]);

  return { display, show };
};

export default useAnimateModal;
