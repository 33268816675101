import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./HeroText.module.scss";
import { motion } from "framer-motion";
import { getAuth } from "../../../Auth/store/selectors";

import { heroText, heroTextItem } from "../Motion/MotionVariants";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import SamplePdf from "../../../../assets/pdf/sample_pdf_houseplus.pdf";
import DownloadPdf from "../../../../assets/svgs/download-pdf.svg";
import alertToast from "../../../AlertToast";

import { setTypeModal } from "../../store/actions";

const HeroText = ({ hasMinimumInfo, isFromBakeca }) => {
  const dispatch = useDispatch();
  const isUserLoggedin = useSelector(getAuth());
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={heroText}
      className={styles.hero_text}
    >
      <div>
        {/* Header */}
        <motion.h1 className={styles.hero_text_header} variants={heroTextItem}>
          {isFromBakeca && hasMinimumInfo ? (
            <>
              Vuoi valutare se dietro l'annuncio si nasconde un vero{" "}
              <span>affare</span>?
            </>
          ) : isFromBakeca && !hasMinimumInfo ? (
            <>
              L'annuncio da te selezionato non sembra contenere le informazioni
              necessarie per poterlo <span>valutare</span>
            </>
          ) : (
            <>
              Qui valutiamo la bontà degli annunci immobiliari per gli utenti di{" "}
              <span>Bakeca.it</span>.
            </>
          )}
        </motion.h1>
        {/* SubHeader */}
        <motion.p
          className={styles.hero_text_subheader}
          variants={heroTextItem}
        >
          {isFromBakeca && hasMinimumInfo ? (
            <>
              Noi di Houseplus mettiamo a tua disposizione la nostra lunga
              esperienza nel settore immobiliare e le nostre accurate banche
              dati per supportarti nel prendere decisioni consapevoli sui tuoi
              investimenti immobiliari in modo rapido, accessibile e oggettivo.
            </>
          ) : isFromBakeca && !hasMinimumInfo ? (
            <>Riprova con un annuncio piu' completo</>
          ) : (
            <>
              E&#39; un servizio dedicato agli utenti di Bakeca e vi accedono
              tramite il bottone{" "}
              <span className={styles.fake_btn}>Scopri se è un affare</span> su
              un annuncio di casa in vendita.
            </>
          )}
        </motion.p>
      </div>

      <motion.div className={styles.hero_text_ctas} variants={heroTextItem}>
        <div className={styles.hero_text_ctas__btns}>
          {/* BACK TO BAKECA CTA */}
          {!isFromBakeca && !hasMinimumInfo ? (
            <a
              href="https://www.bakeca.it/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <SecondaryButton
                text="Vai su Bakeca.it"
                width={140}
                height={60}
              />
            </a>
          ) : (
            <a
              href={SamplePdf}
              download="Esempio_PDF_HousePlus"
              style={{ textDecoration: "none" }}
            >
              <SecondaryButton
                text="Scarica esempio"
                width={190}
                height={60}
                leftIconSrc={DownloadPdf}
                iconWidth={18}
              />
            </a>
          )}
          {/* BUY CTA */}
          <div className={styles.buy_btn}>
            <PrimaryButton
              text={"Acquista la tua analisi"}
              width={280}
              height={60}
              onClick={() => {
                if (hasMinimumInfo) {
                  if (isUserLoggedin) {
                    dispatch(setTypeModal({ type: "buy", isBuy: true }));
                  } else {
                    dispatch(setTypeModal({ type: "signup", isBuy: true }));
                  }
                } else {
                  alertToast(
                    "warning",
                    "Dati insufficienti",
                    "L'immobile selezionato non ha abbastanza informazioni per generare un report."
                  );
                }
              }}
              disabled={!hasMinimumInfo}
            />
            {isFromBakeca && hasMinimumInfo && (
              <div className={styles.buy_btn__tooltip}>€ 4.88</div>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default HeroText;
