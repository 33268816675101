import { useEffect } from "react";
import styles from "./Paypal.module.scss";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Spinner from "../../../../Common/Spinner";

const Paypal = ({ createReport }) => {
  const [{ isPending, isRejected }, dispatch] = usePayPalScriptReducer();
  // console.log(isPending, "isPaypalPending");
  // console.log(isRejected, "isPaypalRejected");
  // console.log(usePayPalScriptReducer(), "usePayPalScriptReducer()");

  // Retry loading script when PayPal fails to load
  useEffect(() => {
    // console.log(isPending, "isPaypalPending");
    // console.log(isRejected, "isPaypalRejected");
    if (isRejected) {
      console.log("PayPal failed to load, retrying...");
      // Dispatch an action to retry loading the PayPal script
      dispatch({ type: "resetOptions" });
    }
  }, [isRejected, dispatch]);

  // Create paypal order ID
  const createOrder = (data, actions) => {
    // console.log(actions, "actions");

    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: "EUR",
              value: "4.88",
            },
          },
        ],
      })
      .then((orderID) => {
        // console.log(orderID, "orderID");
        return orderID;
      });
  };

  // Handle paypal payment
  const handlePaypalPayment = (data, actions) => {
    // Capture the funds from the transaction
    return actions.order.capture().then(function (details) {
      // Create report with Paypal orderID
      createReport("paypal", details);
    });
  };

  return (
    <div className={styles.paypal_btn}>
      <div className={styles.btn}>
        {!isPending ? (
          <PayPalButtons
            style={{
              layout: "horizontal",
              label: "paypal",
              height: 42,
              size: "large",
              tagline: false,
            }}
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={handlePaypalPayment}
            showSpinner={true}
          />
        ) : (
          <Spinner
            position="relative"
            backgroundColor="transparent"
            spinnerWidth="50px"
            spinnerHeight="50px"
            imageWidth="15px"
            imageHeight="15px"
          />
        )}
      </div>
    </div>
  );
};

export default Paypal;
