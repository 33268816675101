import { setTypeModal } from "../../../store/actions";
import { actions as reduxActions } from "../../../../Auth/store";
import alertToast from "../../../../AlertToast";
import { ROUTES } from "../../../../../routes/constants";

// SIGNUP FUNCTION EITHER BY FORM OR BY GOOGLE OR FACEBOOK
const signUp = (
  dispatch,
  response,
  platform,
  formValues,
  setSuccessStep,
  navigate,
  typeModal
) => {
  // console.log(response, "RESPONSE SIGNUP");
  // console.log(formValues, "RESPONSE SIGNUP");

  dispatch(
    reduxActions[`SIGN_UP${platform}`].REQUEST(response || formValues, () => {
      // console.log(platform, "PLATFORM");
      if (platform !== "_GOOGLE") {
        // console.log(platform, "PLATFORM INSIDE CONDITION");
        dispatch(
          reduxActions[`SIGN_IN${platform}`].REQUEST(
            response || formValues,
            () => {
              dispatch(
                reduxActions.FETCH_USER.REQUEST("", () => {
                  setSuccessStep(true);

                  if (typeModal.isBuy) {
                    setTimeout(() => {
                      dispatch(setTypeModal({ type: "buy", isBuy: true }));
                      setSuccessStep(false);
                      alertToast(
                        "success",
                        "Registrazione Effettuata",
                        "Puoi adesso procedere con il pagamento per generare il tuo report PDF"
                      );
                    }, 500);
                  } else {
                    alertToast("success", "Registrazione Effettuata");
                    navigate(ROUTES.DASHBOARD);
                    dispatch(setTypeModal({ type: null }));
                  }
                })
              );
            }
          )
        );
      } else {
        dispatch(
          reduxActions.FETCH_USER.REQUEST("", () => {
            setSuccessStep(true);

            if (typeModal.isBuy) {
              setTimeout(() => {
                dispatch(setTypeModal({ type: "buy", isBuy: true }));
                setSuccessStep(false);
                alertToast(
                  "success",
                  "Registrazione Effettuata",
                  "Puoi adesso procedere con il pagamento per generare il tuo report PDF"
                );
              }, 500);
            } else {
              alertToast("success", "Registrazione Effettuata");
              navigate(ROUTES.DASHBOARD);
              dispatch(setTypeModal({ type: null }));
            }
          })
        );
      }
    })
  );
};

export default signUp;
