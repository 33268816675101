import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Google from "../../../../assets/svgs/google.svg";
import alertToast from "../../../AlertToast";
import { useDispatch, useSelector } from "react-redux";
import styles from "./GoogleBtn.module.scss";
import { getTypeModal } from "../../store/selectors";

const GoogleBtn = ({ className, callback, setSuccessStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeModal = useSelector(getTypeModal());

  const authGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (response) => {
      callback(
        dispatch,
        response,
        "_GOOGLE",
        "",
        setSuccessStep,
        navigate,
        typeModal
      );
      // console.log(response, "RESPONSE GOOGLE");
    },
    onError: (response) => {
      // console.log(response, "RESPONSE GOOGLE ERROR");
      alertToast("error", "Problema con l'autenticazione Google");
    },
  });

  return (
    <div className={className} onClick={authGoogle}>
      <img src={Google} alt="google" />{" "}
      <span className={styles.span}>Google</span>
    </div>
  );
};

export default GoogleBtn;
