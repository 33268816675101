import styles from "./adsAmount.module.scss";
import formatToEuro from "../../../../../../utils/formatToEuro";

const adsAmount = ({ ads_amount }) => {
  return (
    <div className={styles.ads_amount}>
      <div>{formatToEuro(ads_amount || 84)}</div>
      <div>N. annunci</div>
    </div>
  );
};

export default adsAmount;
