import styles from "./populationDensity.module.scss";
import { Cell, RadialBar, RadialBarChart } from "recharts";

const populationDensity = ({
  population_density,
  bakecaColorFull,
  bakecaColorEmpty,
}) => {
  const translateValue = (value) => {
    if (value < 1) {
      return { label: "bassa", value: 25 };
    }
    if (value < 2) {
      return { label: "media", value: 50 };
    }
    return { label: "alta", value: 100 };
  };

  const data = [
    {
      name: "Example",
      value: 100,
      fill: bakecaColorFull || "#11606D",
    },
    {
      name: "Density",
      value:
        translateValue(population_density?.graph_data?.data?.level)?.value ||
        60,
      fill: bakecaColorFull || "#11606D",
    },
  ];

  return (
    <>
      <div className={styles.population_density}>
        <RadialBarChart
          width={160}
          height={120}
          innerRadius="40%"
          outerRadius="100%"
          data={[
            {
              name: "Example",
              value: 100,
              fill: bakecaColorFull || "#11606D",
            },
            {
              name: "Density",
              value:
                translateValue(population_density?.graph_data?.data?.level)
                  ?.value || 60,
              fill: bakecaColorFull || "#11606D",
            },
          ]}
          startAngle={180}
          endAngle={0}
          barCategoryGap={-5}
        >
          <RadialBar
            minAngle={15}
            background={{ fill: bakecaColorEmpty || "#B2ECF2" }}
            clockWise={true}
            dataKey="value"
          >
            {data.map((entry, value) => (
              <Cell
                key={`cell-${value}`}
                style={entry.name === "Example" ? { display: "none" } : {}}
              />
            ))}
          </RadialBar>
        </RadialBarChart>
        <div className={styles.text}>
          <p className={styles.label}>
            {translateValue(population_density?.graph_data?.data?.level)
              ?.label || "Alta"}
          </p>
          <p className={styles.source}>
            Comune con{" "}
            <span>
              {population_density?.graph_data?.data?.displayed_value ||
                "534 ab./km²"}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default populationDensity;
