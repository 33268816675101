import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import styles from "./CompleteTable.module.scss";
import clx from "classnames";
import Download from "../../../assets/svgs/download-pdf.svg";
import DeleteReport from "../../../assets/svgs/delete-report-table.svg";
import NotImg from "../../../assets/images/img-not-available.png";

import formatToEuro from "../../../utils/formatToEuro";
import { actions } from "../store";

const CompleteTable = ({ userReports, setDeleteModal }) => {
  // console.log(userReports, "userReports");
  const dispatch = useDispatch();

  const handleDeleteReport = (reportId, reportUrl) => {
    setDeleteModal({
      isVisible: true,
      reportId,
      reportUrl,
    });
  };

  const downloadUserReport = (reportId) => {
    dispatch(actions.DOWNLOAD_FROM_DASHBOARD.REQUEST(reportId));
  };

  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    const fetchAllImages = () => {
      const allData = userReports.map((report) => {
        let parsedPhoto;
        try {
          parsedPhoto = JSON.parse(report?.url_foto) || [];
          // console.log(parsedPhoto?.[0], "parsedPhoto");
        } catch (err) {
          // If JSON.parse fails, return a rejected Promise with the error value
          return Promise.reject(NotImg);
        }

        return fetch(parsedPhoto?.[0])
          .then((data) => {
            // console.log(data.status, "data");
            if (data.status == 200) {
              return parsedPhoto?.[0];
            } else {
              return NotImg;
            }
          })
          .catch((err) => {
            return NotImg;
          });
      });
      Promise.all(allData).then((data) => {
        // console.log(data, "PROMISE ALL");
        setPhotos(data);
      });
    };
    fetchAllImages();
  }, []);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th />
          <th>URL annuncio</th>
          <th>Citta`</th>
          <th>Indirizzo</th>
          <th>Valutazione</th>
          <th>Semaforo</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {userReports?.map((report, index) => {
          return (
            <tr key={`report-${index}`}>
              <td className={styles.table_img_container}>
                <a href={photos[index]} target="_blank">
                  <img
                    src={photos[index]}
                    alt="home-icon"
                    className={styles.table_img}
                  />
                </a>
              </td>
              <td className={styles.table_url}>
                <a href={report?.url_annuncio} target="_blank" rel="noreferrer">
                  {report?.url_annuncio?.length > 30
                    ? `${report?.url_annuncio?.slice(0, 30)}...`
                    : report?.url_annuncio}
                </a>
              </td>
              <td>{report?.city || ""}</td>
              <td>
                {report?.address || "Indirizzo non presente nell'annuncio"}
              </td>
              <td>€ {formatToEuro(String(report?.valuation) || 0)}</td>
              <td>
                <div className={styles.semaforo}>
                  <div
                    className={clx(styles.semaforo__red, {
                      [styles.inactive]: report?.semaforo !== "red",
                    })}
                  />
                  <div
                    className={clx(styles.semaforo__yellow, {
                      [styles.inactive]: report?.semaforo !== "orange",
                    })}
                  />
                  <div
                    className={clx(styles.semaforo__green, {
                      [styles.inactive]: report?.semaforo !== "green",
                    })}
                  />
                </div>
              </td>
              <td className={styles.table_icons_container}>
                <div className={styles.table_icons}>
                  <img
                    src={Download}
                    alt="copy-report"
                    onClick={() => downloadUserReport(report?.report_id)}
                    tabIndex="0"
                    onKeyPress={(e) =>
                      e.key === "Enter" && downloadUserReport(report?.report_id)
                    }
                  />
                  <img
                    src={DeleteReport}
                    alt="delete-report"
                    onClick={() =>
                      handleDeleteReport(
                        report?.report_id,
                        report?.url_annuncio
                      )
                    }
                    tabIndex="0"
                    onKeyPress={(e) => {
                      e.key === "Enter" &&
                        handleDeleteReport(
                          report?.report_id,
                          report?.url_annuncio
                        );
                    }}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompleteTable;
