import styles from "./MediaBanner.module.scss";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import MediaImages from "../../../../assets/svgs/media/MediaImages";
import { getDirection } from "../../store/selectors";

const MediaBanner = () => {
  const isScrollingDown = useSelector(getDirection());

  return (
    <div className={styles.banner}>
      <motion.p
        className={styles.banner_header}
        initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }}
        viewport={{ margin: "-50px" }}
      >
        Parlano di Houseplus
      </motion.p>
      <motion.div
        className={styles.banner_list}
        initial={{ opacity: 0, x: 50 }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }}
      >
        {Object.keys(MediaImages).map((key, index) => (
          <div
            className={styles.banner_list_card}
            key={`slider-media-${index}`}
          >
            <img src={MediaImages[key]} alt="slider-img" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default MediaBanner;
