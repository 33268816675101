function buildAddressString(addressInfo) {
  const fields = ["road", "house_number", "postcode", "city", "county"];
  let addressString = "";
  let previousFieldAdded = false;

  for (let i = 0; i < fields.length; i++) {
    if (addressInfo?.[fields[i]]) {
      if (
        fields[i] === "county" &&
        addressInfo?.city !== "" &&
        addressInfo?.[fields[i]] === addressInfo?.city
      ) {
        continue;
      }

      if (previousFieldAdded) {
        addressString += ", ";
      }

      addressString += addressInfo?.[fields[i]];
      previousFieldAdded = true;
    }
  }

  return addressString;
}

export default buildAddressString;
