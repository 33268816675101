import styles from "./AgencyCta.module.scss";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import PrimaryButton from "../../Common/Buttons/SecondaryButton";
import { getDirection } from "../store/selectors";
import HomeImg from "../../../assets/images/plus-home.png";
import HomeImgSmall from "../../../assets/images/logo-white.png";

const AgencyCta = () => {
  const isScrollingDown = useSelector(getDirection());

  return (
    <section className={styles.agency_cta_container}>
      <div className={styles.agency_cta}>
        <div className={styles.agency_cta__logo_small}>
          <img alt="Houseplus logo" src={HomeImgSmall} />
        </div>
        <motion.div
          className={styles.agency_cta__logo}
          initial={{ opacity: 0, x: -50 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          viewport={{ margin: "-100px 0px -50px 0px" }}
        >
          <img alt="Houseplus logo" src={HomeImg} />
        </motion.div>
        <div className={styles.agency_cta__text}>
          <motion.h1
            initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5, ease: "easeOut" },
            }}
          >
            Sei un'agenzia immobiliare?
          </motion.h1>
          <motion.p
            className={styles.agency_cta__text__sub}
            initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5, ease: "easeOut" },
            }}
            viewport={{ margin: "-100px 0px -50px 0px" }}
          >
            Sfrutta la più potente intelligenza artificiale sul mercato
            immobiliare per fornire valutazioni e servizi di consulenza
            impeccabili.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: isScrollingDown ? -50 : 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5, ease: "easeOut" },
            }}
          >
            <a href="https://www.houseplus.it" target="_blank" rel="noreferrer">
              <PrimaryButton
                text="Visita Houseplus.it"
                width={300}
                color={"#078ea6"}
                background={"#ffffff"}
                borderColor={"#ffffff"}
                height={60}
                fontSize={16}
              />
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AgencyCta;
