import React from "react";
import styles from "./HeroImage.module.scss";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { getTeaserData } from "../../../Dashboard/store/selectors";
import Boilerplate from "../../../../assets/svgs/boilerplate.svg";

import { heroImages, heroImageMain } from "../Motion/MotionVariants";

const HeroImage = () => {
  const teaserData = useSelector(getTeaserData());
  // console.log(teaserData, "teaserData");
  return teaserData?.dati_annuncio?.url_foto.length >= 1 ? (
    <motion.a
      className={styles.hero_real_images}
      initial="hidden"
      whileInView="visible"
      // viewport={{ once: true }}
      variants={heroImages}
      href={teaserData?.dati_annuncio?.url_foto?.[0]}
      target="_blank"
    >
      <motion.img
        src={teaserData?.dati_annuncio?.url_foto?.[0]}
        alt="header-main"
        className={styles.hero_images_main}
        variants={heroImageMain}
      />
    </motion.a>
  ) : (
    <motion.div
      className={styles.hero_placeholder_images}
      initial="hidden"
      whileInView="visible"
      // viewport={{ once: true }}
      variants={heroImages}
    >
      <motion.img
        src={Boilerplate}
        alt="header-main"
        className={styles.hero_placeholder_images_main}
        variants={heroImageMain}
      />
      {/* <motion.img
        src={HeroImageTop}
        alt="header-top"
        className={styles.hero_placeholder_images_top}
        variants={heroImageSmall}
      />
      <motion.img
        src={HeroImageBottom}
        alt="header-bottom"
        className={styles.hero_placeholder_images_bottom}
        variants={heroImageSmall}
      /> */}
    </motion.div>
  );
};

export default HeroImage;
