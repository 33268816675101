import * as yup from "yup";

export const INITIAL_VALUES_SIGNUP = {
  nameSignup: "",
  emailSignup: "",
  pwdSignup: "",
  acceptPolicy: false,
  // pwdConfirmation: "",
};

export const VALIDATION_SCHEMA_SIGNUP = yup.object().shape({
  nameSignup: yup
    .string("Inserisci il tuo nome e cognome")
    .required("Nome e Cognome richiesto"),
  emailSignup: yup
    .string("Inserisci la tua email")
    // .email("Attenzione! L`email inserita è nel formato errato")
    .required("Email richiesta")
    .matches(
      // Regular expression for email validation
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      "Attenzione! L`email inserita è nel formato errato"
    ),
  pwdSignup: yup
    .string()
    .min(3, "Password deve contenere minimo 3 caratteri")
    .max(100, "Password troppo lunga")
    .required("Password richiesta"),
  acceptPolicy: yup
    .boolean()
    .required("Informativa privacy richiesta")
    .oneOf([true], "Informativa privacy richiesta"),
  // pwdConfirmation: yup
  //   .string()
  //   .oneOf([yup.ref("pwd"), null], "Attenzione! Le password non coincidono")
  //   .min(3, "La password è troppe breve")
  //   .max(100, "La password è troppo lunga")
  //   .required("Conferma password richiesta"),
});

export const INITIAL_VALUES_LOGIN = {
  emailLogin: "",
  pwdLogin: "",
};

export const VALIDATION_SCHEMA_LOGIN = yup.object().shape({
  emailLogin: yup
    .string("Inserisci la tua email")
    // .email("Attenzione! L`email inserita è nel formato errato")
    .required("Email richiesta")
    .matches(
      // Regular expression for email validation
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      "Attenzione! L`email inserita è nel formato errato"
    ),
  pwdLogin: yup
    .string()
    .min(3, "Password deve contenere minimo 3 caratteri")
    .max(100, "Password troppo lunga")
    .required("Password richiesta"),
});

export const INITIAL_VALUES_FORGOT = {
  emailForgot: "",
};

export const VALIDATION_SCHEMA_FORGOT = yup.object().shape({
  emailForgot: yup
    .string("Inserisci la tua email")
    // .email("Attenzione! L`email inserita è nel formato errato")
    .required("Email richiesta")
    .matches(
      // Regular expression for email validation
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      "Attenzione! L`email inserita è nel formato errato"
    ),
});

export const INITIAL_VALUES_RESET = {
  newPwd: "",
  newPwdConfirmation: "",
};

export const VALIDATION_SCHEMA_RESET = yup.object().shape({
  newPwd: yup
    .string()
    .min(3, "Password deve contenere minimo 3 caratteri")
    .max(100, "Password troppo lunga")
    .required("Password richiesta"),
  newPwdConfirmation: yup
    .string()
    .oneOf([yup.ref("newPwd"), null], "Attenzione! Le password non coincidono")
    .min(3, "Password deve contenere minimo 3 caratteri")
    .max(100, "La password è troppo lunga")
    .required("Conferma password richiesta"),
});
