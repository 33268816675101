import styles from "./TransactionsTable.module.scss";

const TransactionsTable = ({ transactions }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Qta Report</th>
          <th>Importo (€)</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {/* LOOP HERE FOR ALL TRANSACTIONS */}
        {transactions?.map((transaction, idx) => (
          <tr key={`transaction-${idx}`}>
            <td className={styles.far_left}>{transaction?.id}</td>
            <td>{transaction?.quantity}</td>
            <td>{transaction?.amount}</td>
            <td className={styles.far_right}>
              {transaction?.data?.split("T")[0]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionsTable;
