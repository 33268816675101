import styles from "../Account.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";

import Edit from "../../../../assets/svgs/edit.svg";
import Close from "../../../../assets/svgs/close-fat.svg";
import Check from "../../../../assets/svgs/check.svg";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import alertToast from "../../../AlertToast";

import { actions as actionsUser } from "../../../Auth/store";

import {
  Field,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import TextField from "../../../Common/TextField";
import {
  INITIAL_VALUES_ACCOUNT_PASSWORD,
  VALIDATION_SCHEMA_ACCOUNT_PASSWORD,
} from "../constants";

const PasswordForm = () => {
  const dispatch = useDispatch();
  const asteriskString = (string) => "*".repeat(string.length);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.account__block}>
      <div className={styles.account__block__content}>
        <div className={styles.account__block__title}>Password</div>
        <div className={styles.account__block__group}>
          <div className={styles.text}>{asteriskString("sample")}</div>
          <PrimaryButton
            background={"#f97316"}
            width={100}
            height={35}
            text="Modifica"
            leftIconSrc={Edit}
            iconWidth={14}
            className={styles.edit}
            onClick={() => {
              setShowPassword(true);
            }}
          />
        </div>
      </div>
      {showPassword && (
        <>
          <hr />
          <div className={styles.account__block__modify}>
            <div className={styles.account__block__modify__form}>
              <Formik
                initialValues={INITIAL_VALUES_ACCOUNT_PASSWORD}
                // Custom yup validate function in order to pass context to it
                validate={(values) => {
                  try {
                    validateYupSchema(
                      values,
                      VALIDATION_SCHEMA_ACCOUNT_PASSWORD,
                      true,
                      {
                        showPassword,
                      }
                    ); // Context
                  } catch (err) {
                    return yupToFormErrors(err);
                  }

                  return {};
                }}
                onSubmit={(values, actions) => {
                  dispatch(
                    actionsUser.CHANGE_PASSWORD.REQUEST(values, () => {
                      dispatch(actionsUser.FETCH_USER.REQUEST());
                      setShowPassword(false);
                      actions.resetForm();
                      alertToast("success", "Password modificata con successo");
                    })
                  );
                }}
              >
                {({ resetForm }) => (
                  <Form className={styles.form}>
                    <Field
                      name="oldPassword"
                      id="oldPassword"
                      component={TextField}
                      placeholder="Inserisci password attuale"
                      type="password"
                      showPassword={true}
                    />
                    <Field
                      name="newPassword"
                      id="newPassword"
                      component={TextField}
                      placeholder="Inserisci nuova password"
                      type="password"
                      showPassword={true}
                    />
                    <div className={styles.account__block__modify__form_btns}>
                      <PrimaryButton
                        text="Salva modifica"
                        height={35}
                        width={"60%"}
                        leftIconSrc={Check}
                        iconWidth={13}
                        background={"#24707d"}
                        className={styles.save_edit}
                        type="submit"
                        // disabled={isSubmitting}
                      />
                      <PrimaryButton
                        text="Annulla"
                        height={35}
                        width={"40%"}
                        leftIconSrc={Close}
                        iconWidth={11}
                        background={"#8c8c8c"}
                        className={styles.undo_edit}
                        onClick={() => {
                          resetForm();
                          setShowPassword(false);
                        }}
                        // disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordForm;
