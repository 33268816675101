import styles from "./SimilarProperties.module.scss";
import stylesMain from "../../../DownloadPdf.module.scss";

import formatToEuro from "../../../../../utils/formatToEuro";

import ArrowNegative from "../../../../../assets/svgs/arrow-negative.svg";
import ArrowPositive from "../../../../../assets/svgs/arrow-positive.svg";

const SimilarProperties = ({
  similar_properties,
  property_price,
  property_priceMq,
  similar_properties_GPT,
}) => {
  // console.log(property_priceMq, "property_priceMq");
  // console.log(property_price, "property_price");
  // console.log(similar_properties, "similar_properties");

  return (
    <div className={styles.similar_properties}>
      <div className={stylesMain.page__section_title}>
        Immobili simili nell'area
      </div>
      {similar_properties?.length > 0 ? (
        <>
          {similar_properties_GPT && (
            <div className={styles.similar_properties__subtitle}>
              {similar_properties_GPT}
            </div>
          )}
          <div className={styles.similar_properties__table}>
            <table>
              <thead>
                <tr className={styles.head}>
                  <th
                    colSpan="1"
                    style={{
                      borderBottom: "1px solid #DAEBEE",
                      borderTopLeftRadius: "30px",
                    }}
                  >
                    Tipologia <br /> dell'immobile
                  </th>
                  <th colSpan="4" style={{ borderBottom: "1px solid #DAEBEE" }}>
                    Prezzo <br /> (€)
                  </th>
                  <th colSpan="1" style={{ borderBottom: "1px solid #DAEBEE" }}>
                    Prezzo unitario <br /> (€/m
                    <sup style={{ fontSize: "8px" }}>2</sup>)
                  </th>
                  <th colSpan="1" style={{ borderBottom: "1px solid #DAEBEE" }}>
                    Superficie <br /> (m<sup style={{ fontSize: "8px" }}>2</sup>
                    )
                  </th>
                  <th colSpan="1" style={{ borderBottom: "1px solid #DAEBEE" }}>
                    Giorni <br /> sul mercato
                  </th>
                  <th
                    colSpan="1"
                    style={{
                      borderBottom: "1px solid #DAEBEE",
                      borderTopRightRadius: "30px",
                    }}
                  >
                    Distanza dall'immobile <br /> (metri)
                  </th>
                </tr>
              </thead>
              <tbody>
                {similar_properties?.slice(0, 10)?.map((property, index) => (
                  <tr key={`similar-${index}`}>
                    <td
                      style={{
                        borderLeft: "1px solid #daebee",
                        borderBottomLeftRadius:
                          index == similar_properties?.length - 1 && "30px",
                      }}
                    >
                      {property.typology || "Non disponibile"}
                    </td>
                    <td colSpan="4">
                      <div className={styles.price_column}>
                        {formatToEuro(property?.price || 0)}{" "}
                        <img
                          src={
                            property_price <= property?.price
                              ? ArrowNegative
                              : ArrowPositive
                          }
                          alt="comparison-icon"
                        />
                      </div>
                    </td>
                    <td colSpan="1">
                      <div className={styles.price_column}>
                        {formatToEuro(property?.price_mq?.toFixed(0) || 0)}{" "}
                        <img
                          src={
                            property_priceMq <= property?.price_mq
                              ? ArrowNegative
                              : ArrowPositive
                          }
                          alt="comparison-icon"
                        />
                      </div>
                    </td>
                    <td colSpan="1">
                      {property.surface || "Non disponibile"} m
                      <sup style={{ fontSize: "6px" }}>2</sup>
                    </td>
                    <td colSpan="1">{property.GSM || "Non disponibile"}</td>
                    <td
                      colSpan="1"
                      style={{
                        borderRight: "1px solid #daebee",
                        borderBottomRightRadius:
                          index == similar_properties?.length - 1 && "30px",
                      }}
                    >
                      {Math.round(Number(property?.dist || 0))} m
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.legend}>
            <div className={styles.legend__text}>
              * <img src={ArrowPositive} alt="comparison-icon" /> Immobile più
              economico rispetto al prezzo dell'annuncio
            </div>
            <div className={styles.legend__text}>
              * <img src={ArrowNegative} alt="comparison-icon" /> Immobile più
              costoso rispetto al prezzo dell'annuncio
            </div>
          </div>
        </>
      ) : (
        <div className={styles.similar_properties__nodata}>
          Non sono stati trovati immobili in vendita con simili caratteristiche.
        </div>
      )}
    </div>
  );
};

export default SimilarProperties;
