import { useRef } from "react";
import clx from "classnames";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";

import useAnimateModal from "../../../utils/useAnimateModal";

const Modal = ({ modalState, children, height, width, maxWidth, padding }) => {
  const modalOverlayRef = useRef(null);

  // Set display and opacity based on modalState
  // We want the modal to change the display but also to keep the opacity transition
  const { display, show } = useAnimateModal(modalState, modalOverlayRef);

  return createPortal(
    <div
      className={clx(styles.modal_overlay, { [styles.show]: show })}
      style={{ display: display ? "block" : "none" }}
      ref={modalOverlayRef}
    >
      <div
        className={styles.modal_wrapper}
        style={{ height, width, padding, maxWidth }}
      >
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};
export default Modal;
