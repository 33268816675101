import { useEffect } from "react";

const useNoScroll = (modalStateOne) => {
  useEffect(() => {
    // Check if window width is greater than 1100px
    if (window.innerWidth > 1100) {
      const navbar = document.querySelector(".navbar-main");

      if (modalStateOne) {
        document.body.style.paddingRight = "8px";
        if (navbar) {
          navbar.style.paddingRight = "38px";
        }
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.paddingRight = "0px";
        if (navbar) {
          navbar.style.paddingRight = "30px";
        }

        document.body.style.overflow = "auto";
      }

      // Cleanup function to reset styles
      return () => {
        document.body.style.paddingRight = "0px";
        const navbar = document.querySelector(".navbar-main");
        if (navbar) {
          navbar.style.paddingRight = "30px";
        }
        document.body.style.overflow = "auto";
      };
    }
  }, [modalStateOne]);
};

export default useNoScroll;
