import { createSelector } from "reselect";

const selectAccountState = (state) => state.accountReducer;

export const getUserCredits = () =>
  createSelector(selectAccountState, (state) => state.credits);
export const getTransactions = () =>
  createSelector(selectAccountState, (state) => state.transactions);
export const getUserReports = () =>
  createSelector(selectAccountState, (state) => state.userReports);
export const getReport = () =>
  createSelector(selectAccountState, (state) => state.report);
export const getTeaserData = () =>
  createSelector(selectAccountState, (state) => state.teaser);
