import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./Sidebar.module.scss";
import { motion, useCycle } from "framer-motion";

import Burger from "./Burger";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import { ReactComponent as Dashboard } from "../../../../assets/svgs/dashboard.svg";
import { ReactComponent as Login } from "../../../../assets/svgs/enter.svg";
import { sidebar, ulVariants, liVariants } from "../Motion/MotionVariants";
import alertToast from "../../../AlertToast";

import { ROUTES } from "../../../../routes/constants";

import closeSideAndScroll from "../../../../utils/closeSideAndScroll";
import useWindowSize from "../../../../utils/useWindowSize";

import { setTypeModal } from "../../store/actions";

const Sidebar = ({
  townInfoRef,
  whyRef,
  footerRef,
  isUserLoggedin,
  hasMinimumInfo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { windowHeight } = useWindowSize();

  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={windowHeight}
      ref={containerRef}
      className={styles.motion_sidebar}
      style={{ pointerEvents: isOpen ? "auto" : "none" }}
    >
      {/* SIDEBAR DIV */}
      <motion.div
        className={styles.background}
        variants={sidebar(windowHeight)}
      />

      {/* SIDEBAR CONTENT */}
      <motion.ul variants={ulVariants}>
        <motion.li
          variants={liVariants}
          onClick={() => closeSideAndScroll(townInfoRef, toggleOpen)}
          tabIndex="0"
          onKeyPress={(e) =>
            e.key === "Enter" && closeSideAndScroll(townInfoRef, toggleOpen)
          }
        >
          Anteprima dell'analisi
        </motion.li>
        <motion.li
          variants={liVariants}
          onClick={() => closeSideAndScroll(whyRef, toggleOpen)}
          tabIndex="0"
          onKeyPress={(e) =>
            e.key === "Enter" && closeSideAndScroll(whyRef, toggleOpen)
          }
        >
          Perché Houseplus
        </motion.li>
        <motion.li
          variants={liVariants}
          style={{ marginBottom: 16 }}
          onClick={() => closeSideAndScroll(footerRef, toggleOpen)}
          tabIndex="0"
          onKeyPress={(e) =>
            e.key === "Enter" && closeSideAndScroll(footerRef, toggleOpen)
          }
        >
          Contattaci
        </motion.li>
        <motion.li variants={liVariants} className={styles.dashboard_btn}>
          <SecondaryButton
            text={isUserLoggedin ? "Dashboard" : "Accedi"}
            leftIconSrc={isUserLoggedin ? Dashboard : Login}
            isReactIcon={true}
            viewBox={isUserLoggedin ? "0 -1 18 18" : "0 -1 20 20"}
            width={180}
            marginBottom={-18}
            borderColor={"#f9f9fa"}
            color={"#f9f9fa"}
            onClick={() => {
              if (isUserLoggedin) {
                navigate(ROUTES.DASHBOARD);
              } else {
                dispatch(setTypeModal({ type: "login", isBuy: false }));
              }
            }}
            className={!isUserLoggedin && styles.dashboard_button}
          />
        </motion.li>
        {/* {isFromBakeca && ( */}
        <motion.li variants={liVariants}>
          <PrimaryButton
            text="Acquista la tua analisi"
            color={"#0f172a"}
            background={"#f9f9fa"}
            onClick={() => {
              if (hasMinimumInfo) {
                if (isUserLoggedin) {
                  dispatch(setTypeModal({ type: "buy", isBuy: true }));
                } else {
                  dispatch(setTypeModal({ type: "signup", isBuy: true }));
                }
              } else {
                alertToast(
                  "warning",
                  "Dati insufficienti",
                  "L'immobile selezionato non ha abbastanza informazioni per generare un report."
                );
              }
            }}
            disabled={!hasMinimumInfo}
          />
        </motion.li>
        {/* )} */}
      </motion.ul>

      {/* BURGER BTN */}
      <Burger toggleOpen={toggleOpen} />
    </motion.div>
  );
};

export default Sidebar;
