import { createSelector } from "reselect";

const selectLandingState = (state) => state.landingReducer;
// console.log(selectLandingState, "selectLandingState");

export const getDirection = () =>
  createSelector(selectLandingState, (state) => state.direction);

export const getTypeModal = () =>
  createSelector(selectLandingState, (state) => state.typeModal);
