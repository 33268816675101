import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import { Field, Form, Formik } from "formik";

import { INITIAL_VALUES_LOGIN, VALIDATION_SCHEMA_LOGIN } from "../constants";
import { setTypeModal } from "../../store/actions";
import { getTypeModal } from "../../store/selectors";
import { getLoader } from "../../../../shared/Loader/store/selectors";
import login from "./utils/login";

import AuthHeader from "../AuthHeader";
import CloseBtn from "../CloseBtn";
import GoogleBtn from "../GoogleBtn";
import FacebookBtn from "../FacebookBtn";
import TextField from "../../../Common/TextField";
import FormButton from "../../../Common/Buttons/FormButton";
import alertToast from "../../../AlertToast";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoader());
  const typeModal = useSelector(getTypeModal());
  const [successStep, setSuccessStep] = useState(false);

  return (
    <div
      className={styles.login}
      style={{
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      <AuthHeader
        headerText={"Accedi"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
      />
      <CloseBtn />

      {/* FORMS */}
      <div className={styles.login__form}>
        {/* SOCIAL AUTH BTNS */}
        <div className={styles.login__form__social}>
          {/* GOOGLE BUTTON */}
          <GoogleBtn
            className={styles.login__form__social__btn}
            callback={login}
            setSuccessStep={setSuccessStep}
          />
          {/* FACEBOOK BUTTON */}
          <FacebookBtn
            className={styles.login__form__social__btn}
            classNameTwo={styles.facebook}
            callback={login}
            setSuccessStep={setSuccessStep}
          />
        </div>

        {/* DIVIDER */}
        <div className={styles.login__form__hr}>
          <span>Oppure</span>
        </div>

        {/* FORMIK FORM */}
        <div className={styles.login__form__formik}>
          <Formik
            initialValues={INITIAL_VALUES_LOGIN}
            validationSchema={VALIDATION_SCHEMA_LOGIN}
            onSubmit={async (values, actions) => {
              if (!values.emailLogin || !values.pwdLogin) {
                return alertToast(
                  "error",
                  "Compila tutti i campi",
                  "Si prega di compilare sia il campo email che password"
                );
              }

              login(
                dispatch,
                "",
                "",
                {
                  email: values.emailLogin,
                  pwd: values.pwdLogin,
                },
                setSuccessStep,
                navigate,
                typeModal
              );
            }}
          >
            {({ isSubmitting, errors, values }) => (
              <Form className={styles.form}>
                <div className={styles.form__field}>
                  <Field
                    name="emailLogin"
                    id="emailLogin"
                    label="Email"
                    component={TextField}
                    type="email"
                    height="40px"
                  />
                </div>
                <div className={styles.form__field}>
                  <Field
                    name="pwdLogin"
                    id="pwdLogin"
                    label="Password"
                    component={TextField}
                    type="password"
                    showPassword={true}
                    forgotPassword={true}
                    callback={() =>
                      dispatch(setTypeModal({ type: "forgotPwd" }))
                    }
                    height="40px"
                  />
                </div>

                <div className={styles.form__submit}>
                  <FormButton
                    successState={successStep}
                    title="Accedi"
                    disabled={
                      loading ||
                      isSubmitting ||
                      !values.emailLogin ||
                      !values.pwdLogin ||
                      Object.keys(errors).length > 0
                    }
                    type="submit"
                    width="100%"
                    height="45px"
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <div className={styles.login__form__login}>
            <div className={styles.login__form__login__cta}>
              Non hai un account?{" "}
              <span
                // href="#"
                onClick={() => {
                  dispatch(setTypeModal({ type: "signup" }));
                  // RESET signup FORM HERE
                }}
                tabIndex="0"
                onKeyPress={(e) =>
                  e.key === "Enter" &&
                  dispatch(setTypeModal({ type: "signup" }))
                }
              >
                Registrati
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
