import { useEffect, useMemo, useState } from "react";

import GroceryStoreMarker from "../../../../assets/svgs/map/shop-food.svg";
import SchoolMarker from "../../../../assets/svgs/map/school.svg";
import TransportMarkerTrain from "../../../../assets/svgs/map/transportTrain.svg";
import HospitalMarker from "../../../../assets/svgs/map/hospital.svg";
import farmaciayMaker from "../../../../assets/svgs/map/farmacia.svg";
import GreenSpace from "../../../../assets/svgs/map/green.svg";
import styles from "./ThirdPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import LeafletMap from "../../../Common/LeafletMap";
import "./ThirdPgLeaflet.scss";
import Pin from "../../../../assets/svgs/pin.svg";

const ThirdPg = ({ services, coords, services_GPT }) => {
  const values = useMemo(
    () => [
      {
        data_name: "supermarket",
        display_name: "Supermercati",
        icon: GroceryStoreMarker,
        color: "#E69C37",
      },
      {
        data_name: "school",
        display_name: "Scuole e Università",
        icon: SchoolMarker,
        color: "#29A5B3",
      },
      {
        data_name: "transports",
        display_name: "Trasporti",
        icon: TransportMarkerTrain,
        color: "#B3663B",
      },
      {
        data_name: "hospital",
        display_name: "Ospedali",
        icon: HospitalMarker,
        color: "#4581E2",
      },
      {
        data_name: "pharmacy",
        display_name: "Farmacie",
        icon: farmaciayMaker,
        color: "#008A15",
      },
      {
        data_name: "green_outdoors",
        display_name: "Spazi verdi",
        icon: GreenSpace,
        color: "#4E7425",
      },
    ],
    []
  );

  const [activeServices, setActiveServices] = useState();
  const [markers, setMarkers] = useState();

  useEffect(() => {
    const activeData = values
      ?.map((value) => {
        const obj = services?.[value.data_name];
        if (obj) {
          obj.serviceName = value.display_name;
          obj.icon = value.icon;
          const updatedServices = obj.services_found.map((service) => ({
            ...service,
            color: value.color,
          }));
          obj.services_found = updatedServices;
        }
        return obj;
      })
      .filter((item) => item?.services_found?.length);
    // console.log(activeData);
    setActiveServices(activeData);
    setMarkers(
      activeData
        .flatMap((item) => item.services_found)
        .filter((item) => item.distance <= 1000)
    );
  }, [values, services]);

  // const generateMapUrl = (lat, lng, markers) => {
  //   const markerParams = markers
  //     ?.map(
  //       (marker) =>
  //         `&markers=size:tiny%7Ccolor:${marker.color}|${marker.lat},${marker.lng}`
  //     )
  //     .join("&");
  //   const baseUrl = "https://maps.googleapis.com/maps/api/staticmap?";
  //   const params = `style=feature:poi|visibility:off&center=${lat},${lng}&markers=${markerParams}&markers=color:0x04A6B5%7C${lat},${lng}&size=400x400&key=AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw&style=saturation:-100&scale=2&maptype=roadmap&zoom=15`;
  //   return `${baseUrl}${params}`;
  // };

  // const [staticMap, setStaticMap] = useState("");

  // useEffect(() => {
  //   const lat = coords?.[0];
  //   const lng = coords?.[1];
  //   if (lat && lng) {
  //     const map = generateMapUrl(lat, lng, markers);
  //     setStaticMap(map);
  //   }
  // }, [coords, markers]);

  // Find the positions of the CHATGPT phrases
  var matchPuntiDiForza = services_GPT?.match(/Punti di forza:/i);
  var matchPuntiDeboli = services_GPT?.match(/Punti deboli:/i);

  var startOfFirstPart =
    matchPuntiDiForza?.index + matchPuntiDiForza?.[0]?.length;
  var startOfSecondPart =
    matchPuntiDeboli?.index + matchPuntiDeboli?.[0]?.length;

  // Extract the parts
  var firstPart =
    matchPuntiDiForza?.length && matchPuntiDeboli?.length
      ? services_GPT
          ?.substring(
            startOfFirstPart,
            startOfSecondPart - matchPuntiDeboli?.[0]?.length
          )
          .trim()
      : matchPuntiDiForza?.length
      ? services_GPT?.substring(startOfFirstPart).trim()
      : "";
  var secondPart =
    matchPuntiDeboli?.length &&
    services_GPT?.substring(startOfSecondPart).trim();
  // var secondPart = services_GPT
  //   ?.substring(startOfSecondPart + "Punti deboli:".length)
  //   .trim();

  // console.log(services_GPT, "services_GPT");
  // console.log(firstPart, "firstPart");
  // console.log(secondPart, "secondPart");

  // Insert line breaks before each number
  var regex = /\s+\d+\./g;
  firstPart = firstPart?.replace(regex, (match) => "\n" + match.trim());
  secondPart = secondPart?.replace(regex, (match) => "\n" + match.trim());
  // console.log(firstPart, "firstPart");

  // Define a function to convert newlines to <br> tags
  function addLineBreaks(string) {
    return string?.split("\n").map((line, index) => (
      <div
        key={index}
        className={stylesMain.page__text}
        style={{ lineHeight: "120%" }}
      >
        {line}
        {index !== string?.length - 1 && <br />}
      </div>
    ));
  }

  return (
    <div className={styles.area__services__wrapper} id="third_pg">
      {/* PAGE TITLE */}
      <div className={stylesMain.page__section_title}>Servizi di zona</div>
      {/* MAP AND DESCRIPTION */}
      <div className={styles.map_and_info}>
        <div className={styles.map}>
          {/* <img src={staticMap} alt="Map" /> */}
          {/* <span>500m</span> */}
          <LeafletMap
            lat={coords?.[0]}
            lng={coords?.[1]}
            //   svgIconHome={`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            //     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            //  <g>
            //    <g>
            //      <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
            //        c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
            //        C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
            //        s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z" fill="#078ea6"/>
            //    </g>
            //  </g>
            //  </svg>

            //     `}
            imgIconHome={Pin}
            svgIconMarkers={markers}
            isSvgElement={false}
            iconSize={[36, 36]}
            className="svg-icon-leaflet"
            zoom={15}
            radius={500}
          />
          <div className={styles.map__legend}>Raggio di 500m</div>
        </div>
        <div className={styles.info}>
          {firstPart && (
            <div className={styles.info__section}>
              <span>Punti di forza:</span> {addLineBreaks(firstPart)}
            </div>
          )}
          {secondPart && (
            <div className={styles.info__section}>
              <span>Punti deboli:</span> {addLineBreaks(secondPart)}
            </div>
          )}
        </div>
      </div>
      {/* SERVICES */}
      <div className={styles.services}>
        {activeServices?.map((item, index) => (
          <div key={index} className={styles.service}>
            <div className={styles.service_title}>
              <img src={item.icon} alt="service-logo" /> {item.serviceName}
            </div>
            <div className={styles.service_content}>
              <div className={styles.service_content_distance}>
                <p className={styles.service_content_distance_title}>km</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_distance_content}
                  >
                    {(serviceItem.distance / 1000).toFixed(2)}
                  </p>
                ))}
              </div>
              <div className={styles.service_content_name}>
                <p className={styles.service_content_name_title}>Nome</p>
                {item.services_found.map((serviceItem, index) => (
                  <p
                    key={index}
                    className={styles.service_content_name_content}
                  >
                    {serviceItem.name.substring(0, 25)}
                    {serviceItem.name.length > 25 && "..."}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThirdPg;
