import { useEffect, useState } from "react";

function useWindowSize(userWidth = 840) {
  const [isMobile, setIsMobile] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < userWidth) {
        if (!isMobile) {
          setIsMobile(true);
          setWindowHeight(window.innerHeight);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isMobile) {
          setIsMobile(false);
          setWindowHeight(window.innerHeight);
        }
      }
      setWindowWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return { isMobile, windowHeight, windowWidth };
}

export default useWindowSize;
