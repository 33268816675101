import { useRef } from "react";
import { useSelector } from "react-redux";
import clx from "classnames";
import { createPortal } from "react-dom";
import styles from "./Auth.module.scss";
import Login from "./Login";
import Signup from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import DataPreview from "./DataPreview";
import BuyModal from "./BuyModal";

import useAnimateModal from "../../../utils/useAnimateModal";
import FacebookSDKInit from "../../../utils/FacebookSDKInit";

import { getTypeModal } from "../store/selectors";

const Auth = ({ hasMinimumInfo }) => {
  const typeModal = useSelector(getTypeModal());
  const authOverlayRef = useRef(null);

  // Set display and opacity based on modalState
  // We want the modal to change the display but also to keep the opacity transition
  const { display, show } = useAnimateModal(typeModal.type, authOverlayRef);

  return createPortal(
    <div
      className={clx(styles.auth_overlay, { [styles.show]: show })}
      style={{ display: display ? "flex" : "none" }}
      ref={authOverlayRef}
    >
      {typeModal.type == "signup" && <Signup />}
      {typeModal.type == "login" && <Login />}
      {typeModal.type == "forgotPwd" && <ForgotPassword />}
      {typeModal.type == "buy" && <BuyModal hasMinimumInfo={hasMinimumInfo} />}
      {typeModal.type == "card" && (
        <DataPreview hasMinimumInfo={hasMinimumInfo} />
      )}

      <FacebookSDKInit appId={process.env.REACT_APP_FACEBOOK_APP_ID} />
    </div>,
    document.getElementById("modal-root")
  );
};

export default Auth;
