// Scroll to section function for sidebar, closes sidebar and scrolls to section
const closeSideAndScroll = (section, toggleSidebar) => {
  // "Producing Code" (May take some time)
  let myPromise = new Promise(function (resolve, reject) {
    toggleSidebar();
    resolve("Successful"); // when successful
  });

  // "Consuming Code" (Must wait for a fulfilled Promise)
  myPromise.then(() => {
    setTimeout(() => {
      window.scrollTo({
        top: section?.current?.offsetTop,
        behavior: "smooth",
      });
    }, 1200);
  });
};

export default closeSideAndScroll;
