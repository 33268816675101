import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./LeafletMap.scss";

const LeafletMap = ({
  lat,
  lng,
  svgIconHome,
  svgIconMarkers,
  isSvgElement = true,
  imgIconHome,
  iconSize,
  className,
  zoom,
  url = "",
  radius = 0,
}) => {
  const [mapCoords, setMapCoords] = useState(null);
  useEffect(() => {
    if (lat && lng) setMapCoords([lat, lng]);
  }, [lat, lng]);

  const customIconHome = !imgIconHome
    ? L.divIcon({
        html: svgIconHome,
        className,
        iconSize,
      })
    : L.icon({
        iconUrl: imgIconHome,
        iconSize, // size of the icon
      });

  return mapCoords ? (
    <MapContainer
      center={[mapCoords[0], mapCoords[1]]}
      zoom={zoom}
      zoomControl={false}
      boxZoom={false}
      dragging={false}
      doubleClickZoom={false}
      scrollWheelZoom={false}
    >
      <TileLayer
        url={url || "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"}
      />
      <Marker position={[mapCoords[0], mapCoords[1]]} icon={customIconHome} />
      {svgIconMarkers?.map((marker, index) => (
        <Marker
          key={index}
          position={[marker?.lat, marker?.lng]}
          icon={L.divIcon({
            html: isSvgElement
              ? marker?.icon
              : `<div style="background-color: ${marker?.color}" class="dot"></div>`,
            className,
            iconSize,
          })}
        />
      ))}
      {radius && (
        <Circle center={[mapCoords[0], mapCoords[1]]} radius={radius} />
      )}
    </MapContainer>
  ) : null;
};

export default LeafletMap;
