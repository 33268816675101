import clx from "classnames";
import MaleFemaleIcon from "../../../../../assets/svgs/male-female.svg";
import chartStyles from "../../../../Common/Charts/Charts.module.scss";
import CircleProgressBar from "../../../../Common/Charts/CircleProgressBar";
import styles from "./GenderDistributionArea.module.scss";

const GenderDistributionArea = ({ data, marginTop, minHeight }) => {
  // console.log(data);
  const roundFemale = Math.round(data?.data?.cat_area[1] * 100);
  // const roundMale = Math.round(male * 100);

  return (
    <div
      className={`${styles.gender__distribution__area__wrapper} ${chartStyles.chart__wrapper}`}
      style={{ minHeight }}
    >
      <b style={{ fontSize: 15 }}>Distribuzione genere</b>
      <div className={styles.circle__wrapper} style={{ marginTop }}>
        <CircleProgressBar
          percentage={roundFemale}
          colour={"#00B1C5"}
          radius={100}
          disableColour={"#BFEEF3"}
          icon={MaleFemaleIcon}
          label={["Uomo", "Donna"]}
        />
      </div>
      <p
        className={clx(chartStyles.chart__source, styles.chart__source)}
        style={{
          position: "absolute",
          bottom: "20px",
          right: "30px",
        }}
      >
        {data?.description}
      </p>
    </div>
  );
};

export default GenderDistributionArea;
