import styles from "./CommonCard.module.scss";

const CommonCard = ({
  header,
  description,
  content,
  icon: Icon,
  marginTop,
  fontSize,
  titleSize,
}) => (
  <div style={{ position: "relative" }}>
    <div className={styles.common__card__wrapper}>
      <div className={styles.first__row}>
        <b className={styles.header} style={{ fontSize: titleSize }}>
          {header}
        </b>
      </div>
      <p className={styles.content} style={{ marginTop, fontSize }}>
        {content}
      </p>
      {description && <p className={styles.chart__source}>{description}</p>}
    </div>
  </div>
);

export default CommonCard;
