import "./AreaChart.scss";

import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";

const Index = ({
  title,
  description,
  limit,
  label,
  dx,
  marginLeft,
  chartData,
  labelAdditionalContent,
  minHeight,
  wrapperHeight,
  footerTop,
  locale,
  paddingTop,
  chartMarginTop,
  sourcePosition,
  sourceRight,
  sourceBottom,
}) => (
  <div
    className={chartStyles.chart__wrapper}
    style={{ minHeight: wrapperHeight, paddingTop }}
  >
    <b style={{ fontSize: "15px" }}>{title}</b>
    <div
      className={chartStyles.chart__container}
      style={{ marginTop: chartMarginTop || "30px" }}
    >
      <ResponsiveContainer
        minHeight={minHeight || 410}
        minWidth={420}
        height="80%"
      >
        <AreaChart data={chartData} margin={{ top: 40, left: marginLeft }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#00B1C5"} stopOpacity={0.8} />
              <stop offset="95%" stopColor={"#00B1C5"} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            tick={
              <ChartsAxisLabel dy={10} textAnchor={"middle"} locale={locale} />
            }
          />
          <YAxis
            label={<ChartsLabel label={label} dy={10} dx={0} />}
            tick={<ChartsAxisLabel dy={3.5} textAnchor={"end"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
            domain={limit ? [limit[1], limit[0]] : [0, "auto"]}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            content={
              <ChartsTooltip
                type={"areaChart"}
                labelAdditionalContent={labelAdditionalContent}
              />
            }
          />
          <Area
            type="monotone"
            dataKey={chartData && Object.keys(chartData[0])[1]}
            stroke={"#00B1C5"}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    <p
      className={chartStyles.chart__source}
      style={{
        marginTop: footerTop,
        position: sourcePosition,
        right: sourceRight,
        bottom: sourceBottom,
      }}
    >
      {description}
    </p>
  </div>
);

export default Index;

/* <linearGradient id="colorPv" x1="0" y1="0"
	x2="0" y2="1">
	<stop offset="5%" stopColor={'#11606D'} stopOpacity={0.8}/>
	<stop offset="95%" stopColor={'#11606D'} stopOpacity={0}/>
</linearGradient> */

/* <Legend verticalAlign="top" align="right" height={50} width={'fit-content'} /> */
/* <Area type="monotone" dataKey="Donna" stroke={'#11606D'}
							fillOpacity={1} fill="url(#colorPv)" /> */
