import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../containers/Common/Spinner";

export default () => {
  const { loading, isModalLoader } = useSelector((state) => state.loader);

  if (loading && isModalLoader) {
    return <Spinner />;
  } else {
    return null;
  }
};
