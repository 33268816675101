import { motion } from "framer-motion";
import "./ImageList.scss";

import AuthSvgProp from "../../../../assets/svgs/auth-svg-prop.svg";
import AuthSvgLazio from "../../../../assets/svgs/auth-svg-lazio.svg";
import AuthSvgLuiss from "../../../../assets/svgs/auth-svg-luiss.svg";
import AuthSvgHomepal from "../../../../assets/svgs/auth-svg-homepal.svg";
import AuthSvgPlanet from "../../../../assets/svgs/auth-svg-planet.svg";

const ImageList = ({ className, rightHOCItem, leftHOCItem }) => {
  return (
    <motion.div className={className} variants={rightHOCItem || leftHOCItem}>
      <img src={AuthSvgProp} alt="Auth" className="first_img" />
      <img src={AuthSvgLazio} alt="Auth" className="second_img" />
      <img src={AuthSvgLuiss} alt="Auth" className="third_img" />
      <img src={AuthSvgHomepal} alt="Auth" className="fourth_img" />
      <img src={AuthSvgPlanet} alt="Auth" className="fifth_img" />
    </motion.div>
  );
};

export default ImageList;
