import styles from "./Footer.module.scss";
import { useState } from "react";
import ContactUsModal from "./ContactUsModal";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import HpLogo from "../../../assets/svgs/final-logo.svg";
import Facebook from "../../../assets/svgs/facebook.svg";
import Linkedin from "../../../assets/svgs/linkedin.svg";
import Youtube from "../../../assets/svgs/youtube.svg";
import ArrowUp from "../../../assets/svgs/up-arrow-new.svg";
import Email from "../../../assets/svgs/email.svg";

const Footer = ({
  footerRef,
  // setAuthModal,
  // setBuyModal,
  // isFromBakeca,
  // hasMinimumInfo,
}) => {
  // const isUserLoggedin = useSelector(getAuth());
  const [modalState, setModalState] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={styles.footer__container} ref={footerRef}>
      <div className={styles.footer}>
        {/* INFO */}
        <div className={styles.footer__info}>
          <img src={HpLogo} alt="logo" className={styles.footer__info__logo} />
          <div className={styles.footer__info__name}>
            © HOUSEPLUS INNOVATION s.r.l
          </div>
          <div className={styles.footer__info__iva}> P.IVA 01849040702</div>
          <div className={styles.footer__info__social}>
            <a
              href="https://www.facebook.com/houseplus.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/houseplusitalia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Linkedin} alt="linkedin" />
            </a>
            <a
              href="https://www.youtube.com/@housepluspredictiveAI"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.youtube}
            >
              <img src={Youtube} alt="youtube" />
            </a>
          </div>
        </div>

        {/* LINKS */}
        <ul className={styles.footer__links}>
          <li>
            <a href="https://www.iubenda.com/privacy-policy/24329777">
              Privacy
            </a>
          </li>
          <li>
            <a
              href="https://uploads-ssl.webflow.com/6377c6cc4ffcbe580af46c7f/654d304fe946c0ade97232ab_termini-e-condizioni.pdf"
              target="_blank"
              className={styles.hero_banner_text_subheader}
            >
              Termini e condizioni
            </a>
          </li>
        </ul>

        {/* CTA */}
        <div className={styles.footer__cta}>
          <div className={styles.footer__cta__text}>
            La tua casa è importante. Prendi decisioni più informate.
          </div>
          {/* <PrimaryButton
            text="Acquista la tua analisi"
            width={"100%"}
            onClick={() => {
              if (isUserLoggedin) {
                setBuyModal(true);
              } else {
                setAuthModal(true);
              }
            }}
            disabled={!isFromBakeca || !hasMinimumInfo}
          /> */}
          <div className={styles.footer__cta__contact_us}>
            <SecondaryButton
              text="Contattaci"
              width={"100%"}
              height={45}
              color={"#078ea6"}
              borderColor={"#078ea6"}
              onClick={() => setModalState(true)}
              leftIconSrc={Email}
              iconHeight={"auto"}
            />
          </div>

          {/* CONTACT US MODAL */}
          <ContactUsModal
            modalState={modalState}
            setModalState={setModalState}
          />
        </div>
      </div>

      {/* BACK TO TOP BUTTON */}
      <div className={styles.btt}>
        <SecondaryButton
          text="Torna su"
          rightIconSrc={ArrowUp}
          onClick={() => scrollToTop()}
          className={styles.btt__button}
        ></SecondaryButton>
      </div>
    </footer>
  );
};

export default Footer;
