import { types } from "./actions";

const initialState = {
  error: null,
  credits: [],
  transactions: [],
  userReports: {},
  report: {},
  teaser: {},
  reviews: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_USER_CREDITS.SUCCESS:
      return {
        ...state,
        credits: action.payload,
      };
    case types.GET_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case types.GET_REPORTS.SUCCESS:
      return {
        ...state,
        userReports: action.payload,
      };
    case types.GET_REPORT.SUCCESS:
      return {
        ...state,
        report: {
          ...action.payload,
        },
      };
    case types.GET_TEASER.SUCCESS:
      return {
        ...state,
        teaser: {
          ...action.payload,
        },
      };
    case types.GET_REVIEWS.SUCCESS:
      return {
        ...state,
        reviews: action.payload,
      };
    default:
      return { ...state };
  }
};
