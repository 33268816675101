import clx from "classnames";
import { useSelector } from "react-redux";
import styles from "./InfoCards.module.scss";
import { motion } from "framer-motion";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "./swiper.scss";
// import required swiper modules
import { EffectCards, Pagination, Navigation } from "swiper/modules";

import { getTeaserData } from "../../../Dashboard/store/selectors";
import { getDirection } from "../../store/selectors";

import Card from "./Card";
import Omi from "./Charts/omi";
import PopulationDensity from "./Charts/populationDensity";
import GenderDistribution from "./Charts/genderDistribution";
import AdsAmount from "./Charts/adsAmount";

const InfoCards = ({ hasMinimumInfo }) => {
  // const dispatch = useDispatch();
  const teaserData = useSelector(getTeaserData());
  // console.log(teaserData, "teaserData");
  const isScrollingDown = useSelector(getDirection());

  const teaserDataObj = [
    {
      title: (
        <p className={styles.card_title}>
          Prezzo medio <br /> immobili al m²
        </p>
      ),
      chart: (
        <Omi
          min_omi={teaserData?.area_stats?.min_price_mq}
          max_omi={teaserData?.area_stats?.max_price_mq}
        />
      ),
      gap: "50px",
    },
    {
      title: (
        <p className={styles.card_title}>
          Densita' della <br />
          popolazione
        </p>
      ),
      chart: (
        <PopulationDensity
          population_density={teaserData?.charts?.info?.graphs?.[7]}
          bakecaColorFull={"#567a1f"}
          bakecaColorEmpty={"#a6bea250"}
        />
      ),
      gap: "23px",
    },
    {
      title: (
        <p className={styles.card_title}>
          Distribuzione <br />
          di genere
        </p>
      ),
      chart: (
        <GenderDistribution
          gender_distribution={teaserData?.charts?.info?.graphs?.[3]}
        />
      ),
      gap: "20px",
    },
    {
      title: (
        <p className={styles.card_title}>
          N. di annunci <br />
          simili in zona
        </p>
      ),
      chart: <AdsAmount ads_amount={teaserData?.area_stats?.num_properties} />,
      gap: "50px",
    },
    {
      isBuy: true,
    },
  ];

  return (
    <div
      className={clx(styles.info__cards, {
        [styles.isNotFromBakeca]: !hasMinimumInfo,
      })}
    >
      {/* TITLE */}
      {teaserData?.address_info?.county || teaserData?.address_info?.city ? (
        <motion.h4
          className={styles.info__cards_header}
          initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          viewport={{ margin: "-50px" }}
        >
          Ecco alcune statistiche utili relative a{" "}
          <b>
            {teaserData?.address_info?.county || teaserData?.address_info?.city}
          </b>{" "}
          che potrai trovare nella nostra analisi
        </motion.h4>
      ) : (
        <motion.h4
          className={styles.info__cards_header}
          initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          viewport={{ margin: "-50px" }}
        >
          Alcune informazioni che forniamo ai nostri clienti
        </motion.h4>
      )}
      {/* CARDS DESKTOP */}
      <div className={styles.info__cards__sliders}>
        <motion.div
          className={styles.info__cards__sliders__desktop}
          initial={{ opacity: 0, y: isScrollingDown ? -50 : 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut", delay: 0.2 },
          }}
          viewport={{ margin: "-50px" }}
        >
          {teaserDataObj.map((card, index) => (
            <Card
              key={`town-card-${index}`}
              isScrollingDown={isScrollingDown}
              cardTitle={card.title}
              isBuy={card.isBuy}
              gap={card.gap}
              sampleImg={card.sampleImg}
            >
              {card.chart}
            </Card>
          ))}
        </motion.div>
        {/* CARDS TABLET */}
        <motion.div
          className={clx(styles.info__cards__sliders__tablet, "cards-tablet")}
          initial={{ opacity: 0, y: isScrollingDown ? -50 : 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut", delay: 0.2 },
          }}
          viewport={{ margin: "-50px" }}
        >
          <Swiper
            slidesPerView={2}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            grabCursor={true}
            breakpoints={{
              // when window width is >= 740px
              800: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
            }}
          >
            {teaserDataObj.map((card, index) => (
              <SwiperSlide key={`town-card-${index}`}>
                <Card
                  key={`town-card-${index}`}
                  isScrollingDown={isScrollingDown}
                  cardTitle={card.title}
                  isBuy={card.isBuy}
                  gap={card.gap}
                  sampleImg={card.sampleImg}
                >
                  {card.chart}
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
        {/* CARDS MOBILE */}
        <motion.div
          className={clx(styles.info__cards__sliders__mobile, "cards-mobile")}
          initial={{ opacity: 0, y: isScrollingDown ? -50 : 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut", delay: 0.2 },
          }}
          viewport={{ margin: "-50px" }}
        >
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards, Pagination]}
            pagination={{
              clickable: true,
            }}
          >
            {teaserDataObj.map((card, index) => (
              <SwiperSlide key={`town-card-${index}`}>
                <Card
                  key={`town-card-${index}`}
                  isScrollingDown={isScrollingDown}
                  cardTitle={card.title}
                  isBuy={card.isBuy}
                  gap={card.gap}
                  sampleImg={card.sampleImg}
                >
                  {card.chart}
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </div>
    </div>
  );
};

export default InfoCards;
