import React from "react";
import styles from "./AuthHeader.module.scss";

const AuthHeader = ({
  headerText,
  subheaderText,
  paddingLeft,
  paddingRight,
  marginBottom,
  width,
}) => {
  return (
    <div
      className={styles.auth__header}
      style={{ paddingLeft, paddingRight, marginBottom, width }}
    >
      <h3 className={styles.auth__header__large}>{headerText}</h3>
      {subheaderText && (
        <p className={styles.auth__header__small}>{subheaderText}</p>
      )}
    </div>
  );
};

export default AuthHeader;
