import clx from "classnames";
import { useEffect, useState } from "react";
import styles from "./SecondPgAdditional.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import LeafletMap from "../../../Common/LeafletMap";
import "./SecondPgLeaflet.scss";
import Pin from "../../../../assets/svgs/pin.svg";

const SecondPgAdditional = ({ summary_info, photos, coords, description }) => {
  // STATIC MAP IMAGE
  // const [staticMap, setStaticMap] = useState("");
  // const getStaticMap = (lat, lng) =>
  //   `https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=712x225&markers=color:0x04A6B5%7C${lat},${lng}&key=AIzaSyDhU_uaWxC4hWjLecwUvBW7RzqauLXfpVw&style=saturation:-100&scale=2&maptype=roadmap`;

  // useEffect(() => {
  //   const lat = coords?.[0],
  //     lng = coords?.[1];
  //   if (lat && lng) {
  //     const map = getStaticMap(lat, lng);
  //     setStaticMap(map);
  //   }
  // }, [coords]);

  const [parsedPhotos, setParsedPhotos] = useState([]);

  useEffect(() => {
    if (typeof photos === "string") {
      try {
        setParsedPhotos(JSON.parse(photos));
      } catch (error) {
        // console.log(error, "error parsing photos from mysql");
      }
    } else {
      setParsedPhotos(photos);
    }
  }, [photos]);

  return (
    <div className={styles.second_page_additional} id="second_page">
      {/* MAIN INFO */}
      <div className={styles.main_info}>
        {/* TITLE */}
        <div className={clx(stylesMain.page__section_title, styles.title)}>
          Informazioni di sintesi
        </div>
        {/* INFO */}
        <div className={styles.main_info__info}>
          {Object.keys(summary_info)?.map((info, index) => (
            <div className={styles.info} key={`sintesi-${index}`}>
              <div className={styles.item_circle} />
              <div className={stylesMain.page__text}>
                {info}:{" "}
                <span className={stylesMain.page__text}>
                  {summary_info[info]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* AD DESCRIPTION */}
      <div className={styles.ad_description}>
        {/* TITLE */}
        <div className={clx(stylesMain.page__section_title, styles.title)}>
          Descrizione annuncio
        </div>
        {/* DESCRIPTION */}
        <div className={clx(stylesMain.page__text, styles.description)}>
          {description
            ? description?.length > 700
              ? `${description?.slice(0, 700)}...`
              : description
            : "L'annuncio non contiene una descrizione"}
        </div>
      </div>

      {/* PHOTOS */}
      <div className={styles.photos}>
        {/* TITLE */}
        <div className={clx(stylesMain.page__section_title, styles.title)}>
          Fotografie dell'immobile
        </div>
        {/* PHOTOS */}
        <div
          className={styles.photos__container}
          style={{ margin: parsedPhotos?.length == 1 && "0" }}
        >
          {parsedPhotos?.length >= 1 ? (
            parsedPhotos
              ?.slice(0, 3)
              ?.map((photo, index) => (
                <img src={photo} alt="property" key={`photo-${index}`} />
              ))
          ) : (
            <div className={stylesMain.page__text}>
              L'annuncio non contiene fotografie
            </div>
          )}
        </div>
      </div>

      {/* LOCATION */}
      <div className={styles.location}>
        {/* TITLE */}
        <div className={stylesMain.page__section_title}>Posizione</div>
        {/* MAP */}
        {coords?.[0] && coords?.[1] ? (
          <LeafletMap
            lat={coords?.[0]}
            lng={coords?.[1]}
            //           svgIconHome={`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            //    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            // <g>
            //   <g>
            //     <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
            //       c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
            //       C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
            //       s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z" fill="#078ea6"/>
            //   </g>
            // </g>
            // </svg>

            //    `}
            imgIconHome={Pin}
            iconSize={[36, 36]}
            className="svg-icon-leaflet"
            zoom={15}
          />
        ) : (
          <div className={stylesMain.page__text}>
            L'annuncio non contiene coordinate
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondPgAdditional;
