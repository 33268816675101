import * as constants from "./constants";

export const startLoader = (isModalLoader) => ({
  type: constants.START_LOADER,
  payload: {
    isModalLoader: isModalLoader,
  },
});
export const stopLoader = (isModalLoader) => ({
  type: constants.STOP_LOADER,
  payload: {
    isModalLoader: isModalLoader,
  },
});
