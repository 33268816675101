import styles from "./CheckboxField.module.scss";
import Exclamation from "../../../assets/svgs/Exclamation.svg";

const CheckboxField = ({
  field,
  id,
  label,
  form,
  labelStyle,
  paddingBottom,
  marginBottom,
  padding,
  gap,
  errorMargin,
  className,
  disabled,
  ...props
}) => {
  const isError = form.touched[field.name] && form.errors[field.name];

  return (
    <div style={{ width: "100%" }} className={className}>
      <label
        className={styles["checkbox__wrapper"]}
        style={{ padding, gap, marginBottom }}
      >
        <input
          {...field}
          id={id}
          type="checkbox"
          value={id}
          checked={field.value} // control checked state
          className={styles["checkbox__field"]}
          disabled={disabled}
          {...props}
        />
        <span style={{ ...labelStyle }}>{label}</span>
      </label>
      {isError && (
        <div className={styles.error} style={{ marginTop: errorMargin }}>
          <img src={Exclamation} alt="error" /> {form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default CheckboxField;
