const ChartsLabel = ({
	x, y, stroke, value, offset, dy, textAnchor, dx, label,
}) =>
	 (
		<g transform={`translate(${x},${y})`}>
			<text x={x} y={y} dy={dy}
				dx={dx}
				offset={offset}
				textAnchor={textAnchor}
				fill={process.env.REACT_APP_PLATFORM == 'MONDORED' ? '#370a0e' : '#034349'} style={{ fontSize: 14 }}>
				{label}
			</text>
		</g>
	)
;

export default ChartsLabel;