export const heroText = {
  visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
  hidden: { opacity: 0 },
};

export const heroTextItem = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.35, ease: "easeOut" },
  },
  hidden: { opacity: 0, y: 40 },
};

export const heroImages = {
  visible: { opacity: 1, transition: { staggerChildren: 0.4 } },
  hidden: { opacity: 0 },
};

export const heroImageMain = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.7, ease: "easeOut" },
  },
  hidden: { opacity: 0, x: 20 },
};

export const heroImageSmall = {
  visible: {
    opacity: 1,
    transition: { duration: 0.7, ease: "easeOut" },
  },
  hidden: { opacity: 0 },
};
