import styles from "./Reviews.module.scss";
// import { motion } from "framer-motion";
// import TestimonialOne from "../../../assets/images/testimonial-one.png";
// import Google from "../../../assets/images/google.png";
// import ReviewCard from "./ReviewCard";
import MediaBanner from "./MediaBanner";

const Reviews = ({ reviewsRef }) => {
  // const trustRef = useRef(null);
  // const dispatch = useDispatch();

  // console.log(
  //   useSelector((state) => state.reviews),
  //   "reviews"
  // );

  // LOAD TRUSTPILOT WIDGET
  // useEffect(() => {
  //   if (window.Trustpilot) {
  //     window.Trustpilot.loadFromElement(trustRef.current, true);
  //   }
  // }, []);

  // GET REVIEWS FROM GOOGLE
  // useEffect(() => {
  //   console.log("sdsdsdsdsdsds");
  //   dispatch(actions.GET_REVIEWS.REQUEST());
  // }, []);

  // const reviewOne = {
  //   name: "Andrea Lacalamita",
  //   avatar: TestimonialOne,
  //   credentials: "Fondatore Homepal",
  //   comment:
  //     "Crediamo che il futuro del mercato immobiliare siano i dati, la capacità di raccoglierli, di interpretarli e di metterli al servizio dell’ecosistema.",
  // };
  // const reviewTwo = {
  //   name: "Andrea Lacalamita",
  //   avatar: TestimonialOne,
  //   credentials: "Fondatore Homepal",
  //   comment:
  //     "Crediamo che il futuro del mercato immobiliare siano i dati, la capacità di raccoglierli, di interpretarli e di metterli al servizio dell’ecosistema.",
  // };

  return (
    <section className={styles.reviews} ref={reviewsRef}>
      {/* TRUSTPILOT */}
      {/* <motion.div
          initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          className="trustpilot-widget"
          data-locale="it-IT"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="636926d9d7b1485a9f90a6e6"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="dark"
          data-min-review-count="10"
          data-style-alignment="center"
          ref={trustRef}
        >
          <a
            href="https://it.trustpilot.com/review/houseplus.it"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </motion.div> */}
      {/* <motion.h1
          initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
        >
          Testimonial
        </motion.h1>
        <div className={styles.reviews__cards}>
          <div className={styles.reviews__cards__google}>
            <div className={styles.reviews__cards__google__logos}>
              <div className={styles.reviews__cards__google__logos__logo}>
                <img src={Google} alt="google logo" />
              </div>
            </div>
            <div className={styles.reviews__cards__google__logos}>
              <div className={styles.reviews__cards__google__logos__logo}>
                <img src={Google} alt="google logo" />
              </div>
            </div>
          </div>
          <ReviewCard review={reviewOne} />
          <ReviewCard review={reviewTwo} />
        </div> */}
      <MediaBanner />
    </section>
  );
};

export default Reviews;
