import AreaChart from "../../../../Common/Charts/AreaChart";

const PopulationChange = ({
  data,
  minHeight,
  wrapperHeight,
  locale,
  paddingTop,
  chartMarginTop,
  footerTop,
}) => {
  // console.log(data);
  const chartData = data?.data?.x?.map((item, index) => ({
    x: item,
    Popolazione: data?.data?.y[index],
  }));
  // console.log(chartData);

  return (
    <div>
      <AreaChart
        title={data?.title}
        description={data?.description}
        limit={data?.data?.ylimits}
        label={data?.data?.ytitle}
        dx={35}
        marginLeft={10}
        wrapperHeight={wrapperHeight}
        minHeight={minHeight}
        chartData={chartData}
        locale={locale}
        paddingTop={paddingTop}
        chartMarginTop={chartMarginTop}
        footerTop={footerTop}
      />
    </div>
  );
};

export default PopulationChange;
