import styles from "./omi.module.scss";
import formatToEuro from "../../../../../../utils/formatToEuro";

// Omi landing chart
const omi = ({ min_omi, max_omi }) => {
  return (
    <div className={styles.omi}>
      {/* MAX */}
      <div className={styles.omi__max}>
        <div className={styles.number}>€ {formatToEuro(max_omi || 3500)}</div>
        <div className={styles.text}>Max.</div>
      </div>
      {/* MIN */}
      <div className={styles.omi__min}>
        <div className={styles.number}>€ {formatToEuro(min_omi || 1700)}</div>
        <div className={styles.text}>Min.</div>
      </div>
    </div>
  );
};

export default omi;
