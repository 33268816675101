import styles from "./FirstPg.module.scss";

const FirstPg = ({ houseAddress, creationDate }) => {
  return (
    <div className={styles.first_page_wrapper}>
      <div className={styles.first_page_img} />
      {houseAddress && (
        <div className={styles.first_page_header}>
          <div className={styles.first_page_header_title}>
            Valutazione immobile
          </div>
          <div className={styles.first_page_header_address}>{houseAddress}</div>
          {creationDate && (
            <div className={styles.first_page_header_date}>
              Stima realizzata il {creationDate?.split("T")[0]}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FirstPg;
