const formatToEuro = (value) => {
  // Remove all non digits
  var removeChar = String(value)?.replace(/\D/g, "");

  // Format number to Euro
  let euro = Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return euro.format(removeChar);
};

export default formatToEuro;
