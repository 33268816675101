import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLoader } from "../../../../../shared/Loader/store/selectors";
import styles from "./Stripe.module.scss";
import alertToast from "../../../../AlertToast";
import FormButton from "../../../../Common/Buttons/FormButton";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Stripe = ({ createReport, successStep, setSuccessStep }) => {
  const loading = useSelector(getLoader());

  // Use Stripe for payment processing
  const stripe = useStripe();
  const elements = useElements();
  //   console.log("stripe", stripe);
  //   console.log("elements", elements);
  //   console.log("CardElement", CardElement);

  // Establish whether Stripe card form is complete in order to enable submit button
  const [isCardComplete, setIsCardComplete] = useState(false);
  // console.log("isCardComplete", isCardComplete);

  // Wait for stripe to have loaded and attach event listener to card element to check if card form is complete
  useEffect(() => {
    if (stripe && elements) {
      const card = elements.getElement(CardElement);
      if (card) {
        // console.log("stripe", stripe);
        const handleCardChange = (event) => {
          //   console.log("event", stripe event);
          setIsCardComplete(event.complete);
        };

        card.on("change", handleCardChange);

        return () => {
          card.off("change", handleCardChange);
        };
      }
    }
  }, [elements, stripe]);

  // Handle stripe payment
  const handleStripeSubmit = async (e) => {
    e.preventDefault();

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      alertToast(
        "warning",
        "Stripe non e` stato ancora caricato",
        "attendi qualche secondo e riprova"
      );
      setSuccessStep(false);
      return;
    }

    // Take payment details and send payment method id along with amount of credits to backend to be handled
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    let paymentData;

    // console.log("paymentMethod", paymentMethod);

    if (!error) {
      paymentData = {
        id: paymentMethod.id,
        amount: 4.88,
        currency: "eur",
      };

      // Create report with stripe payment data
      createReport("stripe", paymentData);
    } else {
      alertToast(
        "error",
        "Errore durante il pagamento",
        "C'e` stato un errore durante il pagamento da parte di Stripe. Riprova piu` tardi oppure contatta il team Houseplus che risolvera' il problema al piu` presto"
      );
      setSuccessStep(false);
    }
  };

  //   if (!stripe || !elements) return null;
  return (
    <div className={styles.stripe}>
      <CardElement className={styles.stripe_element} />

      <FormButton
        title="Procedi con il pagamento e genera report"
        callback={handleStripeSubmit}
        width="100%"
        height="45px"
        disabled={!isCardComplete || loading}
        successState={successStep}
        loading={loading}
      />
    </div>
  );
};

export default Stripe;
