import { forwardRef } from "react";
import HpLogo from "../../../../assets/images/hp_logo.png";
import styles from "../../DownloadPdf.module.scss";
import clx from "classnames";

// Higher Order Component wrapper for the pages
const Index = forwardRef(({ children, height, visibleClass }, ref) => (
  <div
    ref={ref}
    className={`${styles.page} ${visibleClass || ""}`}
    style={{ height }}
  >
    <div className={styles.page__logo}>
      <img src={HpLogo} alt="header-logo" />
    </div>
    {children}
    <div className={styles.page__footer}>
      <p className={clx(styles.footer__number, "footer__number")}>1</p>
    </div>
  </div>
));

export default Index;
