import { call, put, takeLatest } from "redux-saga/effects";

import { Api } from "../../../entries/ApiTransport";
import sagaAssessor from "../../../utils/sagaAssessor";
import { actions, types } from "./actions";

const api = Api.getInstance();

const signUp = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // console.log("payload signup", payload);
        const { data } = yield call(() => api.post(`/auth/signUp`, payload));
        yield put(actions.SIGN_UP.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const signIn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // console.log("payload login", payload);
        const { data } = yield call(() => api.post(`/auth/login`, payload));

        localStorage.setItem("jwtTokenBakeca", data.token);
        yield put(actions.SIGN_IN.SUCCESS(data.token));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const signUpGoogle = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // console.log("payload signup google", payload);
        const { data } = yield call(() =>
          api.post(`/auth/signUpGoogle`, payload)
        );

        localStorage.setItem("jwtTokenBakeca", data.token);
        yield put(actions.SIGN_UP_GOOGLE.SUCCESS(data.token));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const signUpFacebook = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/auth/signupFacebook`, payload)
        );
        yield put(actions.SIGN_UP.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const signInGoogle = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // console.log("payload login google", payload);
        const { data } = yield call(() =>
          api.post(`/auth/loginGoogle`, payload)
        );

        localStorage.setItem("jwtTokenBakeca", data.token);
        yield put(actions.SIGN_IN.SUCCESS(data.token));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const signInFacebook = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post(`/auth/loginFacebook`, payload)
        );

        localStorage.setItem("jwtTokenBakeca", data.token);
        yield put(actions.SIGN_IN.SUCCESS(data.token));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.SIGN_IN.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const logOut = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        localStorage.removeItem("jwtTokenBakeca");
        Object.keys(localStorage)
          .filter(
            (item) =>
              item.includes("searchText") ||
              item.includes("filterList") ||
              item.includes("sortingOptions")
          )
          .forEach((item) => {
            localStorage.removeItem(`${item}`);
          });
        yield put(actions.LOG_OUT.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.LOG_OUT.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const getUser = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get("/auth/user"));
        yield put(actions.FETCH_USER.SUCCESS(data.data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.FETCH_USER.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const forgotPassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post("/auth/forgotPassword", payload)
        );
        yield put(actions.FORGOT_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.FORGOT_PASSWORD.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const changePassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post("/user/changePassword", payload)
        );
        yield put(actions.CHANGE_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.CHANGE_PASSWORD.FAILED(err)
  );

const resetPassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.put("/auth/forgotPassword", payload)
        );
        yield put(actions.RESET_PASSWORD.SUCCESS());
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.RESET_PASSWORD.FAILED(err)
  );

const editAccount = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // console.log("payload edit account", payload);
        const { data } = yield call(() => api.put("/auth/user", payload));
        yield put(actions.EDIT_ACCOUNT.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.EDIT_ACCOUNT.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

const deleteAccount = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.delete(`/auth/user`));
        yield put(actions.DELETE_ACCOUNT.SUCCESS(data));
        callback && typeof callback === "function" && callback();
      },
    (err) => actions.DELETE_ACCOUNT.FAILED(err),
    false // DETERMINES IF TO SHOW LOADER MODAL
  );

export default function* () {
  yield takeLatest(types.SIGN_UP.REQUEST, signUp);
  yield takeLatest(types.SIGN_UP_GOOGLE.REQUEST, signUpGoogle);
  yield takeLatest(types.SIGN_UP_FACEBOOK.REQUEST, signUpFacebook);
  yield takeLatest(types.SIGN_IN.REQUEST, signIn);
  yield takeLatest(types.SIGN_IN_GOOGLE.REQUEST, signInGoogle);
  yield takeLatest(types.SIGN_IN_FACEBOOK.REQUEST, signInFacebook);
  yield takeLatest(types.FETCH_USER.REQUEST, getUser);
  yield takeLatest(types.EDIT_ACCOUNT.REQUEST, editAccount);
  yield takeLatest(types.DELETE_ACCOUNT.REQUEST, deleteAccount);
  yield takeLatest(types.LOG_OUT.REQUEST, logOut);
  yield takeLatest(types.FORGOT_PASSWORD.REQUEST, forgotPassword);
  yield takeLatest(types.CHANGE_PASSWORD.REQUEST, changePassword);
  yield takeLatest(types.RESET_PASSWORD.REQUEST, resetPassword);
}
