import { Cell, RadialBar, RadialBarChart } from "recharts";

import chartStyles from "../Charts.module.scss";
import styles from "./GaugeChart.module.scss";

const GaugeChart = ({
  title,
  description,
  displayValue,
  levelLabel,
  value,
  inverted = false,
  minHeight,
  marginTop,
  marginTopValue,
  titleTop,
  titleLeft,
  fontSize,
  marginTopContainer,
}) => {
  const data = [
    {
      name: "Example",
      value: 100,
      fill: "#11606D",
    },
    {
      name: "Social demographic",
      value,
      fill: "#11606D",
    },
  ];

  return (
    <div
      className={styles.gauge__wrapper}
      style={{
        position: "relative",
        minHeight,
        paddingTop: marginTopContainer,
      }}
    >
      <b
        style={{
          position: "absolute",
          top: titleTop || "35px",
          left: titleLeft,
          marginRight: "30px",
          fontSize: "15px",
        }}
      >
        {title}
      </b>
      <div
        className={`${styles.chart__wrapper} ${
          marginTop ? styles.gauge_margin_top : ""
        }`}
        style={{ marginTop: marginTopValue }}
      >
        <RadialBarChart
          width={160}
          height={110}
          innerRadius="40%"
          outerRadius="100%"
          data={[
            {
              name: "Example",
              value: 100,
              fill: "#11606D",
            },
            {
              name: "Social demographic",
              value,
              fill: "#11606D",
            },
          ]}
          startAngle={180}
          barCategoryGap={-5}
          endAngle={0}
        >
          <RadialBar
            minAngle={15}
            background={{ fill: "#B2ECF2" }}
            clockWise={true}
            dataKey="value"
          >
            {data.map((entry, value) => (
              <Cell
                key={`cell-${value}`}
                style={entry.name === "Example" ? { display: "none" } : {}}
              />
            ))}
          </RadialBar>
        </RadialBarChart>
        <p className={styles.medium} style={{ fontSize }}>
          {levelLabel}
        </p>
        <p className={styles.caption}>{displayValue && displayValue}</p>
      </div>
      <p
        className={`${chartStyles.chart__source} ${styles.source}`}
        style={{
          position: "absolute",
          bottom: "20px",
          right: "30px",
        }}
      >
        {description}
      </p>
    </div>
  );
};

export default GaugeChart;
