import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../containers/Dashboard/store";

// CHECK IF FROM BAKECA
const useCheckBakeca = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [isBakecaLoading, setIsBakecaLoading] = useState(true);
  const [isFromBakeca, setIsFromBakeca] = useState(false);
  const [hasMinimumInfo, setHasMinimumInfo] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      // GET URL FROM LOCAL STORAGE
      let urlAnnuncioLocal = {};
      try {
        urlAnnuncioLocal = JSON.parse(
          localStorage.getItem("urlAnnuncio") || "{}"
        );
      } catch (error) {
        console.error("Error parsing local storage url annuncio:", error);
      }

      // GET URL ANNUNCIO PARAM
      const searchParams = new URLSearchParams(search);
      const idpubblico_annuncio = searchParams.get("idpubblico_annuncio");
      // console.log("idpubblico annuncio", idpubblico_annuncio);

      // Helper to check for minimum required parameters to generate report
      const checkForMinimumParams = (obj) => {
        return ["lat", "lng", "mquadri", "tipoimmobile", "prezzo"].every(
          (key) => key in obj
        );
      };

      // FUNCTION TO PROCESS DATA
      const processData = async (adUrl) => {
        dispatch(
          actions.GET_TEASER.REQUEST(
            adUrl,
            // SUCCESS CALLBACK
            (data) => {
              setIsFromBakeca(true);
              if (checkForMinimumParams(data?.dati_annuncio)) {
                setHasMinimumInfo(true);
                localStorage.setItem("urlAnnuncio", JSON.stringify(adUrl));
                localStorage.setItem(
                  "propertyData",
                  JSON.stringify(data?.dati_annuncio)
                );
                setIsBakecaLoading(false);
              } else {
                setHasMinimumInfo(false);
                localStorage.removeItem("urlAnnuncio");
                localStorage.removeItem("propertyData");
                setIsBakecaLoading(false);
              }
            },
            // FAILURE CALLBACK
            () => {
              setIsFromBakeca(false);
              setHasMinimumInfo(false);
              localStorage.removeItem("urlAnnuncio");
              localStorage.removeItem("propertyData");
              setIsBakecaLoading(false);
            }
          )
        );
      };

      // IF URL ANNUNCIO IS NOT IN LOCAL STORAGE
      if (!urlAnnuncioLocal.length) {
        // If idpubblico_annuncio is in url, set it in local storage and process data with it
        if (idpubblico_annuncio) {
          processData(idpubblico_annuncio);
          // console.log("storage is empty and there are params");
        } else {
          setIsBakecaLoading(false);
          // console.log("storage is empty and there are NO params");
        }
      }

      // IF PROPERTY DATA OBJECT IS IN LOCAL STORAGE
      if (urlAnnuncioLocal.length) {
        // If idpubblico_annuncio is in url and different from local storage, set it in local storage and process data with it
        if (idpubblico_annuncio && urlAnnuncioLocal != idpubblico_annuncio) {
          processData(idpubblico_annuncio);
          // console.log("storage is not empty and there are params");
        } else {
          // If idpubblico_annuncio isnt in url or equal to local storage process data from local storage
          processData(urlAnnuncioLocal);
          // console.log("storage is not empty");
        }
      }
    }
  }, [pathname]);

  return { isFromBakeca, hasMinimumInfo, isBakecaLoading };
};

export default useCheckBakeca;
