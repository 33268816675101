import React from "react";
import { Helmet as HelmetAsync } from "react-helmet-async";

const Helmet = ({ title, route }) => {
  return (
    <HelmetAsync>
      <title>Houseplus per Bakeca - {title}</title>
      <link rel="canonical" href={route} />
    </HelmetAsync>
  );
};

export default Helmet;
