import styles from "./Spinner.module.scss";
import SpinnerIcon from "../../../assets/svgs/spinner-icon.svg";
import { motion } from "framer-motion";

const Spinner = ({
  position,
  backgroundColor,
  height,
  paddingBottom,
  zIndex,
  spinnerWidth,
  spinnerHeight,
  imageWidth,
  imageHeight,
}) => (
  <motion.div
    className={styles.loader}
    style={{
      position,
      backgroundColor,
      height,
      paddingBottom,
      zIndex,
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    }}
    transition={{
      duration: 0.5,
      ease: "easeIn",
    }}
  >
    <div className={styles.spinner__wrapper}>
      <div
        className={styles.spinner}
        style={{ width: spinnerWidth, height: spinnerHeight }}
      />
      <div
        className={styles.spinner__inner}
        style={{ width: imageWidth, height: imageHeight }}
      >
        <img
          src={SpinnerIcon}
          alt="Loading..."
          className={styles.spinner__icon}
        />
      </div>
    </div>
  </motion.div>
);

export default Spinner;
