import React from "react";

import BarChart from "../../../../Common/Charts/BarChart";
import HorizontalBar from "../../../../Common/Charts/HorizontalBar";

const EnergyChart = ({ data }) => {
  // const chartData = data?.data?.x?.map((item, index) => ({
  //   x: item,
  //   Class: data?.data?.y[0][index],
  //   percentClass: `${data?.data?.displayed_values[0][index].toFixed(1)}%`,
  // }));
  // console.log(data, "ENERGY CHART DATA");

  const chartData =
    data &&
    Object.keys(data)
      ?.filter((item) => item !== "Sconosciuta")
      .map((key) => ({
        x: key,
        "Quantita`": data[key],
      }));

  // console.log(Object.keys(chartData[0])[0], "CHART DATA");

  return (
    <div>
      {/* <BarChart
        dx={36}
        marginLeft={0}
        minHeight={200}
        minWidth={240}
        wrapperHeight={235}
        chartMarginTop={"0px"}
        chartPadding={"25px 30px 14px 0px"}
        barMarginTop={1}
        // labelDx={30}
        // labelDy={15}
        chartData={chartData}
        barSize={10}
        isLegend={false}
        isDouble={false}
      /> */}

      <HorizontalBar
        dx={36}
        limit={data?.data?.xlimit}
        marginLeft={-10}
        barMarginTop={0}
        barMarginBottom={0}
        barMarginRight={35}
        minHeight={240}
        minWidth={240}
        chartPadding={"5px 0px"}
        wrapperHeight={260}
        marginTop={"8px"}
        barSize={10}
        isLegend={false}
        isDouble={false}
        chartData={chartData}
      />
    </div>
  );
};

export default EnergyChart;
