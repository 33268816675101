import GaugeChart from "../../../../Common/Charts/GaugeChart";
import formatToEuro from "../../../../../utils/formatToEuro";

const AverageEarnings = ({
  data,
  marginTopValue,
  minHeight,
  titleTop,
  titleLeft,
  fontSize,
}) => {
  // console.log(data);
  const translateValue = (value) => {
    if (value < 1) {
      return { label: "basso", value: 25 };
    }
    if (value < 2) {
      return { label: "medio", value: 50 };
    }
    return { label: "alto", value: 100 };
  };

  const translateDisplayValue = (displayVal) => {
    // const numericVal = displayVal?.split(' ')[0];
    // const stringVal = displayVal?.split(' ')[1];
    // console.log(numericVal?.toLocaleString('it-IT'));

    // console.log(displayVal);
    const str = displayVal;
    // console.log(str);
    const regex = /(\d+(\.\d+)?)/;
    const match = str?.match(regex);
    // console.log(match);
    const number = match
      ? Number(match[0]?.toLocaleString("it-IT")).toFixed(0)
      : null;
    // console.log(number, "NUMBER");
    // console.log(match, "MATCH NUMBER");

    return (
      <>
        Comune con un reddito medio annuale di <br />{" "}
        <b>€ {formatToEuro(number)}</b>
      </>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <GaugeChart
        title={data?.title}
        description={data?.description}
        displayValue={translateDisplayValue(data?.data?.displayed_value)}
        levelLabel={translateValue(data?.data?.level)?.label}
        value={translateValue(data?.data?.level)?.value}
        minHeight={minHeight}
        marginTopValue={marginTopValue}
        titleTop={titleTop}
        titleLeft={titleLeft}
        fontSize={fontSize}
      />
    </div>
  );
};

export default AverageEarnings;
