import React, { useState } from "react";
import { useSelector } from "react-redux";
import clx from "classnames";
import styles from "./Why.module.scss";

import { motion } from "framer-motion";
import { getDirection } from "../store/selectors";

import CostoContenuto from "../../../assets/svgs/costo-contenuto.svg";
import PrimaConsulenza from "../../../assets/svgs/prima-consulenza.svg";
import QualitaDati from "../../../assets/svgs/qualita-dati.svg";
import RisparmioTempo from "../../../assets/svgs/risparmio-tempo.svg";
import RispostaImmediata from "../../../assets/svgs/risposta-immediata.svg";

import MobileSlider from "./MobileSlider";

const Why = ({ whyRef }) => {
  const isScrollingDown = useSelector(getDirection());
  const [active, setActive] = useState(0);

  const reasonsWhy = [
    {
      title: "Giudizio oggettivo",
      text: "Se comprare e valutare immobili non è il tuo mestiere, allora non puoi rinunciare alla nostra consulenza indipendente e oggettiva per valutare la bontà di un annuncio.",
      img: PrimaConsulenza,
    },
    {
      title: "Risparmi il tuo tempo",
      text: "Nella frenesia odierna, é già difficile trovare il tempo per cercare l'immobile che fa al caso tuo, figuriamoci per capire se quell'annuncio si tratta di un affare o di una fregatura. Se ti affidi ad Houseplus, ci metterai un attimo.",
      img: RisparmioTempo,
    },
    {
      title: "Informazioni complete e accurate",
      text: "La nostra analisi si basa su dati del mercato immobiliare ottenuti in tempo reale. Combiniamo questa preziosa conoscenza con informazioni relative al territorio - quali quelle di natura sociale, economica e demografica - per affinare ulteriormente i nostri risultati.",
      img: QualitaDati,
    },
    {
      title: "Costo contenuto",
      text: "Le tecnologie innovative che abbiamo sviluppato ci permettono di abbattere i costi di analisi degli annunci, senza tuttavia fare alcun compromesso sulla qualità dei risultati. Questo permette a voi di risparmiare e a noi di avere clienti felici. Ottimo, no?",
      img: CostoContenuto,
    },
    {
      title: "Risultati immediati",
      text: "Con Houseplus, ottieni le risposte che cerchi istantaneamente, senza dover aspettare inutilmente per ore o addirittura giorni.",
      img: RispostaImmediata,
    },
  ];

  return (
    <section className={styles.why} ref={whyRef}>
      {/* HEADER */}
      <motion.h1
        className={styles.why__header}
        initial={{ opacity: 0, y: isScrollingDown ? -20 : 20 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }}
        viewport={{ margin: "-50px 0px 0px 0px" }}
      >
        {/* Perchè acquistare un'analisi <span>Houseplus</span> conviene? */}
        Perchè acquistare un'analisi Houseplus conviene?
      </motion.h1>

      {/* DESKTOP SLIDER CUSTOM */}
      <div className={styles.why__desktop}>
        {/* PAGINATION BTNS */}
        <motion.div
          className={styles.why__desktop__btns}
          initial={{ opacity: 0, y: isScrollingDown ? -50 : 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          viewport={{ margin: "-50px 0px -50px 0px" }}
        >
          {reasonsWhy.map((reason, index) => (
            <div
              key={`card-btn-${index}`}
              className={clx(styles.why__desktop__btns_btn, {
                [styles.active_btn]: active == index,
              })}
              onClick={() => setActive(index)}
            >
              {reason.title}
            </div>
          ))}
        </motion.div>

        {/* CARD */}
        <motion.div
          className={styles.why__desktop__cards_wrapper}
          initial={{ opacity: 0, x: 50 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          viewport={{ margin: "-50px 0px -50px 0px" }}
        >
          <div
            className={styles.why__desktop__cards}
            style={{ transform: `translatex(-${active * 100}%)` }}
          >
            {reasonsWhy.map((reason, index, array) => (
              <div
                key={`card-${index}`}
                className={clx(styles.why__card, {
                  [styles.active]: active == index,
                })}
              >
                <div className={styles.why__card_text}>
                  <h2 className={styles.why__card_text_header}>
                    {reason.title}
                  </h2>
                  <p className={styles.why__card_text_p}>{reason.text}</p>
                </div>
                <img
                  src={reason.img}
                  alt="card-img"
                  style={{ height: index == array.length - 1 && "380px" }}
                />
              </div>
            ))}
          </div>
        </motion.div>
      </div>

      {/* MOBILE SLIDER CUSTOM */}
      <MobileSlider reasonsWhy={reasonsWhy} />
    </section>
  );
};

export default Why;
