// Scroll to section function
const scrollTo = (elementRef) => {
  // console.log(elementRef, "elementRef");
  window.scrollTo({
    top: elementRef?.current?.offsetTop,
    behavior: "smooth",
  });
};

export default scrollTo;
