import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useResetOverflowOnRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if window width is greater than 1100px
    if (window.innerWidth > 1100) {
      document.body.style.paddingRight = "0px";
      const navbar = document.querySelector(".navbar-main");
      if (navbar) {
        navbar.style.paddingRight = "30px";
      }
      document.body.style.overflow = "auto";
    }
  }, [location]); // Dependency on location
};

export default useResetOverflowOnRouteChange;
