import styles from "./FifthPg.module.scss";
import stylesMain from "../../DownloadPdf.module.scss";
import OmiGraphic from "./OmiGraphic";
import SimilarProperties from "./SimilarProperties";
import EnergyChart from "./EnergyChart";

const FifthPg = ({
  omi,
  city,
  type,
  total_surface,
  adv_price,
  energy_chart,
  energy_chart_GPT,
  similar_properties,
  similar_properties_GPT,
}) => {
  const pricePerMq2 = adv_price / total_surface;
  // console.log(adv_price, "adv_price");
  // console.log(total_surface, "total_surface");
  // console.log(pricePerMq2, "pricePerMq2");
  // console.log(energy_chart, "energy_chart");
  // console.log(similar_properties_GPT, "similar_properties_GPT");

  return (
    <div
      className={styles.fifth_page}
      style={{
        gap: (similar_properties?.length <= 4 || !energy_chart) && "80px",
        padding:
          (similar_properties?.length <= 2 || !energy_chart) && "60px 40px",
      }}
    >
      {/* OMI GRAPHIC */}
      <OmiGraphic omi={omi} city={city} type={type} pricePerMq2={pricePerMq2} />

      {/* CURRENT MARKET */}
      <SimilarProperties
        similar_properties={similar_properties}
        property_price={adv_price}
        property_priceMq={pricePerMq2}
        similar_properties_GPT={similar_properties_GPT}
      />

      {/* ENERGY CLASS */}
      <div className={styles.fifth_page__energy}>
        {energy_chart && (
          <div className={stylesMain.page__section_title}>
            Distribuzione immobili per classe energetica
          </div>
        )}
        <div className={styles.fifth_page__energy__content}>
          {/* CHART */}
          {energy_chart && (
            <div className={styles.chart}>
              {/* <div className={stylesMain.page__section_subtitle}>
                Distribuzione immobili per classe energetica
              </div> */}
              <EnergyChart data={energy_chart} />
            </div>
          )}
          {/* STRENGHT POINTS */}
          {energy_chart_GPT && (
            <div className={styles.chart_description}>
              {/* <div className={stylesMain.page__section_subtitle}>
              Punti di forza
            </div> */}
              <div className={stylesMain.page__text}>{energy_chart_GPT}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FifthPg;
