import clx from "classnames";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import chartStyles from "../Charts.module.scss";
import ChartsAxisLabel from "../ChartsAxisLabel";
import ChartsLabel from "../ChartsLabel";
import ChartsTooltip from "../ChartsTooltip";
import styles from "./PyramidChart.module.scss";

const Index = ({
  title,
  description,
  label,
  limit,
  labelAdditionalTitle,
  chartData,
  wrapperHeight,
  minHeight,
  chartWidth,
  tickX,
  chartMarginTop,
}) => (
  // console.log(chartData);
  // console.log(chartData && Object.keys(chartData[0]));

  <div
    className={clx(chartStyles.chart__wrapper, styles.pyramid)}
    style={{ minHeight: wrapperHeight, padding: "15px 30px 20px" }}
  >
    <b style={{ fontSize: "15px" }}>{title}</b>
    <div
      className={chartStyles.chart__container}
      style={{ display: "flex", marginTop: "-28px" }}
    >
      <ResponsiveContainer
        minHeight={minHeight || 410}
        width="47%"
        minWidth={282}
        height={minHeight || "80%"}
      >
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            top: 40,
            left: 35,
            right: -2,
          }}
        >
          <XAxis
            type="number"
            domain={[0, limit]}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
            reversed
          />
          <YAxis
            type="category"
            dataKey={chartData && Object.keys(chartData[0])[0]}
            orientation="right"
            tick={false}
            width={7}
          />
          <Tooltip
            cursor={{ fill: "#BFEEF3" }}
            wrapperStyle={{ outline: "none" }}
            content={
              <ChartsTooltip labelAdditionalTitle={labelAdditionalTitle} />
            }
          />
          <Legend
            verticalAlign="top"
            align="right"
            height={40}
            width={"fit-content"}
          />
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[1]}
            fill={"#00B1C5"}
            radius={[0, 5, 5, 0]}
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[3]}
              position="right"
              fill="#9D9D9D"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer
        minHeight={minHeight || 410}
        width={chartWidth || "53%"}
        minWidth={318}
        height={minHeight || "80%"}
      >
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            top: 40,
            right: 35,
            left: -2,
          }}
        >
          <XAxis
            type="number"
            domain={[0, limit]}
            tick={<ChartsAxisLabel dy={10} textAnchor={"middle"} />}
            tickFormatter={(tick) => tick.toLocaleString("it-IT")}
          />
          <YAxis
            type="category"
            dataKey={chartData && Object.keys(chartData[0])[0]}
            tickLine={false}
            label={<ChartsLabel label={label} dy={70} dx={12} />}
            tick={
              <ChartsAxisLabel dy={3.5} textAnchor={"end"} dx={tickX || -6.5} />
            }
          />
          <Tooltip
            cursor={{ fill: "#BFEEF3" }}
            wrapperStyle={{ outline: "none" }}
            content={
              <ChartsTooltip labelAdditionalTitle={labelAdditionalTitle} />
            }
          />
          <Legend
            verticalAlign="top"
            height={40}
            width={"fit-content"}
            wrapperStyle={{
              left: 62,
            }}
          />
          <Bar
            isAnimationActive={false}
            dataKey={chartData && Object.keys(chartData[0])[2]}
            fill={"#11606D"}
            radius={[0, 5, 5, 0]}
          >
            <LabelList
              dataKey={chartData && Object.keys(chartData[0])[4]}
              position="right"
              fill="#9D9D9D"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
    <p
      className={chartStyles.chart__source}
      style={{ position: "absolute", top: "6px", right: "39px" }}
    >
      {description}
    </p>
  </div>
);

export default Index;
